import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { Gap, MyButton, MyInput, MyAutocomplete } from "../../atoms";
import MyDialog from "../../molecules/Dialog";
import { PRIMARY } from "../../../utils/constansColors";
import AlertMessage from "../../../components/molecules/AlertMessage";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  getAllCustomer,
  getCustomer,
  updateCustomer,
} from "../../../config/Redux/Customer/actions";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const validationSchemaEdit = yup.object().shape({
  nama_akun: yup.string("Masukan Nama Akun").required("Silahkan Masukan Nama Akun"),
  nama_customer: yup.string("Masukan Nama Customer").nullable(),
  alamat: yup.string("Masukan Alamat Customer").nullable(),
  catatan: yup.string().nullable(),
  status: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable()
  .required("Silahkan Masukan Status Customer"),
  id_kategori_area: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable()
  .required("Silahkan Masukan Kode Area Customer"),
});

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const columnData = [
  { no: 0, name: "No" },
  { no: 1, name: "Nama Akun" },
  { no: 2, name: "Nama Customer" },
  { no: 3, name: "Kategori Area" },
  { no: 4, name: "Status" },
  { no: 6, name: "Action" },
];

const BasicTable = ({ currentPage, search, sortBy, sortType, filterLevel, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  // const [currentPage, setCurrentPage] = React.useState(1);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
  const [idUser, setIdUser] = React.useState(null);

  // alertMessage
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  // selector
  const dataCustomer = useSelector((state) => state.customer.dataCustomer);
  const dataDetailCustomer = useSelector((state) => state.customer.dataDetailCustomer);
  let dataKategoriArea = useSelector((state) => state.global.dataKategoriArea);
  // options
  let optionsCat = dataKategoriArea?.data?.data?.map((item) => ({
    label: item.nama_kategori_area,
    id: item.id_kategori_area,
  }))||[];
  console.log(optionsCat)

  // change password
  const handleOpenDialog = (id) => {
    setOpenDialog(true);
    setIdUser(id);
    dispatch(getCustomer(id));
    formikProfile.resetForm();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // edit profile
  const handleOpenDialogProfile = (item) => {
    const { id_customer } = item;
    setOpenDialogProfile(true);
    setIdUser(id_customer);
    dispatch(getCustomer(id_customer));
    formikProfile.resetForm();
  };

  const handleCloseDialogProfile = () => {
    setOpenDialogProfile(false);
  };

  let dataDetail = dataDetailCustomer?.data?.data;

  // formik edit data profile
  const formikProfile = useFormik({
    initialValues: {
      nama_akun: dataDetail?.nama_akun,
      nama_customer: dataDetail?.nama_customer,
      alamat: dataDetail?.alamat,
      catatan: dataDetail?.catatan,
      status: dataDetail?.status
        ? {
            id: dataDetail?.status,
            label: dataDetail?.status,
          }
        : null,
      id_kategori_area: dataDetail?.id_kategori_area
        ? {
            id: dataDetail?.id_kategori_area,
            label: dataDetail?.nama_kategori_area,
          }
        : null,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaEdit,
    onSubmit: async (values) => {
      // console.log(values)
      let data = values;
      let payload = null;
      if(data.nama_akun !== dataDetail?.nama_akun){
        payload = {
          nama_akun: data.nama_akun,
          nama_customer: data.nama_customer,
          alamat: data.alamat,
          catatan: data.catatan,
          status: data?.status?.id,
          id_kategori_area: data?.id_kategori_area?.id,
        };
      } else {
        payload = {
          nama_customer: data.nama_customer,
          alamat: data.alamat,
          catatan: data.catatan,
          status: data?.status?.id,
          id_kategori_area: data?.id_kategori_area?.id,
        };
      }
      console.log(payload)
      let resp = await dispatch(updateCustomer(idUser, payload));
      if (resp.type === "PUT_CUSTOMER_SUCCESS") {
        handleCloseDialogProfile();
        setMessage(resp.payload?.message);
        setOpenDialogAlert(true);
        dispatch(getAllCustomer(currentPage, search, sortBy, sortType))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
    },
  });

  // form Ubah data
  const renderFormProfile = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Data</b>
        </h2>
      </div>
      <MyInput
        id="nama_akun"
        name="nama_akun"
        label="Nama Akun*"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_akun || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_akun && Boolean(formikProfile.errors.nama_akun)}
        helperText={formikProfile.touched.nama_akun && formikProfile.errors.nama_akun}
      />
      <Gap height={20} />
      <MyInput
        id="nama_customer"
        name="nama_customer"
        label="Nama Customer"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_customer || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_customer && Boolean(formikProfile.errors.nama_customer)}
        helperText={formikProfile.touched.nama_customer && formikProfile.errors.nama_customer}
      />
      <Gap height={20} />
      <MyAutocomplete
        options={[
          {
            id: "aktif",
            label: "aktif",
          },
          {
            id: "blacklist",
            label: "blacklist",
          }
        ]}
        fullWidth
        size="medium"
        label="Status Customer"
        id="status"
        name="status"
        value={formikProfile.values.status || null}
        onChange={(e, value) => {
          formikProfile.setFieldValue("status", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.status && formikProfile.errors.status}
      </FormHelperText>
      <Gap height={20} />
      <MyAutocomplete
        options={optionsCat}
        fullWidth
        size="medium"
        label="Kategori Area"
        id="id_kategori_area"
        name="id_kategori_area"
        value={formikProfile.values.id_kategori_area || null}
        onChange={(e, value) => {
          formikProfile.setFieldValue("id_kategori_area", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.id_kategori_area && formikProfile.errors.id_kategori_area}
      </FormHelperText>
      <Gap height={20} />
      <MyInput
        id="alamat"
        name="alamat"
        label="Alamat"
        fullWidth
        size="medium"
        value={formikProfile.values.alamat || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.alamat && Boolean(formikProfile.errors.alamat)}
        helperText={formikProfile.touched.alamat && formikProfile.errors.alamat}
      />
      <Gap height={20} />

      <MyInput
        id="catatan"
        name="catatan"
        label="Catatan"
        fullWidth
        size="medium"
        value={formikProfile.values.catatan || ""}
        onChange={formikProfile.handleChange}
        error={
          formikProfile.touched.catatan && Boolean(formikProfile.errors.username)
        }
        helperText={formikProfile.touched.catatan && formikProfile.errors.catatan}
      />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogProfile}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  // form Ubah data
  const renderFormDetail = (
    <>
      <div align="center">
        <h2>
          <b>Informasi Detail</b>
        </h2>
      </div>
      <div style={{margin:"4px 0px"}}>
        <span><b>Nama Akun</b></span><br/>
        <span>{dataDetail?.nama_akun || "-"}</span>
      </div>
      <div style={{margin:"8px 0px"}}>
        <span><b>Nama Customer</b></span><br/>
        <span>{dataDetail?.nama_customer || "-"}</span>
      </div>
      <div style={{margin:"8px 0px"}}>
        <span><b>Kategori Area</b></span><br/>
        <span>{dataDetail?.nama_kategori_area || "-"}</span>
      </div>
      <div style={{margin:"8px 0px"}}>
        <span><b>Status</b></span><br/>
        <span>{dataDetail?.status || "-"}</span>
      </div>
      <div style={{margin:"8px 0px"}}>
        <span><b>Alamat</b></span><br/>
        <span>{dataDetail?.alamat || "-"}</span>
      </div>
      <div style={{margin:"8px 0px"}}>
        <span><b>No HP</b></span><br/>
        <span>{dataDetail?.catatan || "-"}</span>
      </div>
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Oke"
          onClick={handleCloseDialog}
        />
      </div>
    </>
  );

  useEffect(() => {
    
  }, [dispatch]);

  return (
    <>
      {/* change password */}
      <MyDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        renderValue={renderFormDetail}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* edit user */}
      <MyDialog
        openDialog={openDialogProfile}
        handleCloseDialog={handleCloseDialogProfile}
        renderValue={renderFormProfile}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />

      <TableContainer>
        <Table
          sx={{ minWidth: 900 }}
          aria-label="simple table"
          className={classes.tableHeader}
        >
          <TableHead>
            <TableRow>
              {columnData.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 700,
                      color: "#757575",
                      width:
                        item.name === "No"
                          ? "3%"
                          : item.name === "Action"
                          ? ""
                          : "16%",
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        {dataCustomer?.data?.data?.map((item, index) => {
          let number = index + 1 + 10 * (dataCustomer?.data?.current_page - 1);
          return (
            <Table
              sx={{ minWidth: 900 }}
              aria-label="simple table"
              className={classes.tableBody}
              key={index}
            >
              <TableBody>
                <TableRow key={item.id}>
                  <TableCell width="3%">{number}</TableCell>
                  <TableCell width="16%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.nama_akun || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="16%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.nama_customer || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="16%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.nama_kategori_area || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="16%">
                    {item?.status}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <MyButton
                          startIcon={<RemoveRedEyeIcon />}
                          style={{ marginTop: 5 }}
                          color="#F06524"
                          colorHover="#F06524"
                          // height={"100%"}
                          size="small"
                          backgroundColor={"#FFFFFF"}
                          borderColor={"#F06524"}
                          backgroundColorHover={"#FFFFFF"}
                          variant="outlined"
                          children="Lihat Detail"
                          onClick={() => handleOpenDialog(item.id_customer)}
                        />
                        <Gap width={10} />
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Ubah Data"
                          onClick={() => handleOpenDialogProfile(item)}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
      {/* <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataUsers?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={20} /> */}
    </>
  );
}

export default BasicTable;