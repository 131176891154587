import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  GET_WAKTU_LIVE,
  GET_WAKTU_LIVE_SUCCESS,
  GET_WAKTU_LIVE_FAIL,
  GET_AKUN_LIVE,
  GET_AKUN_LIVE_SUCCESS,
  GET_AKUN_LIVE_FAIL,
  GET_KATEGORI_AREA,
  GET_KATEGORI_AREA_SUCCESS,
  GET_KATEGORI_AREA_FAIL,
  GET_KATEGORI_PENGIRIMAN,
  GET_KATEGORI_PENGIRIMAN_SUCCESS,
  GET_KATEGORI_PENGIRIMAN_FAIL,
  GET_KATEGORI_PEMBAYARAN,
  GET_KATEGORI_PEMBAYARAN_SUCCESS,
  GET_KATEGORI_PEMBAYARAN_FAIL,
  GET_STATUS_PESANAN,
  GET_STATUS_PESANAN_SUCCESS,
  GET_STATUS_PESANAN_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// GET profile
export const getProfile = () => (dispatch) => {
  dispatch({
    type: GET_PROFILE,
  });

  const request = API.getProfile();
  return request.then(
    (response) => dispatch(global(response.data,GET_PROFILE_SUCCESS)),
    (err) => dispatch(global(err,GET_PROFILE_FAIL))
  );
};

// GET waktu live
export const getWaktuLive = () => (dispatch) => {
  dispatch({
    type: GET_WAKTU_LIVE,
  });

  const request = API.getWaktuLive();
  return request.then(
    (response) => dispatch(global(response.data,GET_WAKTU_LIVE_SUCCESS)),
    (err) => dispatch(global(err,GET_WAKTU_LIVE_FAIL))
  );
};

// GET akun live by token
export const getAkunLiveProfile = () => (dispatch) => {
  dispatch({
    type: GET_AKUN_LIVE,
  });

  const request = API.getAkunLiveProfile();
  return request.then(
    (response) => dispatch(global(response.data,GET_AKUN_LIVE_SUCCESS)),
    (err) => dispatch(global(err,GET_AKUN_LIVE_FAIL))
  );
};

// GET kategori area
export const getKategoriArea = () => (dispatch) => {
  dispatch({
    type: GET_KATEGORI_AREA,
  });

  const request = API.getKategoriArea();
  return request.then(
    (response) => dispatch(global(response.data,GET_KATEGORI_AREA_SUCCESS)),
    (err) => dispatch(global(err,GET_KATEGORI_AREA_FAIL))
  );
};

// GET katgori pembayaran
export const getKategoriPembayaran = () => (dispatch) => {
  dispatch({
    type: GET_KATEGORI_PEMBAYARAN,
  });

  const request = API.getKategoriPembayaran();
  return request.then(
    (response) => dispatch(global(response.data,GET_KATEGORI_PEMBAYARAN_SUCCESS)),
    (err) => dispatch(global(err,GET_KATEGORI_PEMBAYARAN_FAIL))
  );
};

// GET kategori pengiriman
export const getKategoriPengiriman = () => (dispatch) => {
  dispatch({
    type: GET_KATEGORI_PENGIRIMAN,
  });

  const request = API.getKategoriPengiriman();
  return request.then(
    (response) => dispatch(global(response.data,GET_KATEGORI_PENGIRIMAN_SUCCESS)),
    (err) => dispatch(global(err,GET_KATEGORI_PENGIRIMAN_FAIL))
  );
};

// GET status pesanan
export const getAllStatusPesanan = () => (dispatch) => {
  dispatch({
    type: GET_STATUS_PESANAN,
  });

  const request = API.getAllStatusPesanan();
  return request.then(
    (response) => dispatch(global(response.data,GET_STATUS_PESANAN_SUCCESS)),
    (err) => dispatch(global(err,GET_STATUS_PESANAN_FAIL))
  );
};

// change password
export const changePassword = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD,
  });

  const request = API.changePassword(data);
  return request.then(
    (response) => dispatch(global(response.data,UPDATE_PASSWORD_SUCCESS)),
    (err) => dispatch(global(err,UPDATE_PASSWORD_FAIL))
  );
};

export const global = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
