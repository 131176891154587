import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { Gap, MyButton, MyInput, MyAutocomplete } from "../../atoms";
import MyDialog from "../../molecules/Dialog";
import { PRIMARY } from "../../../utils/constansColors";
import AlertMessage from "../../../components/molecules/AlertMessage";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {
  getUsers,
  getDetailUser,
  updateUser,
  getHakAkses,
  updateHakAkses,
  updateHakAkun
} from "../../../config/Redux/UserManagement/actions";
import {
  getAllAkunLive,
} from "../../../config/Redux/AkunLive/actions";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { LOCAL_STORAGE_KEY } from "../../../config/API/config";

const validationSchemaPassword = yup.object().shape({
  katasandibaru: yup.string().required("Silahkan Masukan Kata Sandi Baru"),
  katasandibaruconfirm: yup
    .string()
    .required("Silahkan Konfirmasi Kata Sandi")
    .oneOf([yup.ref("katasandibaru"), null], "Kata Sandi tidak sama"),
});

const validationSchemaEdit = yup.object().shape({
  name: yup.string("Masukan Alamat").required("Silahkan Masukan Alamat"),
  email: yup.string("Masukan Email").required("Silahkan Masukan Email"),
  username: yup
    .string("Masukan Username")
    .required("Silahkan Masukan Username"),
  is_active: yup
    .object()
    .shape({
      label: yup.string(),
      id: yup.string(),
    })
    .required("Silahkan Masukan Status Keaktifan User"),
});

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const columnData = [
  { no: 0, name: "No" },
  { no: 1, name: "Nama" },
  { no: 2, name: "Email" },
  { no: 3, name: "Username" },
  { no: 4, name: "Status Aktif" },
  { no: 6, name: "Hak Akun" },
  { no: 5, name: "Hak Akses" },
];

const BasicTable = ({ currentPage, search, sortBy, sortType, filterLevel, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  // const [currentPage, setCurrentPage] = React.useState(1);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
  const [openDialogHakAkses, setOpenDialogHakAkses] = React.useState(false);
  const [openDialogHakAkun, setOpenDialogHakAkun] = React.useState(false);
  const [idUser, setIdUser] = React.useState(null);
  const [hakAkses, setHakAkses] = React.useState(null);
  const [hakAkun, setHakAkun] = React.useState(null);

  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordNewConfirm, setShowPasswordNewConfirm] =
    React.useState(false);

  // alertMessage
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  // selector
  const dataUsers = useSelector((state) => state.users.dataUsers);
  const dataDetailUser = useSelector((state) => state.users.dataDetailUser);

  const dataHakAkses = useSelector((state) => state.users.dataHakAkses);
  const dataHakAkun = useSelector((state) => state.akunlive.dataAkunLive);
  // console.log(dataHakAkun)
  
  // change password
  const handleOpenDialog = (id) => {
    setIdUser(id);
    setOpenDialog(true);
    formik.resetForm();
  };

  const handleCloseDialog = () => {
    formik.resetForm();
    setOpenDialog(false);
  };

  // edit profile
  const handleOpenDialogProfile = (item) => {
    const { id_user } = item;
    setOpenDialogProfile(true);
    setIdUser(id_user);
    dispatch(getDetailUser(id_user));
    formikProfile.resetForm();
  };

  const handleCloseDialogProfile = () => {
    setOpenDialogProfile(false);
  };

  const handleClickShowPassword = (param) => {
    if (param === "newPass") {
      setShowPasswordNew(!showPasswordNew);
    }
    if (param === "passNewConfirm") {
      setShowPasswordNewConfirm(!showPasswordNewConfirm);
    }
  };

  // formik change password
  const formik = useFormik({
    initialValues: {
      katasandibaru: "",
      katasandibaruconfirm: "",
    },
    enableReinitialize: false,
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      let payload = {
        password: values.katasandibaru,
      };
      let resp = await dispatch(updateUser(idUser,payload));

      if (resp.type === "PUT_USERS_SUCCESS") {
        setMessage(resp.payload.message);
        setOpenDialogAlert(true);
        setStatus("");
        handleCloseDialog();
      } else {
        setMessage(resp.payload.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
    },
  });

  let dataDetail = dataDetailUser?.data?.data;

  // formik edit data profile
  const formikProfile = useFormik({
    initialValues: {
      name: dataDetail?.nama_user,
      email: dataDetail?.email,
      username: dataDetail?.username,
      is_active: dataDetail?.is_active
        ? {
            id: dataDetail?.is_active,
            label: (dataDetail?.is_active===1)?"Aktif":"Tidak Aktif",
          }
        : null,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaEdit,
    onSubmit: async (values) => {
      let data = values;
      let payload = null;
      if(data.email !== dataDetail?.email && data.username !== dataDetail?.username){
        payload = {
          nama_user: data.name,
          email: data.email,
          username: data.username,
          is_active: data?.is_active?.id,
        };
      } else if (data.email !== dataDetail?.email && data.username === dataDetail?.username){
        payload = {
          nama_user: data.name,
          email: data.email,
          is_active: data?.is_active?.id,
        };
      } else if (data.email === dataDetail?.email && data.username !== dataDetail?.username){
        payload = {
          nama_user: data.name,
          username: data.username,
          is_active: data?.is_active?.id,
        };
      } else {
        payload = {
          nama_user: data.name,
          is_active: data?.is_active?.id,
        };
      }
      let resp = await dispatch(updateUser(idUser, payload));
      if (resp.type === "PUT_USERS_SUCCESS") {
        handleCloseDialogProfile();
        setMessage(resp.payload.message);
        setOpenDialogAlert(true);
        dispatch(getUsers(currentPage, search, sortBy, sortType))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
    },
  });

  // form Ubah data
  const renderFormProfile = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Data</b>
        </h2>
      </div>
      <MyInput
        id="name"
        name="name"
        label="Nama Pengguna"
        fullWidth
        size="medium"
        value={formikProfile.values.name || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.name && Boolean(formikProfile.errors.name)}
        helperText={formikProfile.touched.name && formikProfile.errors.name}
      />
      <Gap height={20} />
      <MyInput
        id="email"
        name="email"
        label="Email"
        fullWidth
        size="medium"
        type="email"
        value={formikProfile.values.email || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.email && Boolean(formikProfile.errors.email)}
        helperText={formikProfile.touched.email && formikProfile.errors.email}
      />
      <Gap height={20} />

      <MyInput
        id="username"
        name="username"
        label="Username"
        fullWidth
        size="medium"
        value={formikProfile.values.username || ""}
        onChange={formikProfile.handleChange}
        error={
          formikProfile.touched.username && Boolean(formikProfile.errors.username)
        }
        helperText={formikProfile.touched.username && formikProfile.errors.username}
      />
      <Gap height={20} />
      <MyAutocomplete
        options={[
          {
            id: 1,
            label: "Aktif",
          },
          {
            id: 0,
            label: "Tidak Aktif",
          }
        ]}
        fullWidth
        size="medium"
        label="Status Keaktifan User*"
        id="is_active"
        name="is_active"
        value={formikProfile.values.is_active || null}
        onChange={(e, value) => {
          formikProfile.setFieldValue("is_active", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.is_active && formikProfile.errors.is_active}
      </FormHelperText>
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogProfile}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  // change password
  const renderFormPassword = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Kata Sandi</b>
        </h2>
        <Gap height={20} />

        <MyInput
          id="katasandibaru"
          name="katasandibaru"
          label="Kata Sandi Baru*"
          fullWidth
          size="medium"
          value={formik.values.katasandibaru}
          onChange={formik.handleChange}
          error={
            formik.touched.katasandibaru && Boolean(formik.errors.katasandibaru)
          }
          helperText={
            formik.touched.katasandibaru && formik.errors.katasandibaru
          }
          type={showPasswordNew ? "text" : "password"}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("newPass")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Gap height={20} />
        <MyInput
          id="katasandibaruconfirm"
          name="katasandibaruconfirm"
          label="Kata Sandi Baru Konfirmasi*"
          fullWidth
          size="medium"
          value={formik.values.katasandibaruconfirm}
          onChange={formik.handleChange}
          error={
            formik.touched.katasandibaruconfirm &&
            Boolean(formik.errors.katasandibaruconfirm)
          }
          helperText={
            formik.touched.katasandibaruconfirm &&
            formik.errors.katasandibaruconfirm
          }
          type={showPasswordNewConfirm ? "text" : "password"}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("passNewConfirm")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordNewConfirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Gap height={20} />
        <div style={{ display: "flex" }}>
          <MyButton
            height="40px"
            color="#B9B9B9"
            fullWidth
            backgroundColor="#FFFFFFFF"
            borderColor="#B9B9B9"
            backgroundColorHover="#FFFFFFFF"
            variant="outlined"
            children="Batal"
            onClick={handleCloseDialog}
          />
          <Gap width={30} />
          <MyButton
            height="40px"
            color="#8B8B8B"
            colorHover="white"
            fullWidth
            backgroundColor="#E8E8E8"
            borderColor="#E8E8E8"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Simpan"
            type="submit"
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </>
  );

  // edit hak akses
  const profile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const handleOpenDialogHakAkses = (item) => {
    const { id_user, hak_akses } = item;
    let generateHakAkses = [];
    dataHakAkses?.data?.data?.map((list,idx)=>{
      generateHakAkses[idx] = {
        id_user:id_user,
        id_hak_akses:list.id_hak_akses,
        nama_hak_akses:list.nama_hak_akses,
        value:false
      }
      hak_akses?.map((list2)=>{
        if(list.id_hak_akses === list2.id_hak_akses){
          generateHakAkses[idx].value = true
        }
      })
    })
    setOpenDialogHakAkses(true);
    setHakAkses(generateHakAkses)
  };
  const handleCloseDialogHakAkses = () => {
    setOpenDialogHakAkses(false);
  };
  const handleChangeHakAkses = async (event,id_user) =>{
    let resp = await dispatch(updateHakAkses(id_user,event.target.name));
    if(resp){
      dispatch(getUsers(currentPage, search, sortBy, sortType));
      let temp = hakAkses;
      temp?.map((list,idx)=>{
        if(list.id_hak_akses === parseInt(event.target.name)){
          temp[idx].value = !event.target.checked
        }
        return list
      })
      setHakAkses(temp)
    }
  }

  // change hak akses
  const renderFormHakAkses = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Hak Akses</b>
        </h2>
        <Gap height={20} />
        {hakAkses?.map((list,idx)=>{
          return(
            <div style={{textAlign:'left'}}>
              <Tooltip title={list.nama_hak_akses}>
                <FormControlLabel
                  key={idx}
                  control={
                    <Checkbox checked={list.value} onChange={(e)=>handleChangeHakAkses(e,list.id_user)} name={list.id_hak_akses} />
                  }
                  label={list.nama_hak_akses}
                />
              </Tooltip>
            </div>
          )
        })}
        
        <Gap height={20} />
        <div style={{ display: "flex" }}>
          <MyButton
            height="40px"
            color="#8B8B8B"
            colorHover="white"
            fullWidth
            backgroundColor="#E8E8E8"
            borderColor="#E8E8E8"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Selesai"
            onClick={handleCloseDialogHakAkses}
          />
        </div>
      </div>
    </>
  );

  // edit hak akun
  const handleOpenDialogHakAkun = (item) => {
    const { id_user, akun_live } = item;
    let generateHakAkun = [];
    dataHakAkun?.data?.data?.map((list,idx)=>{
      generateHakAkun[idx] = {
        id_user:id_user,
        id_akun_live:list.id_akun_live,
        nama_akun_live:list.nama_akun_live,
        value:false
      }
      akun_live?.map((list2)=>{
        if(list.id_akun_live === list2.id_akun_live){
          generateHakAkun[idx].value = true
        }
      })
    })
    setOpenDialogHakAkun(true);
    setHakAkun(generateHakAkun)
  };
  const handleCloseDialogHakAkun = () => {
    setOpenDialogHakAkun(false);
  };
  const handleChangeHakAkun = async (event,id_user) =>{
    let resp = await dispatch(updateHakAkun(id_user,event.target.name));
    if(resp){
      dispatch(getUsers(currentPage, search, sortBy, sortType));
      let temp = hakAkun;
      temp?.map((list,idx)=>{
        if(list.id_akun_live === parseInt(event.target.name)){
          temp[idx].value = !event.target.checked
        }
      })
      setHakAkun(temp)
    }
  }

  // change hak akun
  const renderFormHakAkun = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Hak Akun</b>
        </h2>
        <Gap height={20} />
        {hakAkun?.map((list,idx)=>{
          return(
            <div style={{textAlign:'left'}}>
              <Tooltip title={list.nama_akun_live}>
                <FormControlLabel
                  key={idx}
                  control={
                    <Checkbox checked={list.value} onChange={(e)=>handleChangeHakAkun(e,list.id_user)} name={list.id_akun_live} />
                  }
                  label={list.nama_akun_live}
                />
              </Tooltip>
            </div>
          )
        })}
        
        <Gap height={20} />
        <div style={{ display: "flex" }}>
          <MyButton
            height="40px"
            color="#8B8B8B"
            colorHover="white"
            fullWidth
            backgroundColor="#E8E8E8"
            borderColor="#E8E8E8"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Selesai"
            onClick={handleCloseDialogHakAkun}
          />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    dispatch(getHakAkses());
    dispatch(getAllAkunLive());
  }, [dispatch]);

  return (
    <>
      {/* change password */}
      <MyDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        renderValue={renderFormPassword}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* change hak akses */}
      <MyDialog
        openDialog={openDialogHakAkses}
        handleCloseDialog={handleCloseDialogHakAkses}
        renderValue={renderFormHakAkses}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* change hak akun */}
      <MyDialog
        openDialog={openDialogHakAkun}
        handleCloseDialog={handleCloseDialogHakAkun}
        renderValue={renderFormHakAkun}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* edit user */}
      <MyDialog
        openDialog={openDialogProfile}
        handleCloseDialog={handleCloseDialogProfile}
        renderValue={renderFormProfile}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />

      <TableContainer>
        <Table
          sx={{ minWidth: 1100 }}
          aria-label="simple table"
          className={classes.tableHeader}
        >
          <TableHead>
            <TableRow>
              {columnData.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 700,
                      color: "#757575",
                      width:
                        item.name === "No"
                          ? "3%"
                          : item.name === "Hak Akses"
                          ? ""
                          : "10%",
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        {dataUsers?.data?.data?.map((item, index) => {
          let number = index + 1 + 10 * (dataUsers?.data?.current_page - 1);
          if(profile?.data?.id === item.id_user){
            return null
          } else {
          return (
            <Table
              sx={{ minWidth: 1100 }}
              aria-label="simple table"
              className={classes.tableBody}
              key={index}
            >
              <TableBody>
                <TableRow key={item.id}>
                  <TableCell width="3%">{number}</TableCell>
                  <TableCell width="10%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.nama_user || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="10%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.email || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="10%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.username || "-"}
                    </div>
                  </TableCell>
                  
                  <TableCell width="10%">
                    {(item.is_active === 1)?"aktif":"tidak aktif"}
                  </TableCell>
                  <TableCell width="10%">
                    <div style={{ wordWrap: "break-word" }}>
                      <ul style={{paddingInlineStart:'0px'}}>
                        {item?.akun_live?.map((list,idx)=>{
                          return (<li key={idx} style={{fontSize:'10px'}}>{list?.nama_akun_live}</li>)
                        })}
                      </ul>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ul style={{paddingInlineStart:'0px'}}>
                      {item?.hak_akses.map((list,idx)=>{
                        return (<li key={idx} style={{fontSize:'10px'}}>{list?.nama_hak_akses}</li>)
                      })}
                      </ul>
                      <Gap width={30} />
                      <div>
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Ubah Data"
                          onClick={() => handleOpenDialogProfile(item)}
                        />
                        <Gap width={10} />
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#FF9000"
                          borderColor="#FF9000"
                          backgroundColorHover="#FF9000"
                          variant="outlined"
                          children="Ubah Hak Akses"
                          onClick={() => handleOpenDialogHakAkses(item)}
                        />
                        <Gap width={10} />
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#FF9000"
                          borderColor="#FF9000"
                          backgroundColorHover="#FF9000"
                          variant="outlined"
                          children="Ubah Hak Akun"
                          onClick={() => handleOpenDialogHakAkun(item)}
                        />
                        <Gap width={10} />
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="#F06524"
                          colorHover="#F06524"
                          // height={"100%"}
                          size="small"
                          backgroundColor={"#FFFFFF"}
                          borderColor={"#F06524"}
                          backgroundColorHover={"#FFFFFF"}
                          variant="outlined"
                          children="Ubah Kata Sandi"
                          onClick={() => handleOpenDialog(item.id_user)}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
          }
        })}
      </TableContainer>
      {/* <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataUsers?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={20} /> */}
    </>
  );
}

export default BasicTable;