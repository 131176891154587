import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Gap } from "../../components/atoms";
import "./routes.css";
// Navigation
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import { LOCAL_STORAGE_KEY } from "../API/config";
import { isJwtExpired } from "jwt-check-expiration";

// pages
import PageUserManagement from "../../pages/PageUserManagement";

// profile
import PageProfile from "../../pages/PageProfile";

// login
import PageLogin from "../../pages/PageLogin";

import PageCustomer from "../../pages/Customer";

import PageLaporan from "../../pages/Laporan";

//Master Data
import PageMasterData from "../../pages/MasterData";
import PageLiveAkun from "../../pages/MasterData/LiveAkun";
import PageMetodePembayaran from "../../pages/MasterData/MetodePembayaran";
import PageMetodePengiriman from "../../pages/MasterData/MetodePengiriman";
import PageReviewer from "../../pages/MasterData/Reviewer";

import PageRekapLive from "../../pages/RekapLive";
import PageRekapLivePesanan from "../../pages/RekapLive/Pesanan";
import PageRekapLiveBarang from "../../pages/RekapLive/Barang";
import PageRekapAdmin from "../../pages/RekapAdmin";
import PageRekapAdminPesanan from "../../pages/RekapAdmin/Pesanan";
import PageRekapAdminBarang from "../../pages/RekapAdmin/Barang";


const PrivateRoute = ({ children }) => {
  const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  let location = useLocation();
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <>
      <Header />
      <Gap height={35} />
      <SideMenu />
      <div className="content">{children}</div>
    </>
  );
};

const MyRoutes = () => {
  let navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  let getToken = token?.token;
  useEffect(() => {
    if (getToken) {
      let checkAuth = isJwtExpired(getToken);
      if (checkAuth) {
        localStorage.clear();
        navigate(`/login`);
      }
    }
  }, [navigate, getToken]);
  return (
    <>
      {/* <div className="content"> */}
      <Routes>
        <Route exact path="/login" element={<PageLogin />} />
        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <PageRekapLive />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/rekap-live/pesanan/:eventID/:akunID/:waktuID/:tanggal"
          element={
            <PrivateRoute>
              <PageRekapLivePesanan />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/rekap-live/pesanan/barang/:eventID/:akunID/:waktuID/:tanggal/:pesananID"
          element={
            <PrivateRoute>
              <PageRekapLiveBarang />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/rekap-admin"
          element={
            <PrivateRoute>
              <PageRekapAdmin />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/rekap-admin/pesanan/:eventID/:akunID/:waktuID/:tanggal"
          element={
            <PrivateRoute>
              <PageRekapAdminPesanan />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/rekap-admin/pesanan/barang/:eventID/:akunID/:waktuID/:tanggal/:pesananID"
          element={
            <PrivateRoute>
              <PageRekapAdminBarang />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/laporan"
          element={
            <PrivateRoute>
              <PageLaporan />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/customer"
          element={
            <PrivateRoute>
              <PageCustomer />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/data-master"
          element={
            <PrivateRoute>
              <PageMasterData />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/data-master/akun-live"
          element={
            <PrivateRoute>
              <PageLiveAkun />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/data-master/reviewer"
          element={
            <PrivateRoute>
              <PageReviewer />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/data-master/metode-pengiriman"
          element={
            <PrivateRoute>
              <PageMetodePengiriman />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/data-master/metode-pembayaran"
          element={
            <PrivateRoute>
              <PageMetodePembayaran />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/user-management"
          element={
            <PrivateRoute>
              <PageUserManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/profil"
          element={
            <PrivateRoute>
              <PageProfile />
            </PrivateRoute>
          }
        />


        {/* Detail Pages */}
        {/* <Route
          exact
          path="/detail-exco-pusat/:officeId/:tingkatExcoId/:daerahId"
          element={
            <PrivateRoute>
              <PageDetailExcoPusat />
            </PrivateRoute>
          }
        /> */}
      </Routes>
      {/* </div> */}
    </>
  );
};

export default MyRoutes;
