import React from "react";
import RekapAdmin from "../../components/organism/RekapAdmin";

const PageRekapAdmin = () => {
  return (
    <>
      <RekapAdmin />
    </>
  );
};

export default PageRekapAdmin;
