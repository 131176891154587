import {
  GET_REVIEWER,
  GET_REVIEWER_SUCCESS,
  GET_REVIEWER_FAIL,
  GET_DETAIL_REVIEWER,
  GET_DETAIL_REVIEWER_SUCCESS,
  GET_DETAIL_REVIEWER_FAIL,
  PUT_REVIEWER,
  PUT_REVIEWER_SUCCESS,
  PUT_REVIEWER_FAIL,
  ADD_REVIEWER,
  ADD_REVIEWER_SUCCESS,
  ADD_REVIEWER_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllReviewer =
  (page, search, sortBy, sortType, filterArea) => (dispatch) => {
    dispatch({
      type: GET_REVIEWER,
    });

    const request = API.getAllReviewer(page, search, sortBy, sortType, filterArea);
    return request.then(
      (response) => dispatch(reviewer(response.data,GET_REVIEWER_SUCCESS)),
      (err) => dispatch(reviewer(err,GET_REVIEWER_FAIL))
    );
  };


export const getReviewer = (param) => (dispatch) => {
  dispatch({
    type: GET_DETAIL_REVIEWER,
  });

  const request = API.getReviewer(param);
  return request.then(
    (response) => dispatch(reviewer(response.data,GET_DETAIL_REVIEWER_SUCCESS)),
    (err) => dispatch(reviewer(err,GET_DETAIL_REVIEWER_FAIL))
  );
};

// update user
export const updateReviewer = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_REVIEWER,
  });

  const request = API.updateReviewer(id, data);
  return request.then(
    (response) => dispatch(reviewer(response.data,PUT_REVIEWER_SUCCESS)),
    (err) => dispatch(reviewer(err,PUT_REVIEWER_FAIL))
  );
};

// add new user
export const createReviewer = (data) => (dispatch) => {
  dispatch({
    type: ADD_REVIEWER,
  });

  const request = API.createReviewer(data);
  return request.then(
    (response) => dispatch(reviewer(response.data,ADD_REVIEWER_SUCCESS)),
    (err) => dispatch(reviewer(err,ADD_REVIEWER_FAIL))
  );
};

export const reviewer = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
