import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_DETAIL_CUSTOMER,
  GET_DETAIL_CUSTOMER_SUCCESS,
  GET_DETAIL_CUSTOMER_FAIL,
  PUT_CUSTOMER,
  PUT_CUSTOMER_SUCCESS,
  PUT_CUSTOMER_FAIL,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllCustomer =
  (page, search, sortBy, sortType, filterArea) => (dispatch) => {
    dispatch({
      type: GET_CUSTOMER,
    });

    const request = API.getAllCustomer(page, search, sortBy, sortType, filterArea);
    return request.then(
      (response) => dispatch(customer(response.data,GET_CUSTOMER_SUCCESS)),
      (err) => dispatch(customer(err,GET_CUSTOMER_FAIL))
    );
  };


export const getCustomer = (param) => (dispatch) => {
  dispatch({
    type: GET_DETAIL_CUSTOMER,
  });

  const request = API.getCustomer(param);
  return request.then(
    (response) => dispatch(customer(response.data,GET_DETAIL_CUSTOMER_SUCCESS)),
    (err) => dispatch(customer(err,GET_DETAIL_CUSTOMER_FAIL))
  );
};

// update user
export const updateCustomer = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_CUSTOMER,
  });

  const request = API.updateCustomer(id, data);
  return request.then(
    (response) => dispatch(customer(response.data,PUT_CUSTOMER_SUCCESS)),
    (err) => dispatch(customer(err,PUT_CUSTOMER_FAIL))
  );
};

// add new user
export const createCustomer = (data) => (dispatch) => {
  dispatch({
    type: ADD_CUSTOMER,
  });

  const request = API.createCustomer(data);
  return request.then(
    (response) => dispatch(customer(response.data,ADD_CUSTOMER_SUCCESS)),
    (err) => dispatch(customer(err,ADD_CUSTOMER_FAIL))
  );
};

export const customer = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
