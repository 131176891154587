import React from "react";
import { Gap, MyInput, MyButton } from "../../atoms";
import { useFormik } from "formik";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { login } from "../../../config/Redux/Auth/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoHeader } from "../../../assets/index";

const validationSchema = yup.object().shape({
  email_username: yup.string().required("Silahkan Masukan Email atau Username"),
  password: yup.string().required("Silahkan Masukan Kata Sandi"),
});

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorLogin, setErrorLogin] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleClickShowPassword = (param) => {
    setShowPassword(!showPassword);
  };

  const checkHakAkses = (list,akses) => {
    let has = false;
    list?.map((val)=>{
      if(val.nama_hak_akses === akses){
        has = true
      }
      // if(val.nama_hak_akses === "Super Admin Semata"){
      //   has = true
      // }
    })
    return has
  }
  

  const formik = useFormik({
    initialValues: {
      email_username: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let payload = {
        email_username: values.email_username,
        password: values.password,
      };
      setLoading(true)
      const resp = await dispatch(login(payload));
      if(resp){
        let checkLevelExco = resp.payload.data.id_tingkat_exco;
        let hak_akses = resp.payload.data.hak_akses;  
        if (resp.type === "LOGIN_SUCCESS") {
          setLoading(false)
          if(checkHakAkses(hak_akses,"Rekap Live")){
            return navigate(`/`);
          } else if (checkHakAkses(hak_akses,"Rekap Admin")){
            return navigate(`/rekap-admin`);
          } else if (checkHakAkses(hak_akses,"Laporan Penjualan")){
            return navigate(`/laporan`);
          } else if (checkHakAkses(hak_akses,"Data Master")){
            return navigate(`/data-master`);
          } else if (checkHakAkses(hak_akses,"User Management")){
            return navigate(`/user-management`);
          } else {
            return navigate(`/customer`);
          }
        } else {
          // alert("error");
          setLoading(false)
          setErrorLogin(true);
          setErrorMessage(resp.payload?.data?.message || "login failed");
        }
      }
    },
  });

  return (
    <>
      <div align="center" 
        style={{
          minHeight:'100vh',
          backgroundColor:'white'
        }}
      >
        <Gap height={50} />
        <img style={{width:'300px'}} src={logoHeader} alt={logoHeader} />
        <div>
          <h2 style={{color:'gray'}}>Selamat Datang <br/>di Sistem Rekap Panjualan  Live</h2>
          {errorLogin?<p style={{color:'red'}}>{errorMessage}</p>:""}
          <MyInput
            id="email_username"
            name="email_username"
            label="E-Mail atau Username"
            style={{ width: "300px" }}
            // fullWidth
            size="medium"
            value={formik.values.email_username}
            onChange={formik.handleChange}
            error={formik.touched.email_username && Boolean(formik.errors.email_username)}
            helperText={formik.touched.email_username && formik.errors.email_username}
            // type="email"
          />
          <Gap height={20} />
          <MyInput
            id="password"
            name="password"
            label="Kata Sandi"
            style={{ width: "300px" }}
            size="medium"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Gap height={20} />
          <MyButton
            height={50}
            color="#fff"
            colorHover="white"
            style={{ width: "300px" }}
            // fullWidth
            backgroundColor={loading?"lightgray":"#F06524"}
            borderColor={loading?"lightgray":"#F06524"}
            backgroundColorHover={loading?"lightgray":"#F06524"}
            variant="outlined"
            children={loading?"Sedang Memproses...":"Masuk"}
            type="submit"
            disabled={loading}
            onClick={formik.handleSubmit}
          />
        </div>
        <br/>
        <span style={{fontSize:'10px'}}>v 1.2.4</span>
      </div>
    </>
  );
};

export default Login;
