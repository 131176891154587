import React from "react";
import LiveAkun from "../../components/organism/LiveAkun";

const PageLiveAkun = () => {
  return (
    <>
      <LiveAkun />
    </>
  );
};

export default PageLiveAkun;
