import {
  GET_LAPORAN_LIVE_EVENT,
  GET_LAPORAN_LIVE_EVENT_SUCCESS,
  GET_LAPORAN_LIVE_EVENT_FAIL,
  GET_LAPORAN_REKAP,
  GET_LAPORAN_REKAP_SUCCESS,
  GET_LAPORAN_REKAP_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get laporan
export const getLaporanLiveEvent =
  (date_type, tanggal, kategori_visual) => (dispatch) => {
    dispatch({
      type: GET_LAPORAN_LIVE_EVENT,
    });

    const request = API.getLaporanLiveEvent(date_type, tanggal, kategori_visual);
    return request.then(
      (response) => dispatch(laporan(response.data,GET_LAPORAN_LIVE_EVENT_SUCCESS)),
      (err) => dispatch(laporan(err,GET_LAPORAN_LIVE_EVENT_FAIL))
    );
  };

export const getLaporanRekap =
  (date_type, tanggal, kategori_visual, id_status_pesanan) => (dispatch) => {
    dispatch({
      type: GET_LAPORAN_REKAP,
    });

    const request = API.getLaporanRekap(date_type, tanggal, kategori_visual, id_status_pesanan);
    return request.then(
      (response) => dispatch(laporan(response.data,GET_LAPORAN_REKAP_SUCCESS)),
      (err) => dispatch(laporan(err,GET_LAPORAN_REKAP_FAIL))
    );
  };

export const laporan = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
