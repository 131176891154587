import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_DETAIL_USER,
  GET_DETAIL_USER_SUCCESS,
  GET_DETAIL_USER_FAIL,
  PUT_USERS,
  PUT_USERS_SUCCESS,
  PUT_USERS_FAIL,
  CHANGE_PASSWORD_USER,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_FAIL,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  GET_HAK_AKSES,
  GET_HAK_AKSES_SUCCESS,
  GET_HAK_AKSES_FAIL,
  POST_HAK_AKSES,
  POST_HAK_AKSES_SUCCESS,
  POST_HAK_AKSES_FAIL,
  POST_HAK_AKUN,
  POST_HAK_AKUN_SUCCESS,
  POST_HAK_AKUN_FAIL,
} from "./actionTypes";

const initialState = {
  dataUsers: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailUser: {
    loading: false,
    error: false,
    data: null,
  },
  updateUser: {
    loading: false,
    error: false,
    data: null,
  },
  newUser: {
    loading: false,
    error: false,
    data: null,
  },
  changePasswordUser: {
    loading: false,
    error: false,
    data: null,
  },
  dataHakAkses: {
    loading: false,
    error: false,
    data: null,
  },
  updateHakAkses: {
    loading: false,
    error: false,
    data: null,
  },
  updateHakAkun: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_USERS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          loading: true,
          error: false,
        },
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        dataUsers: {
          ...state.dataUsers,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_DETAIL_USER:
      return {
        ...state,
        dataDetailUser: {
          ...state.dataDetailUser,
          loading: true,
          error: false,
        },
      };
    case GET_DETAIL_USER_SUCCESS:
      return {
        ...state,
        dataDetailUser: {
          ...state.dataDetailUser,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_DETAIL_USER_FAIL:
      return {
        ...state,
        dataDetailUser: {
          ...state.dataDetailUser,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_USERS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: true,
          error: false,
        },
      };
    case PUT_USERS_SUCCESS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_USERS_FAIL:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_NEW_USER:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          loading: true,
          error: false,
        },
      };
    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_NEW_USER_FAIL:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          loading: false,
          error: action.payload,
        },
      };
    //change password user
    case CHANGE_PASSWORD_USER:
      return {
        ...state,
        changePasswordUser: {
          ...state.changePasswordUser,
          loading: true,
          error: false,
        },
      };
    case CHANGE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        changePasswordUser: {
          ...state.changePasswordUser,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case CHANGE_PASSWORD_USER_FAIL:
      return {
        ...state,
        changePasswordUser: {
          ...state.changePasswordUser,
          loading: false,
          error: action.payload,
        },
      };
    // get hak akses
    case GET_HAK_AKSES:
      return {
        ...state,
        dataHakAkses: {
          ...state.dataHakAkses,
          loading: true,
          error: false,
        },
      };
    case GET_HAK_AKSES_SUCCESS:
      return {
        ...state,
        dataHakAkses: {
          ...state.dataHakAkses,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_HAK_AKSES_FAIL:
      return {
        ...state,
        dataHakAkses: {
          ...state.dataHakAkses,
          loading: false,
          error: action.payload,
        },
      };
    // update hak akses
    case POST_HAK_AKSES:
      return {
        ...state,
        updateHakAkses: {
          ...state.updateHakAkses,
          loading: true,
          error: false,
        },
      };
    case POST_HAK_AKSES_SUCCESS:
      return {
        ...state,
        updateHakAkses: {
          ...state.updateHakAkses,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case POST_HAK_AKSES_FAIL:
      return {
        ...state,
        updateHakAkses: {
          ...state.updateHakAkses,
          loading: false,
          error: action.payload,
        },
      };
    // update hak akun
    case POST_HAK_AKUN:
      return {
        ...state,
        updateHakAkun: {
          ...state.updateHakAkun,
          loading: true,
          error: false,
        },
      };
    case POST_HAK_AKUN_SUCCESS:
      return {
        ...state,
        updateHakAkun: {
          ...state.updateHakAkun,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case POST_HAK_AKUN_FAIL:
      return {
        ...state,
        updateHakAkun: {
          ...state.updateHakAkun,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
