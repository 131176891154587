import {
  GET_REVIEWER,
  GET_REVIEWER_SUCCESS,
  GET_REVIEWER_FAIL,
  GET_DETAIL_REVIEWER,
  GET_DETAIL_REVIEWER_SUCCESS,
  GET_DETAIL_REVIEWER_FAIL,
  PUT_REVIEWER,
  PUT_REVIEWER_SUCCESS,
  PUT_REVIEWER_FAIL,
  ADD_REVIEWER,
  ADD_REVIEWER_SUCCESS,
  ADD_REVIEWER_FAIL,
} from "./actionTypes";

const initialState = {
  dataReviewer: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailReviewer: {
    loading: false,
    error: false,
    data: null,
  },
  updateReviewer: {
    loading: false,
    error: false,
    data: null,
  },
  addReviewer: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_REVIEWER:
      return {
        ...state,
        dataReviewer: {
          ...state.dataReviewer,
          loading: true,
          error: false,
        },
      };
    case GET_REVIEWER_SUCCESS:
      return {
        ...state,
        dataReviewer: {
          ...state.dataReviewer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_REVIEWER_FAIL:
      return {
        ...state,
        dataReviewer: {
          ...state.dataReviewer,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_DETAIL_REVIEWER:
      return {
        ...state,
        dataDetailReviewer: {
          ...state.dataDetailReviewer,
          loading: true,
          error: false,
        },
      };
    case GET_DETAIL_REVIEWER_SUCCESS:
      return {
        ...state,
        dataDetailReviewer: {
          ...state.dataDetailReviewer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_DETAIL_REVIEWER_FAIL:
      return {
        ...state,
        dataDetailReviewer: {
          ...state.dataDetailReviewer,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_REVIEWER:
      return {
        ...state,
        updateReviewer: {
          ...state.updateReviewer,
          loading: true,
          error: false,
        },
      };
    case PUT_REVIEWER_SUCCESS:
      return {
        ...state,
        updateReviewer: {
          ...state.updateReviewer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_REVIEWER_FAIL:
      return {
        ...state,
        updateReviewer: {
          ...state.updateReviewer,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_REVIEWER:
      return {
        ...state,
        addReviewer: {
          ...state.addReviewer,
          loading: true,
          error: false,
        },
      };
    case ADD_REVIEWER_SUCCESS:
      return {
        ...state,
        addReviewer: {
          ...state.addReviewer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_REVIEWER_FAIL:
      return {
        ...state,
        addReviewer: {
          ...state.addReviewer,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
