import React from "react";
import { Gap } from "../../atoms";
import { Link } from "react-router-dom";
import { Grid,Paper } from "@mui/material";
// import "./detailIkhtisar.css";


const DataManagement = () => {
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Gap height={20} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: 28, fontWeight:700 }}>Data Master</p>
      </div>
      <div>
        <Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6}>
            <Link to="/data-master/akun-live" className="boxhead">
              <Paper className="overview-paper">
                <h3 className="overview-header">Pengaturan <br/> Akun Live</h3>
              </Paper>
            </Link>
					</Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Link to="/data-master/reviewer" className="boxhead">
              <Paper className="overview-paper">
                <h3 className="overview-header">Pengaturan <br/>Reviewer</h3>
              </Paper>
            </Link>
					</Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Link to="/data-master/metode-pengiriman" className="boxhead">
              <Paper className="overview-paper">
                <h3 className="overview-header">Pengaturan <br/>Metode Pengiriman</h3>
              </Paper>
            </Link>
					</Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Link to="/data-master/metode-pembayaran" className="boxhead">
              <Paper className="overview-paper">
                <h3 className="overview-header">Pengaturan <br/>Metode Pembayaran</h3>
              </Paper>
            </Link>
					</Grid>
				</Grid>
      </div>
      
      
      <Gap height={20} />
      <div className="footer-content"></div>
    </div>
  );
};

export default DataManagement;
