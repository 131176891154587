import React, { useEffect } from "react";
import { Gap, MyInput, MyButton, MyAutocomplete } from "../../atoms";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import MyDialog from "../../molecules/Dialog";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useFormik } from "formik";
import AlertMessage from "../../../components/molecules/AlertMessage";
import {
  getUsers,
  postNewUser,
} from "../../../config/Redux/UserManagement/actions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const validationSchemaCreate = yup.object().shape({
  name: yup.string("Masukan Alamat").required("Silahkan Masukan Alamat"),
  email: yup.string("Masukan Email").required("Silahkan Masukan Email"),
  username: yup.string("Masukan Email").required("Silahkan Masukan Username"),
  password: yup.string().required("Silahkan Masukan Kata Sandi Baru"),
  confirmPassword: yup
    .string()
    .required("Silahkan Konfirmasi Kata Sandi")
    .oneOf([yup.ref("password"), null], "Kata Sandi tidak sama"),
});

const UserManagement = () => {
  const dispatch = useDispatch();
  const [openDialogUser, setOpenDialogUser] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordNewConfirm, setShowPasswordNewConfirm] =
    React.useState(false);

  // alert
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  // formik add user
  const formikProfile = useFormik({
    initialValues: {
      name: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchemaCreate,
    onSubmit: async (values) => {
      let payload = {
        nama_user: values.name,
        email: values.email,
        is_active: 1,
        username: values.username,
        password: values.password,
      };
      let resp = await dispatch(postNewUser(payload));
      if (resp.type === "ADD_NEW_USER_SUCCESS") {
        handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getUsers(1));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });

  // new user
  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
    formikProfile.resetForm();
  };

  const handleCloseDialogUser = () => {
    setOpenDialogUser(false);
    formikProfile.resetForm();
  };

  const handleClickShowPassword = (param) => {
    if (param === "newPass") {
      setShowPasswordNew(!showPasswordNew);
    }
    if (param === "passNewConfirm") {
      setShowPasswordNewConfirm(!showPasswordNewConfirm);
    }
  };
  const renderFormNewData = (
    <>
      <div align="center">
        <h2>
          <b>Tambah Pengguna</b>
        </h2>
      </div>
      <MyInput
        id="name"
        name="name"
        label="Nama*"
        fullWidth
        size="medium"
        value={formikProfile.values.name}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.name && Boolean(formikProfile.errors.name)}
        helperText={formikProfile.touched.name && formikProfile.errors.name}
      />
      <Gap height={20} />
      <MyInput
        id="email"
        name="email"
        label="Email*"
        fullWidth
        size="medium"
        type="email"
        value={formikProfile.values.email}
        onChange={formikProfile.handleChange}
        error={
          formikProfile.touched.email && Boolean(formikProfile.errors.email)
        }
        helperText={formikProfile.touched.email && formikProfile.errors.email}
      />
      <Gap height={20} />
      <MyInput
        id="username"
        name="username"
        label="Username*"
        fullWidth
        size="medium"
        value={formikProfile.values.username}
        onChange={formikProfile.handleChange}
        error={
          formikProfile.touched.username && Boolean(formikProfile.errors.username)
        }
        helperText={formikProfile.touched.username && formikProfile.errors.username}
      />
      <Gap height={20} />
      <hr />
      <MyInput
        id="password"
        name="password"
        label="Kata Sandi Baru*"
        fullWidth
        size="medium"
        value={formikProfile.values.password}
        onChange={formikProfile.handleChange}
        error={
          formikProfile.touched.password &&
          Boolean(formikProfile.errors.password)
        }
        helperText={
          formikProfile.touched.password && formikProfile.errors.password
        }
        type={showPasswordNew ? "text" : "password"}
        InputProps={{
          // <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword("newPass")}
                // onMouseDown={handleMouseDownPassword}
              >
                {showPasswordNew ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Gap height={20} />
      <MyInput
        id="confirmPassword"
        name="confirmPassword"
        label="Kata Sandi Baru Konfirmasi*"
        fullWidth
        size="medium"
        value={formikProfile.values.confirmPassword}
        onChange={formikProfile.handleChange}
        error={
          formikProfile.touched.confirmPassword &&
          Boolean(formikProfile.errors.confirmPassword)
        }
        helperText={
          formikProfile.touched.confirmPassword &&
          formikProfile.errors.confirmPassword
        }
        type={showPasswordNewConfirm ? "text" : "password"}
        InputProps={{
          // <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword("passNewConfirm")}
                // onMouseDown={handleMouseDownPassword}
              >
                {showPasswordNewConfirm ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUser}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );
  const classes = useStyles();

  const dataUsers = useSelector((state) => state.users.dataUsers);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [sortBy, setSortBy] = React.useState("nama_user");
  const [sortType, setSortType] = React.useState("asc");

  useEffect(() => {
    dispatch(getUsers(1));
  }, [dispatch]);

  const handlePagination = (event, value) => {
    setCurrentPage(value);
    dispatch(getUsers(value, search, sortBy, sortType));
  };

  const handleSearch = (event) => {
    setSearch(event.target.value)
    let temp = event.target.value
    var timeout;
    var delay = 500;
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      if(temp === event.target.value){
        dispatch(getUsers(1, event.target.value, sortBy, sortType));
        setCurrentPage(1);
      }
    }, delay);
  };

  const handleSortBy = (e) => {
    setSortBy(e.target.value);
    dispatch(
      getUsers(currentPage, search, e.target.value, sortType)
    );
  };

  const handleSortType = (e) => {
    setSortType(e.target.value);
    dispatch(
      getUsers(currentPage, search, sortBy, e.target.value)
    );
  };
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      {/* add new user */}
      <MyDialog
        openDialog={openDialogUser}
        handleCloseDialog={handleCloseDialogUser}
        renderValue={renderFormNewData}
        fullWidth={true}
        maxWidth={"sm"}
      />

      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={20} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: 28, fontWeight:700 }}>User Management</p>
        <div style={{ display: "flex", marginTop: 25 }}>
          {/* <Filters /> */}
          <Gap width={10} />

          <MyInput
            size="small"
            id="search"
            name="search"
            // label="Cari"
            placeholder="Cari"
            onChange={handleSearch}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Gap width={10} />
          <MyButton
            // style={{ marginTop: 25 }}
            startIcon={<AddIcon />}
            height="63%"
            color="white"
            backgroundColor="#F06524"
            borderColor="#F06524"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Tambah Pengguna"
            onClick={handleOpenDialogUser}
          />
        </div>
      </div>

      <div className="filter-tabel-exco">

        {/* sort */}
        <div className="filter-tabel-exco-content">
          <p>Urutkan Berdasarkan</p>
          <select onChange={handleSortBy}>
            <option value={"nama_user"}>{"Nama"}</option>
            <option value={"email"}>{"E-mail"}</option>
            <option value={"username"}>{"Username"}</option>
            <option value={"is_active"}>{"Status Aktif"}</option>
            <option value={"id_user"}>{"Waktu Pembuatan"}</option>
          </select>
        </div>
        <div className="filter-tabel-exco-content">
          <p>Urutkan Secara</p>
          <select onChange={handleSortType}>
            <option value={"asc"}>{"A-Z (Ascending)"}</option>
            <option value={"desc"}>{"Z-A (Descending)"}</option>
          </select>
        </div>
      </div>

      {/* Tableee */}
      <Table 
        currentPage={currentPage} 
        search={search} 
        sortBy={sortBy} 
        sortType={sortType} 
      />
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataUsers?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={20} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
