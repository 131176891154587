import React from "react";
import Login from "../../components/organism/Login";

const PageLogin = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default PageLogin;
