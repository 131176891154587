import {
  GET_METODE_PENGIRIMAN,
  GET_METODE_PENGIRIMAN_SUCCESS,
  GET_METODE_PENGIRIMAN_FAIL,
  GET_DETAIL_METODE_PENGIRIMAN,
  GET_DETAIL_METODE_PENGIRIMAN_SUCCESS,
  GET_DETAIL_METODE_PENGIRIMAN_FAIL,
  PUT_METODE_PENGIRIMAN,
  PUT_METODE_PENGIRIMAN_SUCCESS,
  PUT_METODE_PENGIRIMAN_FAIL,
  ADD_METODE_PENGIRIMAN,
  ADD_METODE_PENGIRIMAN_SUCCESS,
  ADD_METODE_PENGIRIMAN_FAIL,
} from "./actionTypes";

const initialState = {
  dataPengiriman: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailPengiriman: {
    loading: false,
    error: false,
    data: null,
  },
  updatePengiriman: {
    loading: false,
    error: false,
    data: null,
  },
  addPengiriman: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_METODE_PENGIRIMAN:
      return {
        ...state,
        dataPengiriman: {
          ...state.dataPengiriman,
          loading: true,
          error: false,
        },
      };
    case GET_METODE_PENGIRIMAN_SUCCESS:
      return {
        ...state,
        dataPengiriman: {
          ...state.dataPengiriman,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_METODE_PENGIRIMAN_FAIL:
      return {
        ...state,
        dataPengiriman: {
          ...state.dataPengiriman,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_DETAIL_METODE_PENGIRIMAN:
      return {
        ...state,
        dataDetailPengiriman: {
          ...state.dataDetailPengiriman,
          loading: true,
          error: false,
        },
      };
    case GET_DETAIL_METODE_PENGIRIMAN_SUCCESS:
      return {
        ...state,
        dataDetailPengiriman: {
          ...state.dataDetailPengiriman,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_DETAIL_METODE_PENGIRIMAN_FAIL:
      return {
        ...state,
        dataDetailPengiriman: {
          ...state.dataDetailPengiriman,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_METODE_PENGIRIMAN:
      return {
        ...state,
        updatePengiriman: {
          ...state.updatePengiriman,
          loading: true,
          error: false,
        },
      };
    case PUT_METODE_PENGIRIMAN_SUCCESS:
      return {
        ...state,
        updatePengiriman: {
          ...state.updatePengiriman,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_METODE_PENGIRIMAN_FAIL:
      return {
        ...state,
        updatePengiriman: {
          ...state.updatePengiriman,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_METODE_PENGIRIMAN:
      return {
        ...state,
        addPengiriman: {
          ...state.addPengiriman,
          loading: true,
          error: false,
        },
      };
    case ADD_METODE_PENGIRIMAN_SUCCESS:
      return {
        ...state,
        addPengiriman: {
          ...state.addPengiriman,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_METODE_PENGIRIMAN_FAIL:
      return {
        ...state,
        addPengiriman: {
          ...state.addPengiriman,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
