import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { logoHeader } from "../../../assets/index";
import ListItemText from "@mui/material/ListItemText";
import { PRIMARY } from "../../../utils/constansColors";
import { Gap } from "../../atoms";
import "./header.css";
import { LOCAL_STORAGE_KEY } from "../../../config/API/config";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import { Link, useLocation } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemButton from "@mui/material/ListItemButton";
import {
  getProfile,
} from "../../../config/Redux/Global/actions";

const ResponsiveAppBar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const [openDrawer, setOpenDrawer] = React.useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(async() => {
    let wait = await dispatch(getProfile());
    profile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if(wait){
      if(wait?.payload?.status === 401){
        localStorage.removeItem(LOCAL_STORAGE_KEY)
        window.location.reload()
      }
    }
  }, [dispatch]);

  const handleClose = (param) => {
    if (param === "profile") {
      navigate("/profil");
    }
    if (param === "logout") {
      localStorage.clear();
      navigate("/login");
    }
    setAnchorEl(null);
  };

  //cek hak akses
  const checkHakAkses = (list,akses) => {
    let has = false;
    list?.map((val)=>{
      if(val.nama_hak_akses === akses){
        has = true
      }
      if(val.nama_hak_akses === "Super Admin Semata"){
        has = true
      }
    })
    return has
  }

  return (
    <AppBar position="fixed" elevation={0} style={{ backgroundColor: "white", boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)'}}>
      {/* <Container maxWidth="xl"> */}
      <div style={{marginLeft:'12px', marginRight:'12px'}}>
        <Toolbar disableGutters className="header-style">
          <div className="menu-bar">
            <MenuIcon onClick={() => setOpenDrawer(true)} />
            <Drawer
              anchor="left"
              open={Boolean(openDrawer)}
              onClose={() => setOpenDrawer(false)}
            >
              <Box
                sx={{
                  width: 270,
                  textAlign:'center'
                }}
              >
                <img
                  style={{ width: 220, marginTop:'4px' }}
                  src={logoHeader}
                  alt={logoHeader}
                />
                <hr style={{marginTop:'0px'}}/>
                <List component="nav" aria-label="main mailbox folders">
                  {checkHakAkses(profile?.data?.hak_akses,"Rekap Live") &&(
                    <>
                      <Link
                        to="/"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <ListItemButton
                          className={
                            path === "/" ? "Side-menu-list-item-active" : {}
                          }
                        >
                          <ListItemIcon
                            style={path === "/" ? { color: PRIMARY, minWidth:'45px' } : {minWidth:'45px'}}
                          >
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Rekap Live"
                            primaryTypographyProps={
                              path === "/"
                                ? {
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: PRIMARY,
                                  }
                                : { color: "#8b8b8b" }
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Gap height={5} />
                    </>
                  )}
                  {checkHakAkses(profile?.data?.hak_akses,"Rekap Admin") && (
                    <>
                      <Link
                        to="/rekap-admin"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <ListItemButton
                          className={
                            path === "/rekap-admin" ? "Side-menu-list-item-active" : {}
                          }
                        >
                          <ListItemIcon
                            style={path === "/rekap-admin" ? { color: PRIMARY, minWidth:'45px' } : {minWidth:'45px'}}
                          >
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Rekap Admin"
                            primaryTypographyProps={
                              path === "/rekap-admin"
                                ? {
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: PRIMARY,
                                  }
                                : { color: "#8b8b8b" }
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Gap height={5} />
                    </>
                  )}
                  {checkHakAkses(profile?.data?.hak_akses,"Laporan Penjualan") && (
                    <>
                      <Link
                        to="/laporan"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <ListItemButton
                          className={
                            path === "/laporan" ? "Side-menu-list-item-active" : {}
                          }
                        >
                          <ListItemIcon
                            style={path === "/laporan" ? { color: PRIMARY, minWidth:'45px' } : {minWidth:'45px'}}
                          >
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Laporan"
                            primaryTypographyProps={
                              path === "/laporan"
                                ? {
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: PRIMARY,
                                  }
                                : { color: "#8b8b8b" }
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Gap height={5} />
                    </>
                  )}
                  {/* {checkHakAkses(profile?.data?.hak_akses,"Data Management") && ( */}
                    <>
                      <Link
                        to="/customer"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <ListItemButton
                          className={
                            path === "/customer" ? "Side-menu-list-item-active" : {}
                          }
                        >
                          <ListItemIcon
                            style={path === "/customer" ? { color: PRIMARY, minWidth:'45px' } : {minWidth:'45px'}}
                          >
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Customer"
                            primaryTypographyProps={
                              path === "/customer"
                                ? {
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: PRIMARY,
                                  }
                                : { color: "#8b8b8b" }
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Gap height={5} />
                    </>
                  {/* )} */}
                  {checkHakAkses(profile?.data?.hak_akses,"Data Management") && (
                    <>
                      <Link
                        to="/data-master"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <ListItemButton
                          className={
                            path === "/data-master" ? "Side-menu-list-item-active" : {}
                          }
                        >
                          <ListItemIcon
                            style={path === "/data-master" ? { color: PRIMARY, minWidth:'45px' } : {minWidth:'45px'}}
                          >
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Data Master"
                            primaryTypographyProps={
                              path === "/data-master"
                                ? {
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: PRIMARY,
                                  }
                                : { color: "#8b8b8b" }
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Gap height={5} />
                    </>
                  )}
                  {checkHakAkses(profile?.data?.hak_akses,"User Management") && (
                    <>
                      <Link
                        to="/user-management"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <ListItemButton
                          className={
                            path === "/user-management" ? "Side-menu-list-item-active" : {}
                          }
                        >
                          <ListItemIcon
                            style={path === "/user-management" ? { color: PRIMARY, minWidth:'45px' } : {minWidth:'45px'}}
                          >
                            <FolderIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="User Management"
                            primaryTypographyProps={
                              path === "/user-management"
                                ? {
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: PRIMARY,
                                  }
                                : { color: "#8b8b8b" }
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Gap height={5} />
                    </>
                  )}

                </List>
              </Box>
            </Drawer>
          </div>
          <div className="responsive-mobile">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "50px" }}
                src={logoHeader}
                alt={logoHeader}
              />
            </div>
            <Gap width={20} />
            <p>Sistem Rekap Penjualan Live</p>
          </div>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleClick}
          >
            <p style={{ fontSize: "13px" }}>{profile?.data?.nama}</p>
            <span style={{ marginTop: 10 }}>
              <KeyboardArrowDownIcon />
            </span>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{ vertical: "right", horizontal: "right" }}
          >
            <MenuItem onClick={() => handleClose("profile")}>Profil</MenuItem>
            <MenuItem onClick={() => handleClose("logout")}>Keluar</MenuItem>
          </Menu>
        </Toolbar>
      </div>
      {/* </Container> */}
    </AppBar>
  );
};
export default ResponsiveAppBar;
