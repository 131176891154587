import React from "react";
import Customer from "../../components/organism/Customer";

const PageCustomer = () => {
  return (
    <>
      <Customer />
    </>
  );
};

export default PageCustomer;
