import React from "react";
import MetodePembayaran from "../../components/organism/MetodePembayaran";

const PageMetodePembayaran = () => {
  return (
    <>
      <MetodePembayaran />
    </>
  );
};

export default PageMetodePembayaran;
