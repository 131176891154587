import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { Gap, MyButton, MyInput } from "../../atoms";
import MyDialog from "../../molecules/Dialog";
import { PRIMARY } from "../../../utils/constansColors";
import AlertMessage from "../../../components/molecules/AlertMessage";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllEventBarang, updateEventBarang, deleteEventBarang } from "../../../config/Redux/EventBarang/actions";
import { useParams } from "react-router-dom";


const validationSchemaEdit = yup.object().shape({
  kode_barang: yup.string("Masukkan Kode Barang").required("Silahkan Masukkan Kode Barang"),
  nama_barang: yup.string("Masukkan Nama Barang").required("Silahkan Masukkan Nama Barang"),
  harga_barang: yup.string("Masukkan Harga Barang").required("Silahkan Masukkan Harga Barang"),
});

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const columnData = [
  { no: 0, name: "No" },
  { no: 1, name: "Nama Barang" },
  { no: 2, name: "Kode Barang" },
  { no: 3, name: "Harga Barang" },
  { no: 4, name: "Atur Barang" },
];

const BasicTable = ({ search,dataCustomer, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
  const [dataPesananCustomer, setPesananCustomer] = React.useState(null);

  // alertMessage
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [alasan, setAlasan] = React.useState("");

  // selector
  const dataAllEventBarang = useSelector((state) => state.eventlivebarang.dataAllEventBarang);
  // console.log(dataAllEventBarang)

  // change password
  const handleOpenDialogDelete = (item) => {
    setPesananCustomer(item);
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  // edit profile
  const handleOpenDialogProfile = (item) => {
    setPesananCustomer(item);
    setOpenDialogProfile(true);
    formikProfile.resetForm();
  };

  const handleCloseDialogProfile = () => {
    setOpenDialogProfile(false);
  };


  // formik edit data profile
  const formikProfile = useFormik({
    initialValues: {
      kode_barang: dataPesananCustomer?.kode_barang || "",
      nama_barang: dataPesananCustomer?.nama_barang || "",
      harga_barang: dataPesananCustomer?.harga_barang || 0,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaEdit,
    onSubmit: async (values) => {
      let payload = {
        id_rekap: params.pesananID,
        nama_barang: values.nama_barang,
        kode_barang: values.kode_barang,
        harga_barang: values.harga_barang,
      };
      let resp = await dispatch(updateEventBarang(dataPesananCustomer?.id_rekap_barang,payload));
      if (resp.type === "PUT_EVENT_BARANG_SUCCESS") {
        handleCloseDialogProfile();
        setMessage(resp.payload?.message);
        setOpenDialogAlert(true);
        dispatch(getAllEventBarang(1, search, "", "desc", 300, params.pesananID))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
    },
  });
  const handleDelete = async (id) => {
    let payload = {
      alasan:alasan,
      is_rekap_admin: true,
    }
    let resp = await dispatch(deleteEventBarang(dataPesananCustomer?.id_rekap_barang,payload));
      if (resp.type === "DELETE_EVENT_BARANG_SUCCESS") {
        handleCloseDialogDelete();
        setAlasan("")
        setMessage(resp.payload?.message);
        setOpenDialogAlert(true);
        dispatch(getAllEventBarang(1, search, "", "desc", 300, params.pesananID))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
  }
  // form Ubah data
  const renderFormProfile = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Data</b>
        </h2>
      </div>
      <MyInput
        id="nama_barang"
        name="nama_barang"
        label="Nama Barang"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_barang && Boolean(formikProfile.errors.nama_barang)}
        helperText={formikProfile.touched.nama_barang && formikProfile.errors.nama_barang}
      />
      <Gap height={20} />
      <MyInput
        id="kode_barang"
        name="kode_barang"
        label="Kode Barang"
        fullWidth
        size="medium"
        value={formikProfile.values.kode_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.kode_barang && Boolean(formikProfile.errors.kode_barang)}
        helperText={formikProfile.touched.kode_barang && formikProfile.errors.kode_barang}
      />
      <Gap height={20} />
      <MyInput
        id="harga_barang"
        name="harga_barang"
        label="Harga Barang"
        fullWidth
        size="medium"
        type="number"
        value={formikProfile.values.harga_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.harga_barang && Boolean(formikProfile.errors.harga_barang)}
        helperText={formikProfile.touched.harga_barang && formikProfile.errors.harga_barang}
      />
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogProfile}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  const handleAlasan = (event) =>{
    setAlasan(event.target.value)
  }
  // form Ubah data
  const renderFormDetail = (
    <>
      <div align="center">
        <h2>
          <b>Apakah Anda yakin akan menghapus pesanan barang ini?</b>
        </h2>
        <div>
          <span>Masukkan alasan penghapusan barang</span><br/><br/>
          <input type="text" value={alasan} onChange={handleAlasan}/>
        </div>
      </div>
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Tidak"
          onClick={handleCloseDialogDelete}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#fff"
          colorHover="white"
          fullWidth
          backgroundColor={(alasan === "")?"lightgrey":"red"}
          borderColor={(alasan === "")?"lightgrey":"red"}
          backgroundColorHover={(alasan === "")?"lightgrey":"red"}
          variant="outlined"
          children="Ya"
          disabled={(alasan === "")?true:false}
          onClick={()=>handleDelete(dataPesananCustomer?.id_rekap_barang)}
        />
      </div>
    </>
  );
  return (
    <>
      {/* change password */}
      <MyDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        renderValue={renderFormDetail}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* edit user */}
      <MyDialog
        openDialog={openDialogProfile}
        handleCloseDialog={handleCloseDialogProfile}
        renderValue={renderFormProfile}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />

      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className={classes.tableHeader}
        >
          <TableHead>
            <TableRow>
              {columnData.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 700,
                      color: "#757575",
                      width:
                        item.name === "No"
                          ? "3%"
                          : ""
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        {dataAllEventBarang?.data?.data?.map((item, index) => {
          let number = index + 1 + 10 * (dataAllEventBarang?.data?.current_page - 1);
          return (
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className={classes.tableBody}
              key={index}
            >
              <TableBody>
                <TableRow key={item.id}>
                  <TableCell width="3%">{number}</TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word" }}>
                      {item?.nama_barang || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word", }}>
                      {item.kode_barang}
                    </div>
                  </TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word", }}>
                      {parseInt(item?.harga_barang)?.toLocaleString().replace(/,/g,".",)}
                    </div>
                  </TableCell>
                  <TableCell>
                  {(dataCustomer?.status_pesanan?.status === "Keep")?
                    <div
                      style={{
                        textAlign:'right',
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <MyButton
                          startIcon={<DeleteIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          colorHover="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor={"red"}
                          borderColor={"red"}
                          backgroundColorHover={"red"}
                          variant="outlined"
                          children="Hapus Barang"
                          onClick={() => handleOpenDialogDelete(item)}
                        />
                        <Gap width={10} />
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Ubah Barang"
                          onClick={() => handleOpenDialogProfile(item)}
                        />
                      </div>
                    </div>
                    :<span style={{fontSize:'9px'}}>Pengaturan hanya untuk status pesanan "Keep"</span>}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
    </>
  );
}

export default BasicTable;