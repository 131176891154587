import {
  GET_LAPORAN_LIVE_EVENT,
  GET_LAPORAN_LIVE_EVENT_SUCCESS,
  GET_LAPORAN_LIVE_EVENT_FAIL,
  GET_LAPORAN_REKAP,
  GET_LAPORAN_REKAP_SUCCESS,
  GET_LAPORAN_REKAP_FAIL,
} from "./actionTypes";

const initialState = {
  dataLaporanLiveEvent: {
    loading: false,
    error: false,
    data: null,
  },
  dataLaporanRekap: {
    loading: false,
    error: false,
    data: null,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_LAPORAN_LIVE_EVENT:
      return {
        ...state,
        dataLaporanLiveEvent: {
          ...state.dataLaporanLiveEvent,
          loading: true,
          error: false,
        },
      };
    case GET_LAPORAN_LIVE_EVENT_SUCCESS:
      return {
        ...state,
        dataLaporanLiveEvent: {
          ...state.dataLaporanLiveEvent,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_LAPORAN_LIVE_EVENT_FAIL:
      return {
        ...state,
        dataLaporanLiveEvent: {
          ...state.dataLaporanLiveEvent,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_LAPORAN_REKAP:
      return {
        ...state,
        dataLaporanRekap: {
          ...state.dataLaporanRekap,
          loading: true,
          error: false,
        },
      };
    case GET_LAPORAN_REKAP_SUCCESS:
      return {
        ...state,
        dataLaporanRekap: {
          ...state.dataLaporanRekap,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_LAPORAN_REKAP_FAIL:
      return {
        ...state,
        dataLaporanRekap: {
          ...state.dataLaporanRekap,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
