import React from "react";
import UserManagement from "../../components/organism/UserManagement";

const PageUserManagement = () => {
  return (
    <>
      <UserManagement />
    </>
  );
};

export default PageUserManagement;
