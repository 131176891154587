import axios from "axios";
import { BASE_URL, LOCAL_STORAGE_KEY } from "./config";
export const API = {
  login,
  getProvince,
  getKabKot,
  getKecamatan,
  getKelurahan,
  getTingkatExco,
  getJabatan,
  getProfile,
  changePassword,
  getUsers,
  getUserManagement,
  patchUserManagement,
  addNewUser,
  getHakAkses,
  updateHakAkses,
  updateHakAkun,
  getAllCustomer,
  getCustomer,
  createCustomer,
  updateCustomer,
  getAllAkunLive,
  getAkunLive,
  createAkunLive,
  updateAkunLive,
  getAkunLiveProfile,
  getAllReviewer,
  getReviewer,
  createReviewer,
  updateReviewer,
  getWaktuLive,
  getAllPembayaran,
  getPembayaran,
  createPembayaran,
  updatePembayaran,
  getAllPengiriman,
  getPengiriman,
  createPengiriman,
  updatePengiriman,
  getKategoriArea,
  getKategoriPembayaran,
  getKategoriPengiriman,
  getAllEvent,
  getAllEventLast,
  getEvent,
  updateEvent,
  createEvent,
  deleteEvent,
  assignReviewer,
  getAllEventPesanan,
  updateEventPesanan,
  createEventPesanan,
  deleteEventPesanan,
  getAllEventBarang,
  updateEventBarang,
  createEventBarang,
  deleteEventBarang,
  getAllStatusPesanan,
  getLaporanLiveEvent,
  getLaporanRekap
};

const defaultHeaders = {
  "Content-Type": "application/json",
};

const loadAuthToken = () => {
  let result = null;
  const storedCreds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  result = storedCreds && storedCreds.token;

  return result;
};

// auth login
async function login(data) {
  try {
    const url = `${BASE_URL}/user/login`;
    const headers = defaultHeaders;
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get province
async function getProvince() {
  try {
    const url = `${BASE_URL}/wilayah/provinsi`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}
// get kabkot
async function getKabKot(id) {
  try {
    const url = `${BASE_URL}/wilayah/kota-kabupaten?provinsi=${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get kecamtan
async function getKecamatan(param) {
  try {
    const url = `${BASE_URL}/wilayah/kecamatan?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get kelurahan
async function getKelurahan(param) {
  try {
    const url = `${BASE_URL}/wilayah/kelurahan?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get /tingkat-exco
async function getTingkatExco() {
  try {
    const url = `${BASE_URL}/wilayah/tingkat-exco`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get jabatan
async function getJabatan(param) {
  try {
    const url = `${BASE_URL}/wilayah/jabatan?id_tingkat_exco=${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get profile
async function getProfile() {
  try {
    const url = `${BASE_URL}/user/profile`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// change password
async function changePassword(data) {
  console.log(`changePassword data`, data);
  try {
    const url = `${BASE_URL}/user/profile`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// USER
async function getUsers(page, search, sortBy, sortType) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    const url = `${BASE_URL}/user/get-all?${param}&size=10`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// get detail users
async function getUserManagement(param) {
  try {
    const url = `${BASE_URL}/user/id/${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// put/patch detail users
async function patchUserManagement(id, data) {
  try {
    const url = `${BASE_URL}/user/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// CHANGE PASSWORD USER
async function addNewUser(data) {
  try {
    const url = `${BASE_URL}/user/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateHakAkses(id_user,id_hak_akses) {
  try {
    const url = `${BASE_URL}/user/hak-akses/${id_user}/${id_hak_akses}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateHakAkun(id_user,id_hak_akses) {
  try {
    const url = `${BASE_URL}/user/hak-akun-live/${id_user}/${id_hak_akses}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// CUSTOMER
async function getAllCustomer(page, search, sortBy, sortType, filterArea) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    if (filterArea) {
      param = param + `&id_kategori_area=${filterArea}`;
    }
    const url = `${BASE_URL}/customer/get-all?${param}&size=10`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getCustomer(param) {
  try {
    const url = `${BASE_URL}/customer/id/${param}&is_pesanan=true`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createCustomer(data) {
  try {
    const url = `${BASE_URL}/customer/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateCustomer(id, data) {
  try {
    const url = `${BASE_URL}/customer/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// AKUN LIVE
async function getAllAkunLive(page, search, sortBy, sortType) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    const url = `${BASE_URL}/akun-live/get-all?${param}&size=20`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getAkunLive(param) {
  try {
    const url = `${BASE_URL}/akun-live/id/${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createAkunLive(data) {
  try {
    const url = `${BASE_URL}/akun-live/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateAkunLive(id, data) {
  try {
    const url = `${BASE_URL}/akun-live/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getAkunLiveProfile(page, search, sortBy, sortType) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    const url = `${BASE_URL}/akun-live/profile?`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// REVIEWER
async function getAllReviewer(page, search, sortBy, sortType) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    const url = `${BASE_URL}/reviewer/get-all?${param}&size=10`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getReviewer(param) {
  try {
    const url = `${BASE_URL}/reviewer/id/${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createReviewer(data) {
  try {
    const url = `${BASE_URL}/reviewer/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateReviewer(id, data) {
  try {
    const url = `${BASE_URL}/reviewer/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// METODE PENGIRIMAN
async function getAllPengiriman(page, search, sortBy, sortType) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    const url = `${BASE_URL}/metode-pengiriman/get-all?${param}&size=100`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getPengiriman(param) {
  try {
    const url = `${BASE_URL}/metode-pengiriman/id/${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createPengiriman(data) {
  try {
    const url = `${BASE_URL}/metode-pengiriman/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updatePengiriman(id, data) {
  try {
    const url = `${BASE_URL}/metode-pengiriman/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// METODE PEMBAYARAN
async function getAllPembayaran(page, search, sortBy, sortType) {
  try {
    let param = `page=${page}`;
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    const url = `${BASE_URL}/metode-pembayaran/get-all?${param}&size=100`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getPembayaran(param) {
  try {
    const url = `${BASE_URL}/metode-pembayaran/id/${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createPembayaran(data) {
  try {
    const url = `${BASE_URL}/metode-pembayaran/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updatePembayaran(id, data) {
  try {
    const url = `${BASE_URL}/metode-pembayaran/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// HAK AKSES
async function getHakAkses() {
  try {
    const url = `${BASE_URL}/hak-akses/get-all?`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// WAKTU LIVE
async function getWaktuLive() {
  try {
    const url = `${BASE_URL}/waktu-live/get-all?`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// KATEGORI AREA
async function getKategoriArea() {
  try {
    const url = `${BASE_URL}/kategori-area/get-all?`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// KATEGORI PENGIRIMAN
async function getKategoriPengiriman() {
  try {
    const url = `${BASE_URL}/metode-pengiriman/kategori?`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// KATEGORI PEMBAYARAN
async function getKategoriPembayaran() {
  try {
    const url = `${BASE_URL}/metode-pembayaran/kategori?`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// EVENT
async function getAllEvent(filterTanggal) {
  try {
    let param = ``;
    if (filterTanggal) {
      param = param + `tanggal_live_event=${filterTanggal}`;
    }
    const url = `${BASE_URL}/live-event/get-all?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getAllEventLast(filterTanggal) {
  try {
    let param = ``;
    if (filterTanggal) {
      param = param + `tanggal_live_event=${filterTanggal}`;
    }
    const url = `${BASE_URL}/live-event/get-7-days?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getEvent(param) {
  try {
    const url = `${BASE_URL}/live-event/detail?id_live_event=${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createEvent(data) {
  try {
    const url = `${BASE_URL}/live-event/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateEvent(data) {
  try {
    const url = `${BASE_URL}/live-event/update`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function deleteEvent(data) {
  try {
    const url = `${BASE_URL}/live-event/delete`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "DELETE",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// EVENT PESANAN
async function getAllEventPesanan(page, search, sortBy, sortType, size, id_live_event) {
  try {
    let param = ``;
    if (page) {
      param = param + `page=${page}`;
    }
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    if (size) {
      param = param + `&size=${size}`;
    }
    if (id_live_event) {
      param = param + `&id_live_event=${id_live_event}`;
    }
    const url = `${BASE_URL}/rekap-pesanan/get-all?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createEventPesanan(data) {
  try {
    const url = `${BASE_URL}/rekap-pesanan/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateEventPesanan(id,data) {
  try {
    const url = `${BASE_URL}/rekap-pesanan/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function deleteEventPesanan(id) {
  try {
    const url = `${BASE_URL}/rekap-pesanan/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "DELETE",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

// EVENT PESANAN BARANG
async function getAllEventBarang(page, search, sortBy, sortType, size, id_rekap) {
  try {
    let param = ``;
    if (page) {
      param = param + `page=${page}`;
    }
    if (search) {
      param = param + `&search=${search}`;
    }
    if (sortBy) {
      param = param + `&order_by=${sortBy}`;
    }
    if (sortType) {
      param = param + `&order_type=${sortType}`;
    }
    if (size) {
      param = param + `&size=${size}`;
    }
    if (id_rekap) {
      param = param + `&id_rekap=${id_rekap}`;
    }
    const url = `${BASE_URL}/rekap-barang/get-all?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function createEventBarang(data) {
  try {
    const url = `${BASE_URL}/rekap-barang/create`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function updateEventBarang(id,data) {
  try {
    const url = `${BASE_URL}/rekap-barang/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function deleteEventBarang(id,data) {
  try {
    const url = `${BASE_URL}/rekap-barang/id/${id}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "DELETE",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function assignReviewer(data) {
  try {
    const url = `${BASE_URL}/live-event/assign-reviewer`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "POST",
      url,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getAllStatusPesanan() {
  try {
    const url = `${BASE_URL}/status-pesanan/get-all`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getLaporanLiveEvent(date_type, tanggal, kategori_visual) {
  try {
    let param = ``;
    if (date_type) {
      param = param + `date_type=${date_type}`;
    }
    if (tanggal) {
      param = param + `&tanggal=${tanggal}`;
    }
    if (kategori_visual) {
      param = param + `&kategori_visual=${kategori_visual}`;
    }
    
    const url = `${BASE_URL}/laporan/live-event?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

async function getLaporanRekap(date_type, tanggal, kategori_visual, id_status_pesanan) {
  try {
    let param = ``;
    if (date_type) {
      param = param + `date_type=${date_type}`;
    }
    if (tanggal) {
      param = param + `&tanggal=${tanggal}`;
    }
    if (kategori_visual) {
      param = param + `&kategori_visual=${kategori_visual}`;
    }
    if (id_status_pesanan) {
      param = param + `&id_status_pesanan=${id_status_pesanan}`;
    }
    
    const url = `${BASE_URL}/laporan/rekap?${param}`;
    const authorization = "Bearer " + loadAuthToken();
    const headers = {
      defaultHeaders,
      authorization,
    };
    const response = await axios({
      method: "GET",
      url,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}