export const GET_REVIEWER = "GET_REVIEWER";
export const GET_REVIEWER_SUCCESS = "GET_REVIEWER_SUCCESS";
export const GET_REVIEWER_FAIL = "GET_REVIEWER_FAIL";

// get detail user
export const GET_DETAIL_REVIEWER = "GET_DETAIL_REVIEWER";
export const GET_DETAIL_REVIEWER_SUCCESS = "GET_DETAIL_REVIEWER_SUCCESS";
export const GET_DETAIL_REVIEWER_FAIL = "GET_DETAIL_REVIEWER_FAIL";

// put detail user
export const PUT_REVIEWER = "PUT_REVIEWER";
export const PUT_REVIEWER_SUCCESS = "PUT_REVIEWER_SUCCESS";
export const PUT_REVIEWER_FAIL = "PUT_REVIEWER_FAIL";

// add new user
export const ADD_REVIEWER = "ADD_REVIEWER";
export const ADD_REVIEWER_SUCCESS = "ADD_REVIEWER_SUCCESS";
export const ADD_REVIEWER_FAIL = "ADD_REVIEWER_FAIL";