import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import { logger } from "redux-logger";
import createRootReducer from "./combineReducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewareList = [thunk];
const store = createStore(
  createRootReducer(),
  composeEnhancer(applyMiddleware(...middlewareList))
);

export default store;
