export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

// get detail user
export const GET_DETAIL_USER = "GET_DETAIL_USER";
export const GET_DETAIL_USER_SUCCESS = "GET_DETAIL_USER_SUCCESS";
export const GET_DETAIL_USER_FAIL = "GET_DETAIL_USER_FAIL";

// put detail user
export const PUT_USERS = "PUT_USERS";
export const PUT_USERS_SUCCESS = "PUT_USERS_SUCCESS";
export const PUT_USERS_FAIL = "PUT_USERS_FAIL";

// change password user
export const CHANGE_PASSWORD_USER = "CHANGE_PASSWORD_USER";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";
export const CHANGE_PASSWORD_USER_FAIL = "CHANGE_PASSWORD_USER_FAIL";

// add new user
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";

// set hak akses
export const GET_HAK_AKSES = "GET_HAK_AKSES";
export const GET_HAK_AKSES_SUCCESS = "GET_HAK_AKSES_SUCCESS";
export const GET_HAK_AKSES_FAIL = "GET_HAK_AKSES_FAIL";

// update hak akses
export const POST_HAK_AKSES = "POST_HAK_AKSES";
export const POST_HAK_AKSES_SUCCESS = "POST_HAK_AKSES_SUCCESS";
export const POST_HAK_AKSES_FAIL = "POST_HAK_AKSES_FAIL";

// update hak akun
export const POST_HAK_AKUN = "POST_HAK_AKUN";
export const POST_HAK_AKUN_SUCCESS = "POST_HAK_AKUN_SUCCESS";
export const POST_HAK_AKUN_FAIL = "POST_HAK_AKUN_FAIL";