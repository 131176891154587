import React from "react";
import Reviewer from "../../components/organism/Reviewer";

const PageReviewer = () => {
  return (
    <>
      <Reviewer />
    </>
  );
};

export default PageReviewer;
