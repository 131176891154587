import {
  GET_AKUN_LIVE,
  GET_AKUN_LIVE_SUCCESS,
  GET_AKUN_LIVE_FAIL,
  GET_DETAIL_AKUN_LIVE,
  GET_DETAIL_AKUN_LIVE_SUCCESS,
  GET_DETAIL_AKUN_LIVE_FAIL,
  PUT_AKUN_LIVE,
  PUT_AKUN_LIVE_SUCCESS,
  PUT_AKUN_LIVE_FAIL,
  ADD_AKUN_LIVE,
  ADD_AKUN_LIVE_SUCCESS,
  ADD_AKUN_LIVE_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllAkunLive =
  (page, search, sortBy, sortType, filterArea) => (dispatch) => {
    dispatch({
      type: GET_AKUN_LIVE,
    });

    const request = API.getAllAkunLive(page, search, sortBy, sortType, filterArea);
    return request.then(
      (response) => dispatch(akunlive(response.data,GET_AKUN_LIVE_SUCCESS)),
      (err) => dispatch(akunlive(err,GET_AKUN_LIVE_FAIL))
    );
  };

  export const getAkunLive = (param) => (dispatch) => {
    dispatch({
      type: GET_DETAIL_AKUN_LIVE,
    });

    const request = API.getAkunLive(param);
    return request.then(
      (response) => dispatch(akunlive(response.data,GET_DETAIL_AKUN_LIVE_SUCCESS)),
      (err) => dispatch(akunlive(err,GET_DETAIL_AKUN_LIVE_FAIL))
    );
  };

// update user
export const updateAkunLive = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_AKUN_LIVE,
  });

  const request = API.updateAkunLive(id, data);
  return request.then(
    (response) => dispatch(akunlive(response.data,PUT_AKUN_LIVE_SUCCESS)),
    (err) => dispatch(akunlive(err,PUT_AKUN_LIVE_FAIL))
  );
};

// add new user
export const createAkunLive = (data) => (dispatch) => {
  dispatch({
    type: ADD_AKUN_LIVE,
  });

  const request = API.createAkunLive(data);
  return request.then(
    (response) => dispatch(akunlive(response.data,ADD_AKUN_LIVE_SUCCESS)),
    (err) => dispatch(akunlive(err,ADD_AKUN_LIVE_FAIL))
  );
};

export const akunlive = (data,type,a) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
    a:a
  });
};
