import React from "react";
import RekapLivePesanan from "../../components/organism/RekapLivePesanan";

const PageRekapLivePesanan = () => {
  return (
    <>
      <RekapLivePesanan />
    </>
  );
};

export default PageRekapLivePesanan;
