import React from "react";
import Laporan from "../../components/organism/Laporan";

const PageLaporan = () => {
  return (
    <>
      <Laporan />
    </>
  );
};

export default PageLaporan;
