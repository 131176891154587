import React from "react";
import MasterData from "../../components/organism/MasterData";

const PageMasterData = () => {
  return (
    <>
      <MasterData />
    </>
  );
};

export default PageMasterData;
