import { combineReducers } from "redux";
import auth from "./Auth/reducers";
import global from "./Global/reducers";
import users from "./UserManagement/reducers";
import customer from "./Customer/reducers";
import reviewer from "./Reviewer/reducers";
import akunlive from "./AkunLive/reducers";
import metodepembayaran from "./MetodePembayaran/reducers";
import metodepengiriman from "./MetodePengiriman/reducers";
import eventlive from "./Event/reducers";
import eventlivepesanan from "./EventPesanan/reducers";
import eventlivebarang from "./EventBarang/reducers";
import laporan from "./Laporan/reducers";
const createRootReducer = () =>
  combineReducers({
    auth,
    global,
    users,
    customer,
    reviewer,
    akunlive,
    metodepembayaran,
    metodepengiriman,
    eventlive,
    eventlivepesanan,
    eventlivebarang,
    laporan
  });

export default createRootReducer;
