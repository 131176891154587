export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const GET_WAKTU_LIVE = "GET_WAKTU_LIVE";
export const GET_WAKTU_LIVE_SUCCESS = "GET_WAKTU_LIVE_SUCCESS";
export const GET_WAKTU_LIVE_FAIL = "GET_WAKTU_LIVE_FAIL";

export const GET_AKUN_LIVE = "GET_AKUN_LIVE";
export const GET_AKUN_LIVE_SUCCESS = "GET_AKUN_LIVE_SUCCESS";
export const GET_AKUN_LIVE_FAIL = "GET_AKUN_LIVE_FAIL";

export const GET_KATEGORI_AREA = "GET_KATEGORI_AREA";
export const GET_KATEGORI_AREA_SUCCESS = "GET_KATEGORI_AREA_SUCCESS";
export const GET_KATEGORI_AREA_FAIL = "GET_KATEGORI_AREA_FAIL";

export const GET_KATEGORI_PENGIRIMAN = "GET_KATEGORI_PENGIRIMAN";
export const GET_KATEGORI_PENGIRIMAN_SUCCESS = "GET_KATEGORI_PENGIRIMAN_SUCCESS";
export const GET_KATEGORI_PENGIRIMAN_FAIL = "GET_KATEGORI_PENGIRIMAN_FAIL";

export const GET_KATEGORI_PEMBAYARAN = "GET_KATEGORI_PEMBAYARAN";
export const GET_KATEGORI_PEMBAYARAN_SUCCESS = "GET_KATEGORI_PEMBAYARAN_SUCCESS";
export const GET_KATEGORI_PEMBAYARAN_FAIL = "GET_KATEGORI_PEMBAYARAN_FAIL";

export const GET_STATUS_PESANAN = "GET_STATUS_PESANAN";
export const GET_STATUS_PESANAN_SUCCESS = "GET_STATUS_PESANAN_SUCCESS";
export const GET_STATUS_PESANAN_FAIL = "GET_STATUS_PESANAN_FAIL";