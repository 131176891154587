import {
  GET_ALL_EVENT_PESANAN,
  GET_ALL_EVENT_PESANAN_SUCCESS,
  GET_ALL_EVENT_PESANAN_FAIL,
  GET_EVENT_PESANAN_DETAIL,
  GET_EVENT_PESANAN_DETAIL_SUCCESS,
  GET_EVENT_PESANAN_DETAIL_FAIL,
  PUT_EVENT_PESANAN,
  PUT_EVENT_PESANAN_SUCCESS,
  PUT_EVENT_PESANAN_FAIL,
  ADD_EVENT_PESANAN,
  ADD_EVENT_PESANAN_SUCCESS,
  ADD_EVENT_PESANAN_FAIL,
  DELETE_EVENT_PESANAN,
  DELETE_EVENT_PESANAN_SUCCESS,
  DELETE_EVENT_PESANAN_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllEventPesanan = (page, search, sortBy, sortType, size, id_live_event) => (dispatch) => {
    dispatch({
      type: GET_ALL_EVENT_PESANAN,
    });

    const request = API.getAllEventPesanan(page, search, sortBy, sortType, size, id_live_event);
    return request.then(
      (response) => dispatch(eventLive(response.data,GET_ALL_EVENT_PESANAN_SUCCESS)),
      (err) => dispatch(eventLive(err,GET_ALL_EVENT_PESANAN_FAIL))
    );
  };


export const getEventPesanan = (param) => (dispatch) => {
  dispatch({
    type: GET_EVENT_PESANAN_DETAIL,
  });

  const request = API.getEventPesanan(param);
  return request.then(
    (response) => dispatch(eventLive(response.data,GET_EVENT_PESANAN_DETAIL_SUCCESS)),
    (err) => dispatch(eventLive(err,GET_EVENT_PESANAN_DETAIL_FAIL))
  );
};

// update user
export const updateEventPesanan = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_EVENT_PESANAN,
  });

  const request = API.updateEventPesanan(id, data);
  return request.then(
    (response) => dispatch(eventLive(response.data,PUT_EVENT_PESANAN_SUCCESS)),
    (err) => dispatch(eventLive(err,PUT_EVENT_PESANAN_FAIL))
  );
};

// add new user
export const createEventPesanan = (data) => (dispatch) => {
  dispatch({
    type: ADD_EVENT_PESANAN,
  });

  const request = API.createEventPesanan(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,ADD_EVENT_PESANAN_SUCCESS)),
    (err) => dispatch(eventLive(err,ADD_EVENT_PESANAN_FAIL))
  );
};

export const deleteEventPesanan = (data) => (dispatch) => {
  dispatch({
    type: DELETE_EVENT_PESANAN,
  });

  const request = API.deleteEventPesanan(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,DELETE_EVENT_PESANAN_SUCCESS)),
    (err) => dispatch(eventLive(err,DELETE_EVENT_PESANAN_FAIL))
  );
};

export const eventLive = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
