import MyRoutes from "../config/Routes";

function App() {
  return (
    <>
      <MyRoutes />
    </>
  );
}

export default App;
