import {
  GET_ALL_EVENT,
  GET_ALL_EVENT_SUCCESS,
  GET_ALL_EVENT_FAIL,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  GET_ALL_EVENT_LAST,
  GET_ALL_EVENT_LAST_SUCCESS,
  GET_ALL_EVENT_LAST_FAIL,
  PUT_EVENT,
  PUT_EVENT_SUCCESS,
  PUT_EVENT_FAIL,
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  ASSIGN_REVIEWER_EVENT,
  ASSIGN_REVIEWER_EVENT_SUCCESS,
  ASSIGN_REVIEWER_EVENT_FAIL,
} from "./actionTypes";

const initialState = {
  dataAllEvent: {
    loading: false,
    error: false,
    data: null,
  },
  dataAllEventLast: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailEvent: {
    loading: false,
    error: false,
    data: null,
  },
  updateEvent: {
    loading: false,
    error: false,
    data: null,
  },
  addEvent: {
    loading: false,
    error: false,
    data: null,
  },
  deleteEvent: {
    loading: false,
    error: false,
    data: null,
  },
  assignReviewer: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_ALL_EVENT:
      return {
        ...state,
        dataAllEvent: {
          ...state.dataAllEvent,
          loading: true,
          error: false,
        },
      };
    case GET_ALL_EVENT_SUCCESS:
      return {
        ...state,
        dataAllEvent: {
          ...state.dataAllEvent,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_ALL_EVENT_FAIL:
      return {
        ...state,
        dataAllEvent: {
          ...state.dataAllEvent,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_EVENT_DETAIL:
      return {
        ...state,
        dataDetailEvent: {
          ...state.dataDetailEvent,
          loading: true,
          error: false,
        },
      };
    case GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        dataDetailEvent: {
          ...state.dataDetailEvent,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_EVENT_DETAIL_FAIL:
      return {
        ...state,
        dataDetailEvent: {
          ...state.dataDetailEvent,
          loading: false,
          error: action.payload,
        },
      };
      case GET_ALL_EVENT_LAST:
        return {
          ...state,
          dataAllEventLast: {
            ...state.dataAllEventLast,
            loading: true,
            error: false,
          },
        };
      case GET_ALL_EVENT_LAST_SUCCESS:
        return {
          ...state,
          dataAllEventLast: {
            ...state.dataAllEventLast,
            loading: false,
            error: false,
            data: action.payload,
          },
        };
      case GET_ALL_EVENT_LAST_FAIL:
        return {
          ...state,
          dataAllEventLast: {
            ...state.dataAllEventLast,
            loading: false,
            error: action.payload,
          },
        };
    // update user
    case PUT_EVENT:
      return {
        ...state,
        updateEvent: {
          ...state.updateEvent,
          loading: true,
          error: false,
        },
      };
    case PUT_EVENT_SUCCESS:
      return {
        ...state,
        updateEvent: {
          ...state.updateEvent,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_EVENT_FAIL:
      return {
        ...state,
        updateEvent: {
          ...state.updateEvent,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_EVENT:
      return {
        ...state,
        addEvent: {
          ...state.addEvent,
          loading: true,
          error: false,
        },
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        addEvent: {
          ...state.addEvent,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_EVENT_FAIL:
      return {
        ...state,
        addEvent: {
          ...state.addEvent,
          loading: false,
          error: action.payload,
        },
      };
      case DELETE_EVENT:
        return {
          ...state,
          deleteEvent: {
            ...state.deleteEvent,
            loading: true,
            error: false,
          },
        };
      case DELETE_EVENT_SUCCESS:
        return {
          ...state,
          deleteEvent: {
            ...state.deleteEvent,
            loading: false,
            error: false,
            data: action.payload,
          },
        };
      case DELETE_EVENT_FAIL:
        return {
          ...state,
          deleteEvent: {
            ...state.deleteEvent,
            loading: false,
            error: action.payload,
          },
        };
        case ASSIGN_REVIEWER_EVENT:
          return {
            ...state,
            assignReviewer: {
              ...state.assignReviewer,
              loading: true,
              error: false,
            },
          };
        case ASSIGN_REVIEWER_EVENT_SUCCESS:
          return {
            ...state,
            assignReviewer: {
              ...state.assignReviewer,
              loading: false,
              error: false,
              data: action.payload,
            },
          };
        case ASSIGN_REVIEWER_EVENT_FAIL:
          return {
            ...state,
            assignReviewer: {
              ...state.assignReviewer,
              loading: false,
              error: action.payload,
            },
          };
    default:
      return state;
  }
};

export default reducer;
