import {
  GET_AKUN_LIVE,
  GET_AKUN_LIVE_SUCCESS,
  GET_AKUN_LIVE_FAIL,
  GET_DETAIL_AKUN_LIVE,
  GET_DETAIL_AKUN_LIVE_SUCCESS,
  GET_DETAIL_AKUN_LIVE_FAIL,
  PUT_AKUN_LIVE,
  PUT_AKUN_LIVE_SUCCESS,
  PUT_AKUN_LIVE_FAIL,
  ADD_AKUN_LIVE,
  ADD_AKUN_LIVE_SUCCESS,
  ADD_AKUN_LIVE_FAIL,
} from "./actionTypes";

const initialState = {
  dataAkunLive: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailAkunLive: {
    loading: false,
    error: false,
    data: null,
  },
  updateAkunLive: {
    loading: false,
    error: false,
    data: null,
  },
  addAkunLive: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_AKUN_LIVE:
      return {
        ...state,
        dataAkunLive: {
          ...state.dataAkunLive,
          loading: true,
          error: false,
        },
      };
    case GET_AKUN_LIVE_SUCCESS:
      return {
        ...state,
        dataAkunLive: {
          ...state.dataAkunLive,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_AKUN_LIVE_FAIL:
      return {
        ...state,
        dataAkunLive: {
          ...state.dataAkunLive,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_DETAIL_AKUN_LIVE:
      return {
        ...state,
        dataDetailAkunLive: {
          ...state.dataDetailAkunLive,
          loading: true,
          error: false,
        },
      };
    case GET_DETAIL_AKUN_LIVE_SUCCESS:
      return {
        ...state,
        dataDetailAkunLive: {
          ...state.dataDetailAkunLive,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_DETAIL_AKUN_LIVE_FAIL:
      return {
        ...state,
        dataDetailAkunLive: {
          ...state.dataDetailAkunLive,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_AKUN_LIVE:
      return {
        ...state,
        updateAkunLive: {
          ...state.updateAkunLive,
          loading: true,
          error: false,
        },
      };
    case PUT_AKUN_LIVE_SUCCESS:
      return {
        ...state,
        updateAkunLive: {
          ...state.updateAkunLive,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_AKUN_LIVE_FAIL:
      return {
        ...state,
        updateAkunLive: {
          ...state.updateAkunLive,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_AKUN_LIVE:
      return {
        ...state,
        addAkunLive: {
          ...state.addAkunLive,
          loading: true,
          error: false,
        },
      };
    case ADD_AKUN_LIVE_SUCCESS:
      return {
        ...state,
        addAkunLive: {
          ...state.addAkunLive,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_AKUN_LIVE_FAIL:
      return {
        ...state,
        addAkunLive: {
          ...state.addAkunLive,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
