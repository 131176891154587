import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { Gap, MyButton, MyInput, MyAutocomplete } from "../../atoms";
import MyDialog from "../../molecules/Dialog";
import { PRIMARY } from "../../../utils/constansColors";
import AlertMessage from "../../../components/molecules/AlertMessage";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getAllPembayaran, getPembayaran, updatePembayaran } from "../../../config/Redux/MetodePembayaran/actions";

const validationSchemaEdit = yup.object().shape({
  nama_metode_pembayaran: yup.string("Masukan Nama Metode Pembayaran").required("Silahkan Masukan Nama Metode Pembayaran"),
  kategori_metode_pembayaran: yup.string("Masukan Nama Kategori Metode Pembayaran").nullable(),
});

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const columnData = [
  { no: 0, name: "No" },
  { no: 1, name: "Nama Metode Pembayaran" },
  { no: 2, name: "Kategori Metode Pembayaran" },
  { no: 6, name: "Action" },
];

const BasicTable = ({ currentPage, search, sortBy, sortType, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  // const [currentPage, setCurrentPage] = React.useState(1);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
  const [idUser, setIdUser] = React.useState(null);

  // alertMessage
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  // selector
  const dataPembayaran = useSelector((state) => state.metodepembayaran.dataPembayaran);
  const dataDetailPembayaran = useSelector((state) => state.metodepembayaran.dataDetailPembayaran);

  // change password
  const handleOpenDialog = (id) => {
    setOpenDialog(true);
    setIdUser(id);
    dispatch(getPembayaran(id));
    formikProfile.resetForm();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // edit profile
  const handleOpenDialogProfile = (item) => {
    const { id_metode_pembayaran } = item;
    setOpenDialogProfile(true);
    setIdUser(id_metode_pembayaran);
    dispatch(getPembayaran(id_metode_pembayaran));
    formikProfile.resetForm();
  };

  const handleCloseDialogProfile = () => {
    setOpenDialogProfile(false);
  };

  let dataDetail = dataDetailPembayaran?.data?.data;

  // formik edit data profile
  const formikProfile = useFormik({
    initialValues: {
      nama_metode_pembayaran: dataDetail?.nama_metode_pembayaran,
      kategori_metode_pembayaran: dataDetail?.kategori_metode_pembayaran,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaEdit,
    onSubmit: async (values) => {
      let data = values;
      let payload = null;
      // if(data.kategori_metode_pembayaran !== dataDetail?.kategori_metode_pembayaran){
        payload = {
          kategori_metode_pembayaran: data.kategori_metode_pembayaran,
          nama_metode_pembayaran: data.nama_metode_pembayaran,
        };
      // } else {
      //   payload = {
      //     nama_metode_pembayaran: data.nama_metode_pembayaran,
      //   };
      // }
      // console.log(payload)
      let resp = await dispatch(updatePembayaran(idUser, payload));
      if (resp.type === "PUT_METODE_PEMBAYARAN_SUCCESS") {
        handleCloseDialogProfile();
        setMessage(resp.payload?.message);
        setOpenDialogAlert(true);
        dispatch(getAllPembayaran(currentPage, search, sortBy, sortType))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
    },
  });

  // form Ubah data
  const renderFormProfile = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Data</b>
        </h2>
      </div>
      <MyInput
        id="nama_metode_pembayaran"
        name="nama_metode_pembayaran"
        label="Nama Metode Pembayaran*"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_metode_pembayaran || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_metode_pembayaran && Boolean(formikProfile.errors.nama_metode_pembayaran)}
        helperText={formikProfile.touched.nama_metode_pembayaran && formikProfile.errors.nama_metode_pembayaran}
      />
      <Gap height={20} />
      <MyInput
        id="kategori_metode_pembayaran"
        name="kategori_metode_pembayaran"
        label="Kategori Pembayaran"
        fullWidth
        size="medium"
        value={formikProfile.values.kategori_metode_pembayaran || ""}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.kategori_metode_pembayaran && Boolean(formikProfile.errors.kategori_metode_pembayaran)}
        helperText={formikProfile.touched.kategori_metode_pembayaran && formikProfile.errors.kategori_metode_pembayaran}
      />
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogProfile}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  // form Ubah data
  const renderFormDetail = (
    <>
      <div align="center">
        <h2>
          <b>Informasi Detail</b>
        </h2>
      </div>
      <div style={{margin:"4px 0px"}}>
        <span><b>Nama Metode Pembayaran</b></span><br/>
        <span>{dataDetail?.nama_metode_pembayaran || "-"}</span>
      </div>
      <div style={{margin:"8px 0px"}}>
        <span><b>Kategori Pembayaran</b></span><br/>
        <span>{dataDetail?.kategori_metode_pembayaran || "-"}</span>
      </div>
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Oke"
          onClick={handleCloseDialog}
        />
      </div>
    </>
  );

  useEffect(() => {
    
  }, [dispatch]);

  return (
    <>
      {/* change password */}
      <MyDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        renderValue={renderFormDetail}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* edit user */}
      <MyDialog
        openDialog={openDialogProfile}
        handleCloseDialog={handleCloseDialogProfile}
        renderValue={renderFormProfile}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />

      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className={classes.tableHeader}
        >
          <TableHead>
            <TableRow>
              {columnData.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 700,
                      color: "#757575",
                      width:
                        item.name === "No"
                          ? "3%"
                          : item.name === "Action"
                          ? ""
                          : "30%",
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        {dataPembayaran?.data?.data?.map((item, index) => {
          let number = index + 1 + 10 * (dataPembayaran?.data?.current_page - 1);
          return (
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className={classes.tableBody}
              key={index}
            >
              <TableBody>
                <TableRow key={item.id}>
                  <TableCell width="3%">{number}</TableCell>
                  <TableCell width="30%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.nama_metode_pembayaran || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="30%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item.kategori_metode_pembayaran || "-"}
                    </div>
                  </TableCell>
                  
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <MyButton
                          startIcon={<RemoveRedEyeIcon />}
                          style={{ marginTop: 5 }}
                          color="#F06524"
                          colorHover="#F06524"
                          // height={"100%"}
                          size="small"
                          backgroundColor={"#FFFFFF"}
                          borderColor={"#F06524"}
                          backgroundColorHover={"#FFFFFF"}
                          variant="outlined"
                          children="Lihat Detail"
                          onClick={() => handleOpenDialog(item.id_metode_pembayaran)}
                        />
                        <Gap width={10} />
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Ubah Data"
                          onClick={() => handleOpenDialogProfile(item)}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
      {/* <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataUsers?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={20} /> */}
    </>
  );
}

export default BasicTable;