import React, { useEffect } from "react";
import { Gap, MyButton, MyInput } from "../../atoms";

import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import {
  getAllEvent,
  getAllEventLast
} from "../../../config/Redux/Event/actions";
import { useDispatch, useSelector } from "react-redux";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const UserManagement = () => {
  const dispatch = useDispatch();

  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = today.getFullYear()+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2)
    return date
  }

  const [searchDate, setSearchDate] = React.useState(convertDate(new Date()));
  // const dataEventLive = useSelector((state) => state.eventlive.dataAllEventLast);

  useEffect(() => {
    dispatch(getAllEventLast(searchDate));
  }, [dispatch]);

  const handleFilterDate = (event) =>{
    setSearchDate(event.target.value)
    dispatch(getAllEventLast(event.target.value));
  }

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Gap height={20} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: 28, fontWeight:700 }}>Rekap Admin <span style={{fontSize:'14px'}}>(7 hari terakhir)</span></p>
        <div style={{ display: "flex", marginTop: 25 }}>
          {/* <Filters /> */}
          <Gap width={10} />
          <MyInput
            size="small"
            id="search"
            name="search"
            type="date"
            placeholder="Cari"
            value={searchDate}
            onChange={handleFilterDate}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
          />
        </div>
      </div>

      {/* Tableee */}
      <Table 
        searchDate={searchDate} 
      />
      <Gap height={20} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
