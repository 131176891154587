export const GET_METODE_PEMBAYARAN = "GET_METODE_PEMBAYARAN";
export const GET_METODE_PEMBAYARAN_SUCCESS = "GET_METODE_PEMBAYARAN_SUCCESS";
export const GET_METODE_PEMBAYARAN_FAIL = "GET_METODE_PEMBAYARAN_FAIL";

// get detail user
export const GET_DETAIL_METODE_PEMBAYARAN = "GET_DETAIL_METODE_PEMBAYARAN";
export const GET_DETAIL_METODE_PEMBAYARAN_SUCCESS = "GET_DETAIL_METODE_PEMBAYARAN_SUCCESS";
export const GET_DETAIL_METODE_PEMBAYARAN_FAIL = "GET_DETAIL_METODE_PEMBAYARAN_FAIL";

// put detail user
export const PUT_METODE_PEMBAYARAN = "PUT_METODE_PEMBAYARAN";
export const PUT_METODE_PEMBAYARAN_SUCCESS = "PUT_METODE_PEMBAYARAN_SUCCESS";
export const PUT_METODE_PEMBAYARAN_FAIL = "PUT_METODE_PEMBAYARAN_FAIL";

// add new user
export const ADD_METODE_PEMBAYARAN = "ADD_METODE_PEMBAYARAN";
export const ADD_METODE_PEMBAYARAN_SUCCESS = "ADD_METODE_PEMBAYARAN_SUCCESS";
export const ADD_METODE_PEMBAYARAN_FAIL = "ADD_METODE_PEMBAYARAN_FAIL";