import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { MyButton } from "../../atoms";
import { PRIMARY } from "../../../utils/constansColors";
import { useSelector } from "react-redux";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from "react-router-dom";


const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const columnData = [
  { no: 0, name: "No" },
  { no: 1, name: "Nama Akun" },
  { no: 2, name: "Waktu Live" },
  { no: 3, name: "Tanggal Event" },
  { no: 4, name: "Jumlah Pesanan" },
  { no: 6, name: "Action" },
];

const BasicTable = ({ searchDate, ...rest }) => {
  const classes = useStyles();
  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()
    return date
  }

  // selector
  const dataEventLive = useSelector((state) => state.eventlive.dataAllEvent);
  return (
    <>

      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className={classes.tableHeader}
        >
          <TableHead>
            <TableRow>
              {columnData.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 700,
                      color: "#757575",
                      width:
                        item.name === "No"
                          ? "3%"
                          : item.name === "Action"
                          ? ""
                          : "16%",
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        {dataEventLive?.data?.data?.map((item, index) => {
          let number = index + 1 + 10 * (dataEventLive?.data?.current_page - 1);
          return (
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className={classes.tableBody}
              key={index}
            >
              <TableBody>
                <TableRow key={item.id}>
                  <TableCell width="3%">{number}</TableCell>
                  <TableCell width="16%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item?.akun_live?.nama || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="16%">
                    <div style={{ wordWrap: "break-word" }}>
                      {item?.waktu_live?.nama || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="16%">
                    <div style={{ wordWrap: "break-word" }}>
                      {convertDate(item.tanggal_live_event) || "-"}
                    </div>
                  </TableCell>
                  <TableCell width="16%">
                    {item?.jumlah_pesanan}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                      <Link to={"/rekap-live/pesanan/"+item?.id_live_event+"/"+item?.akun_live?.id+"/"+item?.waktu_live?.id+"/"+item.tanggal_live_event}>
                        <MyButton
                          startIcon={<RemoveRedEyeIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Lihat Rekap"
                        />
                        </Link>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
    </>
  );
}

export default BasicTable;