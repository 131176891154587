import {
  GET_METODE_PEMBAYARAN,
  GET_METODE_PEMBAYARAN_SUCCESS,
  GET_METODE_PEMBAYARAN_FAIL,
  GET_DETAIL_METODE_PEMBAYARAN,
  GET_DETAIL_METODE_PEMBAYARAN_SUCCESS,
  GET_DETAIL_METODE_PEMBAYARAN_FAIL,
  PUT_METODE_PEMBAYARAN,
  PUT_METODE_PEMBAYARAN_SUCCESS,
  PUT_METODE_PEMBAYARAN_FAIL,
  ADD_METODE_PEMBAYARAN,
  ADD_METODE_PEMBAYARAN_SUCCESS,
  ADD_METODE_PEMBAYARAN_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllPembayaran =
  (page, search, sortBy, sortType, filterArea) => (dispatch) => {
    dispatch({
      type: GET_METODE_PEMBAYARAN,
    });

    const request = API.getAllPembayaran(page, search, sortBy, sortType, filterArea);
    return request.then(
      (response) => dispatch(customer(response.data,GET_METODE_PEMBAYARAN_SUCCESS)),
      (err) => dispatch(customer(err,GET_METODE_PEMBAYARAN_FAIL))
    );
  };


export const getPembayaran = (param) => (dispatch) => {
  dispatch({
    type: GET_DETAIL_METODE_PEMBAYARAN,
  });

  const request = API.getPembayaran(param);
  return request.then(
    (response) => dispatch(customer(response.data,GET_DETAIL_METODE_PEMBAYARAN_SUCCESS)),
    (err) => dispatch(customer(err,GET_DETAIL_METODE_PEMBAYARAN_FAIL))
  );
};

// update user
export const updatePembayaran = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_METODE_PEMBAYARAN,
  });

  const request = API.updatePembayaran(id, data);
  return request.then(
    (response) => dispatch(customer(response.data,PUT_METODE_PEMBAYARAN_SUCCESS)),
    (err) => dispatch(customer(err,PUT_METODE_PEMBAYARAN_FAIL))
  );
};

// add new user
export const createPembayaran = (data) => (dispatch) => {
  dispatch({
    type: ADD_METODE_PEMBAYARAN,
  });

  const request = API.createPembayaran(data);
  return request.then(
    (response) => dispatch(customer(response.data,ADD_METODE_PEMBAYARAN_SUCCESS)),
    (err) => dispatch(customer(err,ADD_METODE_PEMBAYARAN_FAIL))
  );
};

export const customer = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
