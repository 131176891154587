import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL } from "./actionTypes";

const initialState = {
  login: {
    loading: false,
    error: false,
    data: null,
    isLoggedIn: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          error: false,
          isLoggedIn: false,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: false,
          isLoggedIn: true,
          data: action.payload,
        },
      };
    case LOGIN_FAIL:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: action.payload,
          isLoggedIn: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
