import React, { useEffect } from "react";
import { Gap, MyInput, MyButton, MyAutocomplete } from "../../atoms";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import MyDialog from "../../molecules/Dialog";
import { useFormik } from "formik";
import AlertMessage from "../../../components/molecules/AlertMessage";
import {
  getAllCustomer,
} from "../../../config/Redux/Customer/actions";
import {
  getAllEventPesanan,
  createEventPesanan,
} from "../../../config/Redux/EventPesanan/actions";
import {
  getEvent,
  updateEvent,
  assignReviewer
} from "../../../config/Redux/Event/actions";
import {
  getAkunLiveProfile,
  getWaktuLive,
} from "../../../config/Redux/Global/actions";
import {
  getAllReviewer,
} from "../../../config/Redux/Reviewer/actions";
import { useDispatch, useSelector } from "react-redux";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const validationSchemaCreate = yup.object().shape({
  customer: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Masukan Customer"),
});

const validationSchemaUpdateInfo = yup.object().shape({
  tanggal_live_event: yup.string("Masukkan Tanggal Event Live").required("Silahkan Masukkan Tanggal Event Live"),
  waktu_mulai: yup.string("Masukkan Waktu Mulai Live").required("Silahkan Masukkan Waktu Mulai Live"),
  durasi_live: yup.string("Masukkan Durasi Live").required("Silahkan Masukkan Durasi Live"),
  id_waktu_live: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Masukan Waktu Live"),
  id_akun_live: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Masukan Akun Live"),
});

const validationSchemaUpdateReviewer = yup.object().shape({
  reviewer: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Tambahkan Reviewer"),
});

const UserManagement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let params = useParams();
  const [openDialogUser, setOpenDialogUser] = React.useState(false);
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [openDialogInfoEvent, setOpenDialogInfoEvent] = React.useState(false);
  const [openDialogReviewer, setOpenDialogReviewer] = React.useState(false);
  const [openDialogReviewerDelete, setOpenDialogReviewerDelete] = React.useState(false);
  const [reviewerID, setReviewerID] = React.useState("");
  const [reviewerName, setReviewerName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [search, setSearch] = React.useState("");

  const [currentPage, setCurrentPage] = React.useState(1);
  const handlePagination = (event, value) => {
    setCurrentPage(value);
    dispatch(getAllEventPesanan(value, search, "", "desc", 300, params.eventID));
    // dispatch(getAllCustomer(value, search, sortBy, sortType));
  };
  const dataAllEventPesanan = useSelector((state) => state.eventlivepesanan.dataAllEventPesanan);

  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()
    return date
  }
  const convertDateReverse = (tanggal) => {
    let today = new Date(tanggal);
    let date = today.getFullYear()+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2)
    return date
  }

  const handleOpenDialogUpdateEvent = () => {
    setOpenDialogInfoEvent(true);
  };
  const handleCloseDialogUpdateEvent = () => {
    setOpenDialogInfoEvent(false);
    formikUpdateEvent.resetForm();
  };
  const handleOpenDialogReviewer = () => {
    setOpenDialogReviewer(true);
  };
  const handleCloseDialogReviewer = () => {
    setOpenDialogReviewer(false);
    formikReviewer.resetForm();
  };

  const handleOpenDialogReviewerDelete = (name,id) => {
    setOpenDialogReviewerDelete(true);
    setReviewerID(id)
    setReviewerName(name)
  };
  const handleCloseDialogReviewerDelete = () => {
    setOpenDialogReviewerDelete(false);
  };

  // selector
  let dataCustomer = useSelector((state) => state.customer.dataCustomer);
  let dataDetailEvent = useSelector((state) => state.eventlive.dataDetailEvent);
  // options
  let optionsCat = dataCustomer?.data?.data?.map((item) => ({
    label: item.nama_akun+" ("+item.nama_customer+")",
    id: item.id_customer,
  }))||[];

  // formik add user
  const formikProfile = useFormik({
    initialValues: {
      customer: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchemaCreate,
    onSubmit: async (values) => {
      let payload = {
        id_customer: values.customer.id,
        id_akun_live: params.akunID,
        id_waktu_live: params.waktuID,
        tanggal_live_event: params.tanggal,
      };
      let resp = await dispatch(createEventPesanan(payload));
      if (resp.type === "ADD_EVENT_PESANAN_SUCCESS") {
        handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getAllEventPesanan(currentPage, search, "", "desc", 300, params.eventID));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });

  // new user
  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
    formikProfile.resetForm();
  };
  const handleCloseDialogUser = () => {
    setOpenDialogUser(false);
    formikProfile.resetForm();
  };
  const handleSelectSearch = (event) => {
    dispatch(getAllCustomer(1, event.target.value, "nama_customer", 'asc'));
  }
  const renderFormNewData = (
    <>
      <div align="center">
        <h2>
          <b>Tambah Pesanan</b>
        </h2>
      </div>
      <MyAutocomplete
        options={optionsCat}
        fullWidth
        size="medium"
        label="Customer"
        id="customer"
        name="customer"
        value={formikProfile.values.customer || null}
        onKeyUp={(e) => {
          handleSelectSearch(e);
        }}
        onChange={(e, value) => {
          formikProfile.setFieldValue("customer", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.customer && formikProfile.errors.customer}
      </FormHelperText>
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUser}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );


  // selector
  let dataWaktuLive = useSelector((state) => state.global.dataWaktuLive);
  // options
  let optionsWaktu = dataWaktuLive?.data?.data?.map((item) => ({
    label: item.nama_waktu_live,
    id: item.id_waktu_live,
  }))||[];
  // update event
  const formikUpdateEvent = useFormik({
    initialValues: {
      tanggal_live_event: convertDateReverse(dataDetailEvent?.data?.data?.tanggal_live_event),
      id_akun_live: dataDetailEvent?.data?.data?.akun_live?.id
      ? {
          id: dataDetailEvent?.data?.data?.akun_live?.id,
          label: dataDetailEvent?.data?.data?.akun_live?.nama,
        }
      : null,
      id_waktu_live: dataDetailEvent?.data?.data?.waktu_live?.id
      ? {
          id: dataDetailEvent?.data?.data?.waktu_live?.id,
          label: dataDetailEvent?.data?.data?.waktu_live?.nama,
        } 
      : null,
      waktu_mulai:dataDetailEvent?.data?.data?.waktu_mulai,
      durasi_live:dataDetailEvent?.data?.data?.durasi_live_jam
    },
    enableReinitialize: true,
    validationSchema: validationSchemaUpdateInfo,
    onSubmit: async (values) => {
      let payload = {
        id_live_event:params.eventID,
        tanggal_live_event: values.tanggal_live_event,
        id_waktu_live: values.id_waktu_live.id,
        id_akun_live: values.id_akun_live.id,
        durasi_live_jam:values.durasi_live,
        waktu_mulai:values.waktu_mulai,
      };
      let resp = await dispatch(updateEvent(payload));
      if (resp.type === "PUT_EVENT_SUCCESS") {
        handleCloseDialogUpdateEvent();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getEvent(params.eventID));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });
  const renderFormUpdateEvent = (
    <>
      <div align="center">
        <h2>
          <b>Lengkapi Informasi Event Live</b>
        </h2>
      </div>
      <MyInput
        id="waktu_mulai"
        name="waktu_mulai"
        label="Waktu Mulai Live"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.waktu_mulai}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.waktu_mulai && Boolean(formikUpdateEvent.errors.waktu_mulai)}
        helperText={formikUpdateEvent.touched.waktu_mulai && formikUpdateEvent.errors.waktu_mulai}
      />
      <Gap height={20} />
      <MyInput
        id="durasi_live"
        name="durasi_live"
        label="Durasi Live (jam)"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.durasi_live}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.durasi_live && Boolean(formikUpdateEvent.errors.durasi_live)}
        helperText={formikUpdateEvent.touched.durasi_live && formikUpdateEvent.errors.durasi_live}
      />
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUpdateEvent}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikUpdateEvent.handleSubmit}
        />
      </div>
    </>
  );
  
  const handleSelectSearchReviewer = (event) => {
    dispatch(getAllReviewer(1, event.target.value));
  }
  // selector
  let dataReviewer = useSelector((state) => state.reviewer.dataReviewer);
  // options
  let optionsReviewer = dataReviewer?.data?.data?.map((item) => ({
    label: item.nama_reviewer,
    id: item.id_reviewer,
  }))||[];
  //update reveiwer
  const formikReviewer = useFormik({
    initialValues: {
      reviewer: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchemaUpdateReviewer,
    onSubmit: async (values) => {
      let payload = {
        id_live_event:params.eventID,
        id_reviewer: values.reviewer.id,
      };
      let resp = await dispatch(assignReviewer(payload));
      if (resp.type === "ASSIGN_REVIEWER_EVENT_SUCCESS") {
        // handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getEvent(params.eventID));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });
  const renderFormReviewer = (
    <>
      <div align="center">
        <h2>
          <b>Perbaharui Reviewer Event Live</b>
        </h2>
      </div>
      <MyAutocomplete
        options={optionsReviewer}
        fullWidth
        size="medium"
        label="Tambahkan Reviewer"
        id="reviewer"
        name="reviewer"
        value={formikReviewer.values.reviewer || null}
        onKeyUp={(e) => {
          handleSelectSearchReviewer(e);
        }}
        onChange={(e, value) => {
          formikReviewer.setFieldValue("reviewer", value);
        }}
        
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikReviewer.touched.reviewer && formikReviewer.errors.reviewer}
      </FormHelperText>
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Tambahkan"
          type="submit"
          onClick={formikReviewer.handleSubmit}
        />
      </div>
      <Gap height={20} />
      <div>
        <p>Daftar Reveiwer</p>
        <div>
          <table>
            <tbody>
              {dataDetailEvent?.data?.data?.reviewer.map((list,idx)=>{
                return (
                  <tr key={idx}>
                    <td>{idx+1}.</td>
                    <td>{list.nama}</td>
                    <td><DeleteIcon onClick={()=>handleOpenDialogReviewerDelete(list.nama,list.id)} style={{fontSize:"20px",cursor:'pointer', marginLeft:'20px'}}/></td>
                  </tr>
                )
              })}
            </tbody>
          </table> 
        </div>
      </div>
      <Gap height={40} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogReviewer}
        />
      </div>
    </>
  );

  const handleDeleteReviewer = async() =>{
    let payload = {
      id_live_event:params.eventID,
      id_reviewer: reviewerID,
    };
    let resp = await dispatch(assignReviewer(payload));
    if (resp.type === "ASSIGN_REVIEWER_EVENT_SUCCESS") {
      handleCloseDialogReviewerDelete();
      setOpenDialogAlert(true);
      setMessage(resp.payload.message);
      setStatus("");
      dispatch(getEvent(params.eventID));
    } else {
      setOpenDialogAlert(true);
      setMessage(resp.payload.data.message);
      setStatus("error");
    }
  }

  const renderFormReviewerDelete = (
    <>
      <div align="center">
        <h2>
          <b>Apakah Anda Yakin<br/> Akan Menghapus Reviewer Ini?<br/><br/><span style={{color:"gray"}}>{reviewerName}</span></b>
        </h2>
      </div>
      
      <Gap height={40} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Tidak"
          onClick={handleCloseDialogReviewerDelete}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="white"
          colorHover="white"
          fullWidth
          backgroundColor="red"
          borderColor="red"
          backgroundColorHover="red"
          variant="outlined"
          children="Hapus"
          type="submit"
          onClick={handleDeleteReviewer}
        />
      </div>
    </>
  );

  useEffect(() => {
    dispatch(getAllCustomer(1));
    dispatch(getEvent(params.eventID));
    dispatch(getAkunLiveProfile());
    dispatch(getWaktuLive());
    dispatch(getAllReviewer(1));
    dispatch(getAllEventPesanan(1, "", "", "desc", 300, params.eventID));
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearch(event.target.value)
    let temp = event.target.value
    var timeout;
    var delay = 500;
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      if(temp === event.target.value){
        dispatch(getAllEventPesanan(currentPage, event.target.value, "", "desc", 300, params.eventID));
      }
    }, delay);
  };

  const breadcrumbs = [
    <Link
      to={"/"}
      style={{ textDecoration: "none", fontWeight: "bold", color: "black", fontSize:'14px' }}
    >
      Rekap Live
    </Link>,
    <Typography key="2" color="text.primary" style={{ color: "#BDBDBD", fontSize:'14px', marginTop:'2px' }}>
      Rekap Pesanan
    </Typography>
  ];

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      {/* add new user */}
      <MyDialog
        openDialog={openDialogUser}
        handleCloseDialog={handleCloseDialogUser}
        renderValue={renderFormNewData}
        fullWidth={true}
        maxWidth={"sm"}
      />
      <MyDialog
        openDialog={openDialogInfoEvent}
        handleCloseDialog={handleCloseDialogUpdateEvent}
        renderValue={renderFormUpdateEvent}
        fullWidth={true}
        maxWidth={"sm"}
      />
      <MyDialog
        openDialog={openDialogReviewer}
        handleCloseDialog={handleCloseDialogReviewer}
        renderValue={renderFormReviewer}
        fullWidth={true}
        maxWidth={"sm"}
      />
      <MyDialog
        openDialog={openDialogReviewerDelete}
        handleCloseDialog={handleCloseDialogReviewerDelete}
        renderValue={renderFormReviewerDelete}
        fullWidth={true}
        maxWidth={"sm"}
      />

      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={40} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <p style={{ fontSize: 28, fontWeight:700,marginTop:0 }}>Rekap Pesanan</p>
        </div>
      </div>

      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <p style={{marginTop:0, fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
              Informasi Event Live <EditIcon onClick={handleOpenDialogUpdateEvent} style={{fontSize:"18px",cursor:'pointer'}}/>  
            </p>
            <table>
              <tbody>
                <tr>
                  <td>Akun Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.akun_live?.nama || "-"}</b></td>
                </tr>
                <tr>
                  <td>Tanggal Live</td>
                  <td>: <b>{convertDate(dataDetailEvent?.data?.data?.tanggal_live_event) || "-"}</b></td>
                </tr>
                <tr>
                  <td>Waktu Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.waktu_live?.nama || "-"}</b></td>
                </tr>
                <tr>
                  <td>Waktu Mulai Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.waktu_mulai || "-"}</b></td>
                </tr>
                <tr>
                  <td>Durasi Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.durasi_live_jam || "-"} jam</b></td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <p style={{marginTop:0, fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
              Informasi Reviewer Live <EditIcon onClick={handleOpenDialogReviewer} style={{fontSize:"18px",cursor:'pointer'}}/>
            </p>
            Reviewer<br/>
              {dataDetailEvent?.data?.data?.reviewer.map((list,idx)=>{
                return (
                  <div key={idx}><b>{idx+1}. {list.nama}</b></div>
                )
              })}
          </Grid>
        </Grid>
      </div>
          
      {/* Tableee */}
      <Gap height={30} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <p style={{ fontSize: 20, fontWeight:700, margin:0,lineHeight:"40px"  }}>Daftar Pesanan</p>
        </div>
        <div style={{ display: "flex" }}>
          {/* <Filters /> */}
          <Gap width={10} />

          <MyInput
            size="small"
            id="search"
            name="search"
            // label="Cari"
            placeholder="Cari"
            onChange={handleSearch}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Gap width={10} />
          <MyButton
            // style={{ marginTop: 25 }}
            startIcon={<AddIcon />}
            height="40px"
            color="white"
            backgroundColor="#F06524"
            borderColor="#F06524"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Tambah Pesanan"
            onClick={handleOpenDialogUser}
          />
        </div>
      </div>
      <Table 
        search={search}
      />
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataAllEventPesanan?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={60} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
