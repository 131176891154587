import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { MyInput } from "..";
const MyAutocomplete = ({ label, options, onChange, ...rest }) => {
  // const options = [
  //   { label: "The Godfather", id: 1 },
  //   { label: "Pulp Fiction", id: 2 },
  // ];
  return (
    <>
      <Autocomplete
        {...rest}
        disablePortal
        id="combo-box-demo"
        onChange={onChange}
        options={options}
        // sx={{ width: 300 }}
        renderInput={(params) => <MyInput {...params} label={label} />}
      />
    </>
  );
};

export default MyAutocomplete;
