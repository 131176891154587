import {
  GET_ALL_EVENT_PESANAN,
  GET_ALL_EVENT_PESANAN_SUCCESS,
  GET_ALL_EVENT_PESANAN_FAIL,
  GET_EVENT_PESANAN_DETAIL,
  GET_EVENT_PESANAN_DETAIL_SUCCESS,
  GET_EVENT_PESANAN_DETAIL_FAIL,
  PUT_EVENT_PESANAN,
  PUT_EVENT_PESANAN_SUCCESS,
  PUT_EVENT_PESANAN_FAIL,
  ADD_EVENT_PESANAN,
  ADD_EVENT_PESANAN_SUCCESS,
  ADD_EVENT_PESANAN_FAIL,
  DELETE_EVENT_PESANAN,
  DELETE_EVENT_PESANAN_SUCCESS,
  DELETE_EVENT_PESANAN_FAIL,
} from "./actionTypes";

const initialState = {
  dataAllEventPesanan: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailEventPesanan: {
    loading: false,
    error: false,
    data: null,
  },
  updateEventPesanan: {
    loading: false,
    error: false,
    data: null,
  },
  addEventPesanan: {
    loading: false,
    error: false,
    data: null,
  },
  deleteEventPesanan: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_ALL_EVENT_PESANAN:
      return {
        ...state,
        dataAllEventPesanan: {
          ...state.dataAllEventPesanan,
          loading: true,
          error: false,
        },
      };
    case GET_ALL_EVENT_PESANAN_SUCCESS:
      return {
        ...state,
        dataAllEventPesanan: {
          ...state.dataAllEventPesanan,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_ALL_EVENT_PESANAN_FAIL:
      return {
        ...state,
        dataAllEventPesanan: {
          ...state.dataAllEventPesanan,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_EVENT_PESANAN_DETAIL:
      return {
        ...state,
        dataDetailEventPesanan: {
          ...state.dataDetailEventPesanan,
          loading: true,
          error: false,
        },
      };
    case GET_EVENT_PESANAN_DETAIL_SUCCESS:
      return {
        ...state,
        dataDetailEventPesanan: {
          ...state.dataDetailEventPesanan,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_EVENT_PESANAN_DETAIL_FAIL:
      return {
        ...state,
        dataDetailEventPesanan: {
          ...state.dataDetailEventPesanan,
          loading: false,
          error: action.payload,
        },
      };
      
    // update user
    case PUT_EVENT_PESANAN:
      return {
        ...state,
        updateEventPesanan: {
          ...state.updateEventPesanan,
          loading: true,
          error: false,
        },
      };
    case PUT_EVENT_PESANAN_SUCCESS:
      return {
        ...state,
        updateEventPesanan: {
          ...state.updateEventPesanan,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_EVENT_PESANAN_FAIL:
      return {
        ...state,
        updateEventPesanan: {
          ...state.updateEventPesanan,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_EVENT_PESANAN:
      return {
        ...state,
        addEventPesanan: {
          ...state.addEventPesanan,
          loading: true,
          error: false,
        },
      };
    case ADD_EVENT_PESANAN_SUCCESS:
      return {
        ...state,
        addEventPesanan: {
          ...state.addEventPesanan,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_EVENT_PESANAN_FAIL:
      return {
        ...state,
        addEventPesanan: {
          ...state.addEventPesanan,
          loading: false,
          error: action.payload,
        },
      };
      case DELETE_EVENT_PESANAN:
        return {
          ...state,
          deleteEventPesanan: {
            ...state.deleteEventPesanan,
            loading: true,
            error: false,
          },
        };
      case DELETE_EVENT_PESANAN_SUCCESS:
        return {
          ...state,
          deleteEventPesanan: {
            ...state.deleteEventPesanan,
            loading: false,
            error: false,
            data: action.payload,
          },
        };
      case DELETE_EVENT_PESANAN_FAIL:
        return {
          ...state,
          deleteEventPesanan: {
            ...state.deleteEventPesanan,
            loading: false,
            error: action.payload,
          },
        };
    default:
      return state;
  }
};

export default reducer;
