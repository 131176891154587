import React from "react";
import MetodePengiriman from "../../components/organism/MetodePengiriman";

const PageMetodePengiriman = () => {
  return (
    <>
      <MetodePengiriman />
    </>
  );
};

export default PageMetodePengiriman;
