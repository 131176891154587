export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";

// get detail user
export const GET_DETAIL_CUSTOMER = "GET_DETAIL_CUSTOMER";
export const GET_DETAIL_CUSTOMER_SUCCESS = "GET_DETAIL_CUSTOMER_SUCCESS";
export const GET_DETAIL_CUSTOMER_FAIL = "GET_DETAIL_CUSTOMER_FAIL";

// put detail user
export const PUT_CUSTOMER = "PUT_CUSTOMER";
export const PUT_CUSTOMER_SUCCESS = "PUT_CUSTOMER_SUCCESS";
export const PUT_CUSTOMER_FAIL = "PUT_CUSTOMER_FAIL";

// add new user
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";