export const GET_ALL_EVENT = "GET_ALL_EVENT";
export const GET_ALL_EVENT_SUCCESS = "GET_ALL_EVENT_SUCCESS";
export const GET_ALL_EVENT_FAIL = "GET_ALL_EVENT_FAIL";

export const GET_EVENT_DETAIL = "GET_EVENT_DETAIL";
export const GET_EVENT_DETAIL_SUCCESS = "GET_EVENT_DETAIL_SUCCESS";
export const GET_EVENT_DETAIL_FAIL = "GET_EVENT_DETAIL_FAIL";

export const GET_ALL_EVENT_LAST = "GET_ALL_EVENT_LAST";
export const GET_ALL_EVENT_LAST_SUCCESS = "GET_ALL_EVENT_LAST_SUCCESS";
export const GET_ALL_EVENT_LAST_FAIL = "GET_ALL_EVENT_LAST_FAIL";

// put detail event
export const PUT_EVENT = "PUT_EVENT";
export const PUT_EVENT_SUCCESS = "PUT_EVENT_SUCCESS";
export const PUT_EVENT_FAIL = "PUT_EVENT_FAIL";

// add new event
export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

// DELETE new event
export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const ASSIGN_REVIEWER_EVENT = "ASSIGN_REVIEWER_EVENT";
export const ASSIGN_REVIEWER_EVENT_SUCCESS = "ASSIGN_REVIEWER_EVENT_SUCCESS";
export const ASSIGN_REVIEWER_EVENT_FAIL = "ASSIGN_REVIEWER_EVENT_FAIL";