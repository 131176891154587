import React, { useEffect } from "react";
import { Gap, MyInput, MyButton, MyAutocomplete } from "../../atoms";

import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import MyDialog from "../../molecules/Dialog";
import { useFormik } from "formik";
import AlertMessage from "../../../components/molecules/AlertMessage";
import {
  getAllEvent,
  createEvent,
} from "../../../config/Redux/Event/actions";
import {
  getAkunLiveProfile,
  getWaktuLive,
} from "../../../config/Redux/Global/actions";
import { useDispatch, useSelector } from "react-redux";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const validationSchemaCreate = yup.object().shape({
  tanggal_live_event: yup.string("Masukkan Tanggal Event Live").required("Silahkan Masukkan Tanggal Event Live"),
  id_waktu_live: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Masukan Waktu Live"),
  id_akun_live: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Masukan Akun Live"),
});

const UserManagement = () => {
  const dispatch = useDispatch();
  const [openDialogUser, setOpenDialogUser] = React.useState(false);

  // alert
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = today.getFullYear()+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2)
    return date
  }

  const [searchDate, setSearchDate] = React.useState(convertDate(new Date()));

  // selector
  let dataAkunLive = useSelector((state) => state.global.dataAkunLive);
  // options
  let optionsAkun = dataAkunLive?.data?.data?.map((item) => ({
    label: item.nama_akun_live,
    id: item.id_akun_live,
  }))||[];

  // selector
  let dataWaktuLive = useSelector((state) => state.global.dataWaktuLive);
  // options
  let optionsWaktu = dataWaktuLive?.data?.data?.map((item) => ({
    label: item.nama_waktu_live,
    id: item.id_waktu_live,
  }))||[];

  // formik add user
  const formikProfile = useFormik({
    initialValues: {
      tanggal_live_event: searchDate,
      id_akun_live: "",
      id_waktu_live: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchemaCreate,
    onSubmit: async (values) => {
      let payload = {
        tanggal_live_event: values.tanggal_live_event,
        id_waktu_live: values.id_waktu_live.id,
        id_akun_live: values.id_akun_live.id,
      };
      let resp = await dispatch(createEvent(payload));
      if (resp.type === "ADD_EVENT_SUCCESS") {
        handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getAllEvent(searchDate));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });

  // new user
  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
    formikProfile.resetForm();
  };

  const handleCloseDialogUser = () => {
    setOpenDialogUser(false);
    formikProfile.resetForm();
  };
  const renderFormNewData = (
    <>
      <div align="center">
        <h2>
          <b>Tambah Event Live</b>
        </h2>
      </div>
      <MyInput
        id="tanggal_live_event"
        name="tanggal_live_event"
        label="Tanggal Event Live*"
        type="date"
        fullWidth
        size="medium"
        value={formikProfile.values.tanggal_live_event}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.tanggal_live_event && Boolean(formikProfile.errors.tanggal_live_event)}
        helperText={formikProfile.touched.tanggal_live_event && formikProfile.errors.tanggal_live_event}
      />
      <Gap height={20} />
      <MyAutocomplete
        options={optionsAkun}
        fullWidth
        size="medium"
        label="Akun Live"
        id="id_akun_live"
        name="id_akun_live"
        value={formikProfile.values.id_akun_live || null}
        onChange={(e, value) => {
          formikProfile.setFieldValue("id_akun_live", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.id_akun_live && formikProfile.errors.id_akun_live}
      </FormHelperText>
      <Gap height={20} />
      <MyAutocomplete
        options={optionsWaktu}
        fullWidth
        size="medium"
        label="Waktu Live"
        id="id_waktu_live"
        name="id_waktu_live"
        value={formikProfile.values.id_waktu_live || null}
        onChange={(e, value) => {
          formikProfile.setFieldValue("id_waktu_live", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.id_waktu_live && formikProfile.errors.id_waktu_live}
      </FormHelperText>
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUser}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );


  useEffect(() => {
    dispatch(getAllEvent(searchDate));
    dispatch(getAkunLiveProfile());
    dispatch(getWaktuLive());
  }, [dispatch]);

  const handleFilterDate = (event) =>{
    setSearchDate(event.target.value)
    dispatch(getAllEvent(event.target.value));
  }

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      {/* add new user */}
      <MyDialog
        openDialog={openDialogUser}
        handleCloseDialog={handleCloseDialogUser}
        renderValue={renderFormNewData}
        fullWidth={true}
        maxWidth={"sm"}
      />

      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={20} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: 28, fontWeight:700 }}>Rekap Live</p>
        <div style={{ display: "flex", marginTop: 25 }}>
          {/* <Filters /> */}
          <Gap width={10} />
          <MyInput
            size="small"
            id="search"
            name="search"
            type="date"
            placeholder="Cari"
            value={searchDate}
            onChange={handleFilterDate}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
          />

          {/* <MyInput
            size="small"
            id="search"
            name="search"
            // label="Cari"
            placeholder="Cari"
            onChange={handleSearch}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Gap width={10} />
          <MyButton
            // style={{ marginTop: 25 }}
            startIcon={<AddIcon />}
            height="63%"
            color="white"
            backgroundColor="#F06524"
            borderColor="#F06524"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Tambah Live Event"
            onClick={handleOpenDialogUser}
          />
        </div>
      </div>

      {/* Tableee */}
      <Table 
        searchDate={searchDate} 
      />
      <Gap height={20} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
