import * as React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  root: {
    background: (props) => props.backgroundColor,
    textTransform: "none",
    color: (props) => props.color,
    height: (props) => props.height,
    // width: 200,
    borderColor: (props) => props.borderColor,
    "&:hover": {
      backgroundColor: (props) => props.backgroundColorHover,
      borderColor: (props) => props.borderColor,
      color: (props) => props.colorHover,
    },
  },
});

export default function Hook({
  color,
  colorHover,
  variant,
  backgroundColor,
  backgroundColorHover,
  children,
  height,
  borderColor,
  ...rest
}) {
  const props = {
    color: color,
    backgroundColor: backgroundColor,
    colorHover: colorHover,
    height: height ? height : "",
    backgroundColorHover: backgroundColorHover,
    borderColor: borderColor ? borderColor : "none",
  };

  const classes = useStyles(props);
  return (
    <Button {...rest} className={classes.root} variant={variant}>
      {children}
    </Button>
  );
}
