import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { LOCAL_STORAGE_KEY } from "../../../config/API/config";
let profile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

const BasicTable = ({ dataDetailCustomer,dataDetailEvent,dataCustomer, ...rest }) => {
  let params = useParams();
	const componentRef = useRef();
  const dataAllEventBarang = useSelector((state) => state.eventlivebarang.dataAllEventBarang);
	// console.log(dataCustomer)
	const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()
    return date
  }
	const convertDateTime = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()+', '+("0" + today.getHours()).slice(-2)+":"+("0" + today.getMinutes()).slice(-2)
    return date
  }
	let total = 0
	// console.log(dataCustomer)
	profile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  return (
    <>
			<div ref={componentRef}>
				<div style={{width:"80mm", margin:'auto', fontSize:'12px'}}>
					<div style={{textAlign:"center"}}>
						<p style={{fontSize:'18px', margin:'4px 0px', fontWeight:'bold'}}>Roemah Bajoe Group</p>
						<p style={{margin:'4px 0px'}}>Jl. Bayangkara No.96<br/> Kab. Klaten, Jawa Tengah</p>
					</div>
					<div>
						<hr style={{marginBottom:"2px"}}/>
						<div style={{textAlign:"center"}}>
							<span>Invoice</span>
						</div>
						<hr style={{marginTop:"2px"}}/>
						<table>
							<tbody>
								<tr>
									<td>ID Pesanan</td>
									<td>: {params?.pesananID || "-"}</td>
								</tr>
								<tr>
									<td>Tanggal</td>
									<td>: {convertDate(new Date()) || "-"}</td>
								</tr>
								<tr>
									<td>Admin/Kasir</td>
									<td>: {profile?.data?.nama || "-"}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<hr style={{marginBottom:"2px"}}/>
						<table style={{width:"100%"}}>
							<tbody>
								<tr>
									<td style={{textAlign:"left"}}>Barang</td>
									<td style={{textAlign:'right'}}>Harga</td>
								</tr>
							</tbody>
						</table>
						<hr style={{marginTop:"2px"}}/>
						<table style={{width:"100%"}}>
							<tbody>
								{dataAllEventBarang?.data?.data?.map((list,idx)=>{
									total = total + parseInt(list?.harga_barang)
									return(
										<tr key={idx}>
											<td>{list?.kode_barang}<br/><i>({list?.nama_barang})</i></td>
											<td style={{textAlign:'right'}}>{parseInt(list?.harga_barang)?.toLocaleString().replace(/,/g,".",)}</td>
										</tr>
									)
								})}
								<tr>
									<td style={{textAlign:"right"}}>Sub Total : </td>
									<td style={{textAlign:'right'}}>IDR {parseInt(total)?.toLocaleString().replace(/,/g,".",)}</td>
								</tr>
								<tr>
									<td style={{textAlign:"right"}}>Ongkir : </td>
									<td style={{textAlign:'right'}}>IDR {parseInt(dataCustomer?.pengiriman?.ongkir)?.toLocaleString().replace(/,/g,".",) || "-"}</td>
								</tr>
								{(dataCustomer?.pembayaran?.diskon)?
								<tr>
									<td style={{textAlign:"right"}}>Potongan : </td>
									<td style={{textAlign:'right'}}>IDR {parseInt(dataCustomer?.pembayaran?.diskon)?.toLocaleString().replace(/,/g,".",) || "-"}</td>
								</tr>
								:null}
								{(dataCustomer?.pengiriman?.potongan_ongkir)?
								<tr>
									<td style={{textAlign:"right"}}>Potongan Ongkir: </td>
									<td style={{textAlign:'right'}}>IDR {parseInt(dataCustomer?.pengiriman?.potongan_ongkir)?.toLocaleString().replace(/,/g,".",) || "-"}</td>
								</tr>
								:null}
								<tr>
									<td style={{textAlign:"right"}}></td>
									<td style={{textAlign:'right'}}></td>
								</tr>
								<tr>
									<td style={{textAlign:"right"}}>Total Bayar : </td>
									<td style={{textAlign:'right'}}>IDR {(total + dataCustomer?.pengiriman?.ongkir - dataCustomer?.pembayaran?.diskon - dataCustomer?.pengiriman?.potongan_ongkir).toLocaleString().replace(/,/g,".",)}</td>
								</tr>
								<tr>
									<td style={{textAlign:"right"}}>Terbayar : </td>
									<td style={{textAlign:'right'}}>IDR {(dataCustomer?.pembayaran?.nominal)?parseInt(dataCustomer?.pembayaran?.nominal)?.toLocaleString().replace(/,/g,".",) || "-" : "-"}</td>
								</tr>
							</tbody>
						</table>
						<p style={{fontSize:'10px'}}>Metode Pembayaran : {dataCustomer?.pembayaran?.nama} 
						<br/>Waktu Pembayaran : {convertDateTime(dataCustomer?.pembayaran?.waktu_transfer)}
						<br/>Gabung Pengiriman : {(dataCustomer?.pengiriman?.is_ongkir_gabungan === 1)?"Ya":"Tidak"}
						<br/>Jasa Pengiriman : {dataCustomer?.pengiriman?.nama}
						</p>
					</div>
					<br/>
					<div>
						<hr style={{marginBottom:"2px"}}/>
						<div style={{textAlign:"center"}}>
							<span style={{fontWeight:'bold'}}>Pembelian</span>
						</div>
						<hr style={{marginTop:"2px"}}/>
						<table>
							<tbody>
								<tr>
									<td>Akun Live</td>
									<td>: {dataDetailEvent?.data?.data?.akun_live?.nama || "-"}</td>
								</tr>
								<tr>
									<td>Tanggal Live</td>
									<td>: {convertDate(dataDetailEvent?.data?.data?.tanggal_live_event) || "-"}</td>
								</tr>
								<tr>
									<td>Waktu Live</td>
									<td>: {dataDetailEvent?.data?.data?.waktu_live?.nama || "-"}</td>
								</tr>
								<tr>
									<td>ID Pesanan</td>
									<td>: {params?.pesananID || "-"}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br/>
					<div>
						<hr style={{marginBottom:"2px"}}/>
						<div style={{textAlign:"center"}}>
							<span style={{fontWeight:'bold'}}>Penerima</span>
						</div>
						<hr style={{marginTop:"2px"}}/>
						<span>Nama :</span>
						<div style={{fontWeight:'bold', fontSize:'13px', margin:'2px 8px 6px 8px'}}>
							<span>{dataCustomer?.customer?.nama || "-"} ({dataCustomer?.customer?.akun || "-"})</span>
						</div>
						<span>Alamat :</span>
						<div style={{fontWeight:'bold', fontSize:'13px', margin:'2px 8px 6px 8px'}}>
							<span>{dataDetailCustomer?.data?.data?.alamat || "-"}</span>
						</div>
						<span>No HP :</span>
						<div style={{fontWeight:'bold', fontSize:'13px', margin:'2px 8px 6px 8px'}}>
							<span>{dataDetailCustomer?.data?.data?.catatan || "-"}</span>
						</div>
						{/* <table>
							<tbody>
								<tr>
									<td>Nama</td>
									<td>: {dataCustomer?.customer?.nama || "-"} ({dataCustomer?.customer?.akun || "-"})</td>
								</tr>
								<tr>
									<td>Alamat</td>
									<td>: {dataDetailCustomer?.data?.data?.alamat || "-"}</td>
								</tr>
								<tr>
									<td>Catatan</td>
									<td>: {dataDetailCustomer?.data?.data?.catatan || "-"}</td>
								</tr>
							</tbody>
						</table> */}
					</div>
					<br/>
					<div>
						<hr style={{marginBottom:"2px"}}/>
						<div style={{textAlign:"center"}}>
							<span style={{fontWeight:'bold'}}>Pengirim</span>
						</div>
						<hr style={{marginTop:"2px"}}/>
						<table>
							<tbody>
								<tr>
									<td>Nama</td>
									<td>: {profile?.data?.nama || "-"} ({dataDetailEvent?.data?.data?.akun_live?.nama || "-"})</td>
								</tr>
								<tr>
									<td>Alamat</td>
									<td>: Klaten</td>
								</tr>
								<tr>
									<td>Jasa Pengiriman</td>
									<td>: {dataCustomer?.pengiriman?.nama}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div style={{textAlign:"center"}}>
						<br/>
						<hr/>
						<p>Terima Kasih <br/> Semoga Berkah <br/> #supportUMKM <br/> Roemah Bajoe Group</p>
					</div>
				</div>
			</div>
			<br/>
			<br/>
			<div style={{textAlign:"center"}}>
				<ReactToPrint
					trigger={() => <button style={{
						backgroundColor:'green', 
						color:'white', 
						border:'1px green solid', 
						fontWeight:'bold',
						width:'300px',
						padding:'8px 4px',
						borderRadius:'4px',
						cursor:'pointer'
					}}>Cetak</button>}
					content={() => componentRef.current}
				/>
			</div>
    </>
  );
}

export default BasicTable;