import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Gap, MyButton } from "../../atoms";
const AlertMessage = ({
  openDialog,
  handleCloseDialog,
  message,
  status,
  ...rest
}) => {
  return (
    <>
      <Dialog
        {...rest}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent fullWidth={true} maxWidth={"sm"}>
          <div align="center">
            {status === "error" ? (
              <ErrorIcon style={{ fontSize: 60, color: "red" }} />
            ) : (
              <CheckCircleIcon style={{ fontSize: 60, color: "green" }} />
            )}
          </div>
          {message}
          <Gap height={20} />
          <MyButton
            component="span"
            color="#FFFFFF"
            backgroundColor="#F06524"
            backgroundColorHover="#F06524"
            variant="outlined"
            colorHover="white"
            children="Tutup"
            borderColor="#F06524"
            onClick={handleCloseDialog}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AlertMessage;
