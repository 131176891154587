import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { PRIMARY } from "../../../utils/constansColors/index";

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-outlined": {
    zIndex: 0,
  },
  "& label.Mui-focused": {
    color: PRIMARY,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: PRIMARY,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "none",
    },
    "&:hover fieldset": {
      borderColor: PRIMARY,
    },
    "&.Mui-focused fieldset": {
      borderColor: PRIMARY,
    },
  },
});

const Input = ({ label, ...rest }) => {
  return <CssTextField id={label} label={label} {...rest} />;
};

export default Input;
