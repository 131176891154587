import {
  GET_ALL_EVENT_BARANG,
  GET_ALL_EVENT_BARANG_SUCCESS,
  GET_ALL_EVENT_BARANG_FAIL,
  PUT_EVENT_BARANG,
  PUT_EVENT_BARANG_SUCCESS,
  PUT_EVENT_BARANG_FAIL,
  ADD_EVENT_BARANG,
  ADD_EVENT_BARANG_SUCCESS,
  ADD_EVENT_BARANG_FAIL,
  DELETE_EVENT_BARANG,
  DELETE_EVENT_BARANG_SUCCESS,
  DELETE_EVENT_BARANG_FAIL,
} from "./actionTypes";

const initialState = {
  dataAllEventBarang: {
    loading: false,
    error: false,
    data: null,
  },
  updateEventBarang: {
    loading: false,
    error: false,
    data: null,
  },
  addEventBarang: {
    loading: false,
    error: false,
    data: null,
  },
  deleteEventBarang: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_ALL_EVENT_BARANG:
      return {
        ...state,
        dataAllEventBarang: {
          ...state.dataAllEventBarang,
          loading: true,
          error: false,
        },
      };
    case GET_ALL_EVENT_BARANG_SUCCESS:
      return {
        ...state,
        dataAllEventBarang: {
          ...state.dataAllEventBarang,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_ALL_EVENT_BARANG_FAIL:
      return {
        ...state,
        dataAllEventBarang: {
          ...state.dataAllEventBarang,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_EVENT_BARANG:
      return {
        ...state,
        updateEventBarang: {
          ...state.updateEventBarang,
          loading: true,
          error: false,
        },
      };
    case PUT_EVENT_BARANG_SUCCESS:
      return {
        ...state,
        updateEventBarang: {
          ...state.updateEventBarang,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_EVENT_BARANG_FAIL:
      return {
        ...state,
        updateEventBarang: {
          ...state.updateEventBarang,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_EVENT_BARANG:
      return {
        ...state,
        addEventBarang: {
          ...state.addEventBarang,
          loading: true,
          error: false,
        },
      };
    case ADD_EVENT_BARANG_SUCCESS:
      return {
        ...state,
        addEventBarang: {
          ...state.addEventBarang,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_EVENT_BARANG_FAIL:
      return {
        ...state,
        addEventBarang: {
          ...state.addEventBarang,
          loading: false,
          error: action.payload,
        },
      };
      case DELETE_EVENT_BARANG:
        return {
          ...state,
          deleteEventBarang: {
            ...state.deleteEventBarang,
            loading: true,
            error: false,
          },
        };
      case DELETE_EVENT_BARANG_SUCCESS:
        return {
          ...state,
          deleteEventBarang: {
            ...state.deleteEventBarang,
            loading: false,
            error: false,
            data: action.payload,
          },
        };
      case DELETE_EVENT_BARANG_FAIL:
        return {
          ...state,
          deleteEventBarang: {
            ...state.deleteEventBarang,
            loading: false,
            error: action.payload,
          },
        };
    default:
      return state;
  }
};

export default reducer;
