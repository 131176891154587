import React from "react";
import RekapLiveBarang from "../../components/organism/RekapLiveBarang";

const PageRekapLiveBarang = () => {
  return (
    <>
      <RekapLiveBarang />
    </>
  );
};

export default PageRekapLiveBarang;
