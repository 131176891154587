import React from "react";
import { Grid,Paper } from "@mui/material";
import { useSelector } from "react-redux";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

let months = ['Jan','Feb','Mar','Apr', 'Mei','Jun',
                'Jul','Agu','Sep','Okt','Nov','Des'];
const UserManagement = (params) => {
  const dataLaporanRekap = useSelector((state) => state.laporan.dataLaporanRekap);
	const dataLaporanLiveEvent = useSelector((state) => state.laporan.dataLaporanLiveEvent);
  const convertDateMonth = (date) => {
		var local = new Date(date);
		return local.getDate() + ' ' + (months[local.getMonth()]) + ' ' +  local.getFullYear();
	}
	const convertMonth = (date) => {
		var local = new Date(date);
		return (months[local.getMonth()]);
	}
	const checkParam = () =>{
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    return page
  }
  const dataChartLabel = (datas) => {
		let val = [];
		if(datas){
			datas.sort((a, b) => a.tanggal > b.tanggal ? 1 : -1);
			datas.forEach((data) => {
				let temp = ""
				if(params.dateType==="ytd"){
					temp=convertMonth(data?.tanggal)
				} else {
					temp=convertDateMonth(data?.tanggal)
				}
				val.push(temp)
			});
		}
		return val
	}
  const dataChartDataset = (datas) => {
		let val = [];
		if(datas){
			datas.sort((a, b) => a.tanggal > b.tanggal ? 1 : -1);
			datas.forEach((data) => {
				val.push(data?.data["overall"]?.jumlah_harga_barang)
			});
		}
		return val
	}
  const labels = dataChartLabel((checkParam()==="rekap-live")?dataLaporanLiveEvent?.data?.data:dataLaporanRekap?.data?.data);
  const data = {
		labels,
		datasets: [
			{
				label: 'Jumlah Harga Barang',
				data: dataChartDataset((checkParam()==="rekap-live")?dataLaporanLiveEvent?.data?.data:dataLaporanRekap?.data?.data),
				borderColor: '#04AA6D',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
		],
  };

  const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display:false
			},
			title: {
				display: true,
				text: (checkParam()==="sales")?'Riwayat Penjualan':'Riwayat Pesanan Dibatalkan',
			},
		},
  };
	let countAll = {
		jumlah_barang:0,
		jumlah_diskon:0,
		jumlah_harga_barang:0,
		jumlah_nominal_transfer:0,
		jumlah_ongkir:0,
		jumlah_pesanan:0,
		jumlah_potongan_ongkir:0,
	}
	
  return (
    <div>
      <Paper className="overview-paper-secondary">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h3 className="overview-header">Rekap Laporan {(checkParam()==="rekap-live")?"Live":(checkParam()==="sales")?"Panjualan":"Pesanan Dibatalkan"}</h3>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							{/* <div className="overview-filter">
								<div className="overview-toggle">
									<div className={(toggle==="jam")?"active":""} onClick={() => setToggle('jam')}>Per Jam</div>
									<div className={(toggle==="hari")?"active":""} onClick={() => setToggle('hari')}>Harian</div>
								</div>
								<div className="overview-datepicker">
									<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
								</div>
							</div> */}
							<Line options={options} data={data} height="270px" width="100%"/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6} style={{textAlign:'left',marginBottom:'12px'}}>
									<span style={{fontWeight:'bold',color:'#8d8d8d'}}>Daftar Riwayat {(checkParam()==="rekap-live")?"Pesanan Live":(checkParam()==="sales")?"Panjualan":"Pesanan Dibatalkan"}</span>
								</Grid>
								<Grid item xs={12} sm={6} md={6} style={{textAlign:'right'}}>
									<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename={(checkParam()==="rekap-live")?"Riwayat Pesanan Live Overall" + params.dateType + " " + params.searchDate:(checkParam()==="sales")?"Riwayat Panjualan Overall " + params.dateType + " " + params.searchDate:"Riwayat Pesanan Dibatalkan Overall " + params.dateType + " " + params.searchDate}
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
								</Grid>
							</Grid>
							<table className="tabel-laporan" style={{width:"100%"}} id="table-to-xls">
								<thead>
									<tr>
										<th style={{textAlign:"center"}}>{(params.dateType==="ytd")?"Bulan":"Tanggal"}</th>
										<th style={{textAlign:"center"}}>Jumlah Pesanan</th>
										<th style={{textAlign:"center"}}>Jumlah Barang</th>
										<th style={{textAlign:"center"}}>Jumlah Harga Barang</th>

										{(checkParam()==="sales")?<th style={{textAlign:"center"}}>Jumlah Diskon</th>:null}
										{(checkParam()==="sales")?<th style={{textAlign:"center"}}>Jumlah Nominal Ongkir</th>:null}
										{(checkParam()==="sales")?<th style={{textAlign:"center"}}>Jumlah Potongan Ongkir</th>:null}
										{(checkParam()==="sales")?<th style={{textAlign:"center"}}>Jumlah Nominal Transfer</th>:null}
									</tr>
								</thead>
								<tbody>
									{((checkParam()==="rekap-live")?dataLaporanLiveEvent?.data?.data:dataLaporanRekap?.data?.data)?.map((list,idx)=>{
										countAll.jumlah_barang = countAll.jumlah_barang + list?.data["overall"]?.jumlah_barang
										countAll.jumlah_diskon = countAll.jumlah_diskon + list?.data["overall"]?.jumlah_diskon
										countAll.jumlah_harga_barang = countAll.jumlah_harga_barang + list?.data["overall"]?.jumlah_harga_barang
										countAll.jumlah_nominal_transfer = countAll.jumlah_nominal_transfer + list?.data["overall"]?.jumlah_nominal_transfer
										countAll.jumlah_ongkir = countAll.jumlah_ongkir + list?.data["overall"]?.jumlah_ongkir
										countAll.jumlah_pesanan = countAll.jumlah_pesanan + list?.data["overall"]?.jumlah_pesanan
										countAll.jumlah_potongan_ongkir = countAll.jumlah_potongan_ongkir + list?.data["overall"]?.jumlah_potongan_ongkir
										return(<tr key={idx}>
											<td  style={{textAlign:"left"}}>{(params.dateType==="ytd")?months[list?.tanggal-1]:convertDateMonth(list?.tanggal)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_pesanan?.toLocaleString().replace(/,/g,",",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_barang?.toLocaleString().replace(/,/g,",",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_harga_barang?.toLocaleString().replace(/,/g,",",)}</td>
											{(checkParam()==="sales")?<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_diskon?.toLocaleString().replace(/,/g,",",)}</td>:null}
											{(checkParam()==="sales")?<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_ongkir?.toLocaleString().replace(/,/g,",",)}</td>:null}
											{(checkParam()==="sales")?<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_potongan_ongkir?.toLocaleString().replace(/,/g,",",)}</td>:null}
											{(checkParam()==="sales")?<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_nominal_transfer?.toLocaleString().replace(/,/g,",",)}</td>:null}
										</tr>)
									})}
									<tr key={"a"} className="table-rekap-footer">
											<th  style={{textAlign:"left"}}>Total</th>
											<th  style={{textAlign:"right"}}>{countAll.jumlah_pesanan?.toLocaleString().replace(/,/g,",",)}</th>
											<th  style={{textAlign:"right"}}>{countAll.jumlah_barang?.toLocaleString().replace(/,/g,",",)}</th>
											<th  style={{textAlign:"right"}}>{countAll.jumlah_harga_barang?.toLocaleString().replace(/,/g,",",)}</th>
											{(checkParam()==="sales")?<th  style={{textAlign:"right"}}>{countAll.jumlah_diskon?.toLocaleString().replace(/,/g,",",)}</th>:null}
											{(checkParam()==="sales")?<th  style={{textAlign:"right"}}>{countAll.jumlah_ongkir?.toLocaleString().replace(/,/g,",",)}</th>:null}
											{(checkParam()==="sales")?<th  style={{textAlign:"right"}}>{countAll.jumlah_potongan_ongkir?.toLocaleString().replace(/,/g,",",)}</th>:null}
											{(checkParam()==="sales")?<th  style={{textAlign:"right"}}>{countAll.jumlah_nominal_transfer?.toLocaleString().replace(/,/g,",",)}</th>:null}
										</tr>
								</tbody>
							</table>
						</div>
					</Grid>
				</Grid>
			</Paper>
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
