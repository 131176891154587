import React, { useEffect } from "react";
import { Gap, MyButton, MyInput } from "../../components/atoms";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import MyDialog from "../../components/molecules/Dialog";
import { useFormik } from "formik";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  changePassword,
} from "../../config/Redux/Global/actions";
import AlertMessage from "../../components/molecules/AlertMessage";

const validationSchemaPassword = yup.object().shape({
  katasandilama: yup.string().required("Silahkan Masukan Kata Sandi Lama"),
  katasandibaru: yup.string().required("Silahkan Masukan Kata Sandi Baru"),
  katasandibaruconfirm: yup
    .string()
    .required("Silahkan Konfirmasi Kata Sandi")
    .oneOf([yup.ref("katasandibaru"), null], "Kata Sandi tidak sama"),
});

const PageProfile = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();
  const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
  const [showPasswordOld, setShowPasswordOld] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordNewConfirm, setShowPasswordNewConfirm] =
    React.useState(false);

  const dataProfile = useSelector((state) => state.global.dataProfile);

  // alertmessage
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    formik.resetForm();
    setOpenDialog(false);
  };
  const handleOpenDialogProfile = () => {
    setOpenDialogProfile(true);
  };

  const handleCloseDialogProfile = () => {
    setOpenDialogProfile(false);
  };

  const handleClickShowPassword = (param) => {
    if (param === "oldPass") {
      setShowPasswordOld(!showPasswordOld);
    }
    if (param === "newPass") {
      setShowPasswordNew(!showPasswordNew);
    }
    if (param === "passNewConfirm") {
      setShowPasswordNewConfirm(!showPasswordNewConfirm);
    }
  };

  // formik change password
  const formik = useFormik({
    initialValues: {
      katasandilama: "",
      katasandibaru: "",
    },
    enableReinitialize: false,
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      let payload = {
        old_password: values.katasandilama,
        new_password: values.katasandibaru,
      };

      handleCloseDialog();
      // setLoading(true);
      const resp = await dispatch(changePassword(payload));
      handleCloseDialog();
      console.log(`resp change password`, resp);
      if (resp.type === "UPDATE_PASSWORD_SUCCESS") {
        handleCloseDialogProfile();
        setOpenDialogAlert(true);
        setMessage(resp?.payload?.message);
      } else {
        setOpenDialogAlert(true);
        setMessage(resp?.payload?.data?.message);
        setStatus("error");
      }
    },
  });

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const renderFormPassword = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Kata Sandi</b>
        </h2>
        <Gap height={20} />
        <MyInput
          id="katasandilama"
          name="katasandilama"
          label="Kata Sandi Lama*"
          fullWidth
          size="medium"
          value={formik.values.katasandilama}
          onChange={formik.handleChange}
          error={
            formik.touched.katasandilama && Boolean(formik.errors.katasandilama)
          }
          helperText={
            formik.touched.katasandilama && formik.errors.katasandilama
          }
          type={showPasswordOld ? "text" : "password"}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("oldPass")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordOld ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <hr />
        <MyInput
          id="katasandibaru"
          name="katasandibaru"
          label="Kata Sandi Baru*"
          fullWidth
          size="medium"
          value={formik.values.katasandibaru}
          onChange={formik.handleChange}
          error={
            formik.touched.katasandibaru && Boolean(formik.errors.katasandibaru)
          }
          helperText={
            formik.touched.katasandibaru && formik.errors.katasandibaru
          }
          type={showPasswordNew ? "text" : "password"}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("newPass")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Gap height={20} />
        <MyInput
          id="katasandibaruconfirm"
          name="katasandibaruconfirm"
          label="Kata Sandi Baru Konfirmasi*"
          fullWidth
          size="medium"
          value={formik.values.katasandibaruconfirm}
          onChange={formik.handleChange}
          error={
            formik.touched.katasandibaruconfirm &&
            Boolean(formik.errors.katasandibaruconfirm)
          }
          helperText={
            formik.touched.katasandibaruconfirm &&
            formik.errors.katasandibaruconfirm
          }
          type={showPasswordNewConfirm ? "text" : "password"}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("passNewConfirm")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordNewConfirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Gap height={20} />
        <div style={{ display: "flex" }}>
          <MyButton
            height="40px"
            color="#B9B9B9"
            fullWidth
            backgroundColor="#FFFFFFFF"
            borderColor="#B9B9B9"
            backgroundColorHover="#FFFFFFFF"
            variant="outlined"
            children="Batal"
            onClick={handleCloseDialog}
          />
          <Gap width={30} />
          <MyButton
            height="40px"
            color="#8B8B8B"
            colorHover="white"
            fullWidth
            backgroundColor="#E8E8E8"
            borderColor="#E8E8E8"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Simpan"
            type="submit"
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </>
  );
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <MyDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        renderValue={renderFormPassword}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={20} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: 28, fontWeight: 700 }}>Profil</p>
        <div div style={{ display: "flex", marginTop: 20 }}>
          <MyButton
            startIcon={<EditIcon />}
            size="small"
            color="#FF7100"
            height={"60%"}
            // fullWidth
            backgroundColor="#FFF8E1"
            borderColor="#FFF8E1"
            backgroundColorHover="#FFF8E1"
            variant="outlined"
            children="Ubah Kata Sandi"
            onClick={handleOpenDialog}
          />
        </div>
      </div>
      <Paper
        elevation={0}
        style={{ paddingLeft: 10, paddingRight: 10, padding: 40 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <label style={{ fontSize: 11, fontWeight: 400, color: "#8B8B8B" }}>
              Nama Pengguna
            </label>
            <p style={{ fontSize: 16, fontWeight: 600, marginTop: 7 }}>
              {dataProfile?.data?.data?.nama_user}
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <label style={{ fontSize: 11, fontWeight: 400, color: "#8B8B8B" }}>
              Email Pengguna
            </label>
            <p style={{ fontSize: 16, fontWeight: 600, marginTop: 7 }}>
              {dataProfile?.data?.data?.email}
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <label style={{ fontSize: 11, fontWeight: 400, color: "#8B8B8B" }}>
              Username
            </label>
            <p style={{ fontSize: 16, fontWeight: 600, marginTop: 7 }}>
              {dataProfile?.data?.data?.username}
            </p>
          </Grid>
          
        </Grid>
      </Paper>
    </div>
  );
};

export default PageProfile;
