import React from "react";
import RekapAdminBarang from "../../components/organism/RekapAdminBarang";

const PageRekapAdminBarang = () => {
  return (
    <>
      <RekapAdminBarang />
    </>
  );
};

export default PageRekapAdminBarang;
