import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { Gap, MyButton, MyInput, MyAutocomplete } from "../../atoms";
import MyDialog from "../../molecules/Dialog";
import { PRIMARY } from "../../../utils/constansColors";
import AlertMessage from "../../../components/molecules/AlertMessage";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  getAllCustomer,
} from "../../../config/Redux/Customer/actions";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getAllEventPesanan, updateEventPesanan, deleteEventPesanan } from "../../../config/Redux/EventPesanan/actions";
import { useParams,Link } from "react-router-dom";


const validationSchemaEdit = yup.object().shape({
  customer: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .required("Silahkan Masukan Kode Area Customer"),
});

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const columnData = [
  { no: 0, name: "No" },
  { no: 1, name: "Customer" },
  { no: 2, name: "Jumlah Barang Pesanan" },
  { no: 3, name: "Pembayaran" },
  { no: 4, name: "Status Pesanan" },
  { no: 5, name: "Atur Pesanan" },
  { no: 6, name: "Atur Barang Pesanan" },
];

const BasicTable = ({ search, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [openDialogProfile, setOpenDialogProfile] = React.useState(false);
  const [dataPesananCustomer, setPesananCustomer] = React.useState(null);

  // alertMessage
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  // selector
  const dataAllEventPesanan = useSelector((state) => state.eventlivepesanan.dataAllEventPesanan);
  const dataCustomer = useSelector((state) => state.customer.dataCustomer);
  // options
  let optionsCat = dataCustomer?.data?.data?.map((item) => ({
    label: item.nama_akun+" ("+item.nama_customer+")",
    id: item.id_customer,
  }))||[];

  // change password
  const handleOpenDialogDelete = (item) => {
    setPesananCustomer(item);
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  // edit profile
  const handleOpenDialogProfile = (item) => {
    setPesananCustomer(item);
    setOpenDialogProfile(true);
    formikProfile.resetForm();
  };

  const handleCloseDialogProfile = () => {
    setOpenDialogProfile(false);
  };


  // formik edit data profile
  const formikProfile = useFormik({
    initialValues: {
      customer: dataPesananCustomer?.customer?.id
        ? {
            id: dataPesananCustomer?.customer?.id,
            label: dataPesananCustomer?.customer?.akun+" ("+dataPesananCustomer?.customer?.nama+")",
          }
        : null,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaEdit,
    onSubmit: async (values) => {
      let payload = {
        id_customer: values.customer.id,
      };
      let resp = await dispatch(updateEventPesanan(dataPesananCustomer?.id_rekap,payload));
      if (resp.type === "PUT_EVENT_PESANAN_SUCCESS") {
        handleCloseDialogProfile();
        setMessage(resp.payload?.message);
        setOpenDialogAlert(true);
        dispatch(getAllEventPesanan(1, search, "", "desc", 300, params.eventID))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
    },
  });

  const handleSelectSearch = (event) => {
    dispatch(getAllCustomer(1, event.target.value, "nama_customer", 'asc'));
  }
  const handleDelete = async (id) => {
    let resp = await dispatch(deleteEventPesanan(dataPesananCustomer?.id_rekap));
      if (resp.type === "DELETE_EVENT_PESANAN_SUCCESS") {
        handleCloseDialogDelete();
        setMessage(resp.payload?.message);
        setOpenDialogAlert(true);
        dispatch(getAllEventPesanan(1, search, "", "desc", 300, params.eventID))
        setStatus("");
      } else {
        setMessage(resp.payload?.data?.message);
        setOpenDialogAlert(true);
        setStatus("error");
      }
  }
  // form Ubah data
  const renderFormProfile = (
    <>
      <div align="center">
        <h2>
          <b>Ubah Data</b>
        </h2>
      </div>
      <MyAutocomplete
        options={optionsCat}
        fullWidth
        size="medium"
        label="Customer"
        id="customer"
        name="customer"
        value={formikProfile.values.customer || null}
        onKeyUp={(e) => {
          handleSelectSearch(e);
        }}
        onChange={(e, value) => {
          formikProfile.setFieldValue("customer", value);
        }}
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikProfile.touched.customer && formikProfile.errors.customer}
      </FormHelperText>
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogProfile}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  // form Ubah data
  const renderFormDetail = (
    <>
      <div align="center">
        <h2>
          Apakah Anda yakin akan menghapus pesanan ini?
        </h2>
        <p>Menghapus pesanan hanya digunakan jika Anda salah membuat pesanan.<br/> <br/>
        Namun jika pesanan <br/><b>dibatalkan/customer tidak dapat dihubungi</b><br/> maka gunakan fitur <br/><b>UPDATE Informasi Pesanan</b><br/> pesanan dengan status pesanan = Cancel/Tidak bisa dihubungi</p>
      </div>
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Tidak"
          onClick={handleCloseDialogDelete}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#fff"
          colorHover="white"
          fullWidth
          backgroundColor="red"
          borderColor="red"
          backgroundColorHover="red"
          variant="outlined"
          children="Ya"
          onClick={()=>handleDelete(dataPesananCustomer?.id_rekap)}
        />
      </div>
    </>
  );

  const convertDate = (tanggal) => {
    if(tanggal){
      let today = new Date(tanggal);
      let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()+", "+("0" + today.getHours()).slice(-2)+":"+("0" + today.getMinutes()).slice(-2)
      return date
    }else {
      return "-"
    }
  }

  return (
    <>
      {/* change password */}
      <MyDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        renderValue={renderFormDetail}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* edit user */}
      <MyDialog
        openDialog={openDialogProfile}
        handleCloseDialog={handleCloseDialogProfile}
        renderValue={renderFormProfile}
        fullWidth={true}
        maxWidth={"sm"}
      />
      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />

      <TableContainer>
        <Table
          sx={{ minWidth: 1000 }}
          aria-label="simple table"
          className={classes.tableHeader}
        >
          <TableHead>
            <TableRow>
              {columnData.map((item, key) => {
                return (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 700,
                      color: "#757575",
                      width:
                        item.name === "No"
                          ? "3%"
                          : ""
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        {dataAllEventPesanan?.data?.data?.map((item, index) => {
          let number = index + 1 + 10 * (dataAllEventPesanan?.data?.current_page - 1);
          return (
            <Table
              sx={{ minWidth: 1000 }}
              aria-label="simple table"
              className={classes.tableBody}
              key={index}
            >
              <TableBody>
                <TableRow key={item.id}>
                  <TableCell width="3%">{number}</TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word", fontSize:'12px' }}>
                      ID Pesanan : <b>{item?.id_rekap || "-"}</b><br/><br/>
                      Akun : <b>{item?.customer?.akun || "-"}</b><br/>
                      Nama : <b>{item?.customer?.nama || "-"}</b><br/>
                      Kategori Area : <b>{item?.customer?.kategori_area?.nama || "-"}</b>
                    </div>
                  </TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word", textAlign:'center'}}>
                      {item.jumlah_barang}
                    </div>
                  </TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word", fontSize:'12px'}}>
                      Metode : <b>{item?.pembayaran?.nama || "-"}</b><br/>
                      Waktu : <b>{convertDate(item?.pembayaran?.waktu_transfer) || "-"}</b><br/>
                      Nominal : <b>{item?.pembayaran?.nominal || "-"}</b>
                    </div>
                  </TableCell>
                  <TableCell width="">
                    <div style={{ wordWrap: "break-word", }}>
                      {item?.status_pesanan?.status || "-"}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {/* <MyButton
                          startIcon={<DeleteIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          colorHover="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor={"red"}
                          borderColor={"red"}
                          backgroundColorHover={"red"}
                          variant="outlined"
                          children="Hapus Pesanan"
                          onClick={() => handleOpenDialogDelete(item)}
                        />
                        <Gap width={10} /> */}
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Ubah Customer"
                          onClick={() => handleOpenDialogProfile(item)}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {/* <MyButton
                          startIcon={<RemoveRedEyeIcon />}
                          style={{ marginTop: 5 }}
                          color="#F06524"
                          colorHover="#F06524"
                          // height={"100%"}
                          size="small"
                          backgroundColor={"#FFFFFF"}
                          borderColor={"#F06524"}
                          backgroundColorHover={"#FFFFFF"}
                          variant="outlined"
                          children="Lihat Detail Barang"
                          onClick={() => handleOpenDialog(item.id_customer)}
                        />
                        <Gap width={10} /> */}
                        <Link to={"/rekap-admin/pesanan/barang/"+params?.eventID+"/"+params?.akunID+"/"+params?.waktuID+"/"+params?.tanggal+"/"+item?.id_rekap}>
                        <MyButton
                          startIcon={<EditIcon />}
                          style={{ marginTop: 5 }}
                          color="white"
                          // height={"100%"}
                          size="small"
                          backgroundColor="#f06524"
                          borderColor="#f06524"
                          backgroundColorHover="#f06524"
                          variant="outlined"
                          children="Atur Barang Pesanan"
                          // onClick={() => handleOpenDialogProfile(item)}
                        />
                        </Link>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
    </>
  );
}

export default BasicTable;