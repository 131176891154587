export const GET_METODE_PENGIRIMAN = "GET_METODE_PENGIRIMAN";
export const GET_METODE_PENGIRIMAN_SUCCESS = "GET_METODE_PENGIRIMAN_SUCCESS";
export const GET_METODE_PENGIRIMAN_FAIL = "GET_METODE_PENGIRIMAN_FAIL";

// get detail user
export const GET_DETAIL_METODE_PENGIRIMAN = "GET_DETAIL_METODE_PENGIRIMAN";
export const GET_DETAIL_METODE_PENGIRIMAN_SUCCESS = "GET_DETAIL_METODE_PENGIRIMAN_SUCCESS";
export const GET_DETAIL_METODE_PENGIRIMAN_FAIL = "GET_DETAIL_METODE_PENGIRIMAN_FAIL";

// put detail user
export const PUT_METODE_PENGIRIMAN = "PUT_METODE_PENGIRIMAN";
export const PUT_METODE_PENGIRIMAN_SUCCESS = "PUT_METODE_PENGIRIMAN_SUCCESS";
export const PUT_METODE_PENGIRIMAN_FAIL = "PUT_METODE_PENGIRIMAN_FAIL";

// add new user
export const ADD_METODE_PENGIRIMAN = "ADD_METODE_PENGIRIMAN";
export const ADD_METODE_PENGIRIMAN_SUCCESS = "ADD_METODE_PENGIRIMAN_SUCCESS";
export const ADD_METODE_PENGIRIMAN_FAIL = "ADD_METODE_PENGIRIMAN_FAIL";