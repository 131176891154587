export const GET_ALL_EVENT_BARANG = "GET_ALL_EVENT_BARANG";
export const GET_ALL_EVENT_BARANG_SUCCESS = "GET_ALL_EVENT_BARANG_SUCCESS";
export const GET_ALL_EVENT_BARANG_FAIL = "GET_ALL_EVENT_BARANG_FAIL";


// put detail event
export const PUT_EVENT_BARANG = "PUT_EVENT_BARANG";
export const PUT_EVENT_BARANG_SUCCESS = "PUT_EVENT_BARANG_SUCCESS";
export const PUT_EVENT_BARANG_FAIL = "PUT_EVENT_BARANG_FAIL";

// add new event
export const ADD_EVENT_BARANG = "ADD_EVENT_BARANG";
export const ADD_EVENT_BARANG_SUCCESS = "ADD_EVENT_BARANG_SUCCESS";
export const ADD_EVENT_BARANG_FAIL = "ADD_EVENT_BARANG_FAIL";

// DELETE new event
export const DELETE_EVENT_BARANG = "DELETE_EVENT_BARANG";
export const DELETE_EVENT_BARANG_SUCCESS = "DELETE_EVENT_BARANG_SUCCESS";
export const DELETE_EVENT_BARANG_FAIL = "DELETE_EVENT_BARANG_FAIL";