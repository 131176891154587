import {
  GET_ALL_EVENT_BARANG,
  GET_ALL_EVENT_BARANG_SUCCESS,
  GET_ALL_EVENT_BARANG_FAIL,
  PUT_EVENT_BARANG,
  PUT_EVENT_BARANG_SUCCESS,
  PUT_EVENT_BARANG_FAIL,
  ADD_EVENT_BARANG,
  ADD_EVENT_BARANG_SUCCESS,
  ADD_EVENT_BARANG_FAIL,
  DELETE_EVENT_BARANG,
  DELETE_EVENT_BARANG_SUCCESS,
  DELETE_EVENT_BARANG_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllEventBarang = (page, search, sortBy, sortType, size, id_rekap) => (dispatch) => {
    dispatch({
      type: GET_ALL_EVENT_BARANG,
    });

    const request = API.getAllEventBarang(page, search, sortBy, sortType, size, id_rekap);
    return request.then(
      (response) => dispatch(eventLive(response.data,GET_ALL_EVENT_BARANG_SUCCESS)),
      (err) => dispatch(eventLive(err,GET_ALL_EVENT_BARANG_FAIL))
    );
  };

// update user
export const updateEventBarang = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_EVENT_BARANG,
  });

  const request = API.updateEventBarang(id, data);
  return request.then(
    (response) => dispatch(eventLive(response.data,PUT_EVENT_BARANG_SUCCESS)),
    (err) => dispatch(eventLive(err,PUT_EVENT_BARANG_FAIL))
  );
};

// add new user
export const createEventBarang = (data) => (dispatch) => {
  dispatch({
    type: ADD_EVENT_BARANG,
  });

  const request = API.createEventBarang(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,ADD_EVENT_BARANG_SUCCESS)),
    (err) => dispatch(eventLive(err,ADD_EVENT_BARANG_FAIL))
  );
};

export const deleteEventBarang = (id,data) => (dispatch) => {
  dispatch({
    type: DELETE_EVENT_BARANG,
  });

  const request = API.deleteEventBarang(id,data);
  return request.then(
    (response) => dispatch(eventLive(response.data,DELETE_EVENT_BARANG_SUCCESS)),
    (err) => dispatch(eventLive(err,DELETE_EVENT_BARANG_FAIL))
  );
};

export const eventLive = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
