import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

const MyDialog = ({ openDialog, handleCloseDialog, renderValue, ...rest }) => {
  return (
    <>
      <Dialog
        {...rest}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{renderValue}</DialogContent>
      </Dialog>
    </>
  );
};

export default MyDialog;
