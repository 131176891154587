import React from "react";
import RekapAdminPesanan from "../../components/organism/RekapAdminPesanan";

const PageRekapAdminPesanan = () => {
  return (
    <>
      <RekapAdminPesanan />
    </>
  );
};

export default PageRekapAdminPesanan;
