import {
  GET_ALL_EVENT,
  GET_ALL_EVENT_SUCCESS,
  GET_ALL_EVENT_FAIL,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  GET_ALL_EVENT_LAST,
  GET_ALL_EVENT_LAST_SUCCESS,
  GET_ALL_EVENT_LAST_FAIL,
  PUT_EVENT,
  PUT_EVENT_SUCCESS,
  PUT_EVENT_FAIL,
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  ASSIGN_REVIEWER_EVENT,
  ASSIGN_REVIEWER_EVENT_SUCCESS,
  ASSIGN_REVIEWER_EVENT_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllEvent = (id_live_event) => (dispatch) => {
    dispatch({
      type: GET_ALL_EVENT,
    });

    const request = API.getAllEvent(id_live_event);
    return request.then(
      (response) => dispatch(eventLive(response.data,GET_ALL_EVENT_SUCCESS)),
      (err) => dispatch(eventLive(err,GET_ALL_EVENT_FAIL))
    );
  };


export const getEvent = (param) => (dispatch) => {
  dispatch({
    type: GET_EVENT_DETAIL,
  });

  const request = API.getEvent(param);
  return request.then(
    (response) => dispatch(eventLive(response.data,GET_EVENT_DETAIL_SUCCESS)),
    (err) => dispatch(eventLive(err,GET_EVENT_DETAIL_FAIL))
  );
};

export const getAllEventLast = (filterDate) => (dispatch) => {
  dispatch({
    type: GET_ALL_EVENT_LAST,
  });

  const request = API.getAllEventLast(filterDate);
  return request.then(
    (response) => dispatch(eventLive(response.data,GET_ALL_EVENT_LAST_SUCCESS)),
    (err) => dispatch(eventLive(err,GET_ALL_EVENT_LAST_FAIL))
  );
};

// update user
export const updateEvent = (data) => (dispatch) => {
  dispatch({
    type: PUT_EVENT,
  });

  const request = API.updateEvent(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,PUT_EVENT_SUCCESS)),
    (err) => dispatch(eventLive(err,PUT_EVENT_FAIL))
  );
};

// add new user
export const createEvent = (data) => (dispatch) => {
  dispatch({
    type: ADD_EVENT,
  });

  const request = API.createEvent(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,ADD_EVENT_SUCCESS)),
    (err) => dispatch(eventLive(err,ADD_EVENT_FAIL))
  );
};

export const deleteEvent = (data) => (dispatch) => {
  dispatch({
    type: DELETE_EVENT,
  });

  const request = API.deleteEvent(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,DELETE_EVENT_SUCCESS)),
    (err) => dispatch(eventLive(err,DELETE_EVENT_FAIL))
  );
};

export const assignReviewer = (data) => (dispatch) => {
  dispatch({
    type: ASSIGN_REVIEWER_EVENT,
  });

  const request = API.assignReviewer(data);
  return request.then(
    (response) => dispatch(eventLive(response.data,ASSIGN_REVIEWER_EVENT_SUCCESS)),
    (err) => dispatch(eventLive(err,ASSIGN_REVIEWER_EVENT_FAIL))
  );
};

export const eventLive = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
