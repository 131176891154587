import React, { useEffect } from "react";
import { Gap, MyInput, MyButton } from "../../atoms";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import MyDialog from "../../molecules/Dialog";
import { useFormik } from "formik";
import AlertMessage from "../../../components/molecules/AlertMessage";
import {
  getAllEventBarang,
  createEventBarang
} from "../../../config/Redux/EventBarang/actions";
import {
  getAllEventPesanan,
} from "../../../config/Redux/EventPesanan/actions";
import {
  getEvent,
} from "../../../config/Redux/Event/actions";
import { useDispatch, useSelector } from "react-redux";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const validationSchemaCreate = yup.object().shape({
  kode_barang: yup.string("Masukkan Kode Barang").required("Silahkan Masukkan Kode Barang"),
  nama_barang: yup.string("Masukkan Nama Barang").required("Silahkan Masukkan Nama Barang"),
  harga_barang: yup.string("Masukkan Harga Barang").required("Silahkan Masukkan Harga Barang"),
});

const UserManagement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let params = useParams();
  const [openDialogUser, setOpenDialogUser] = React.useState(false);
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  // const [dataCustomer, setCustomer] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [search, setSearch] = React.useState("");

  const [currentPage, setCurrentPage] = React.useState(1);
  const handlePagination = (event, value) => {
    setCurrentPage(value);
    // dispatch(getAllEventPesanan(value, search, "", "desc", 100, params.eventID));
    // dispatch(getAllCustomer(value, search, sortBy, sortType));
    dispatch(getAllEventBarang(value, search, "", "desc", 300, params.pesananID));
  };
  const dataAllEventBarang = useSelector((state) => state.eventlivebarang.dataAllEventBarang);

  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()
    return date
  }

  // selector
  // let dataDetailEvent = useSelector((state) => state.eventlive.dataDetailEvent);
  let dataAllEventPesanan = useSelector((state) => state.eventlivepesanan.dataAllEventPesanan);
  // console.log(dataAllEventPesanan)
  let dataCustomer = null;
  dataAllEventPesanan?.data?.data?.map((item) => {
    if(item.id_rekap === parseInt(params?.pesananID)){
      dataCustomer = item
    }
    return item
  });

  // formik add user
  const formikProfile = useFormik({
    initialValues: {
      kode_barang: "",
      nama_barang: "",
      harga_barang: ""
    },
    enableReinitialize: true,
    validationSchema: validationSchemaCreate,
    onSubmit: async (values) => {
      let payload = {
        id_rekap: params.pesananID,
        nama_barang: values.nama_barang,
        kode_barang: values.kode_barang,
        harga_barang: values.harga_barang,
      };
      let resp = await dispatch(createEventBarang(payload));
      if (resp.type === "ADD_EVENT_BARANG_SUCCESS") {
        handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getAllEventBarang(currentPage, search, "", "desc", 300, params.pesananID));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });

  // new user
  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
    formikProfile.resetForm();
  };
  const handleCloseDialogUser = () => {
    setOpenDialogUser(false);
    formikProfile.resetForm();
  };
  const renderFormNewData = (
    <>
      <div align="center">
        <h2>
          <b>Tambah Barang Pesanan</b>
        </h2>
      </div>
      <MyInput
        id="nama_barang"
        name="nama_barang"
        label="Nama Barang"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_barang && Boolean(formikProfile.errors.nama_barang)}
        helperText={formikProfile.touched.nama_barang && formikProfile.errors.nama_barang}
      />
      <Gap height={20} />
      <MyInput
        id="kode_barang"
        name="kode_barang"
        label="Kode Barang"
        fullWidth
        size="medium"
        value={formikProfile.values.kode_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.kode_barang && Boolean(formikProfile.errors.kode_barang)}
        helperText={formikProfile.touched.kode_barang && formikProfile.errors.kode_barang}
      />
      <Gap height={20} />
      <MyInput
        id="harga_barang"
        name="harga_barang"
        label="Harga Barang"
        fullWidth
        size="medium"
        type="number"
        value={formikProfile.values.harga_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.harga_barang && Boolean(formikProfile.errors.harga_barang)}
        helperText={formikProfile.touched.harga_barang && formikProfile.errors.harga_barang}
      />
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUser}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  useEffect(() => {
    dispatch(getAllEventBarang(1, "", "", "desc", 300, params.pesananID))
    dispatch(getEvent(params.eventID));
    dispatch(getAllEventPesanan(1, params?.pesananID, "", "desc", 300, params.eventID));
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearch(event.target.value)
    let temp = event.target.value
    var timeout;
    var delay = 500;
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      if(temp === event.target.value){
        dispatch(getAllEventBarang(currentPage, event.target.value, "", "desc", 300, params.pesananID));
      }
    }, delay);
  };

  const breadcrumbs = [
    <Link
      to={"/"}
      style={{ textDecoration: "none", fontWeight: "bold", color: "black", fontSize:'14px' }}
    >
      Rekap Live
    </Link>,
    <Link
      to={"/rekap-live/pesanan/"+params.eventID+"/"+params.akunID+"/"+params.waktuID+"/"+params.tanggal}
      style={{ textDecoration: "none", fontWeight: "bold", color: "black", fontSize:'14px' }}
    >
      Rekap Pesanan
    </Link>,
    <Typography key="2" color="text.primary" style={{ color: "#BDBDBD", fontSize:'14px', marginTop:'2px' }}>
      Detail Pesanan
    </Typography>
  ];

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      {/* add new user */}
      <MyDialog
        openDialog={openDialogUser}
        handleCloseDialog={handleCloseDialogUser}
        renderValue={renderFormNewData}
        fullWidth={true}
        maxWidth={"sm"}
      />

      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={40} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <p style={{ fontSize: 28, fontWeight:700,marginTop:0 }}>Detail Pesanan</p>
        </div>
      </div>

      <div>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={6}>
            <p style={{marginTop:0, fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
              Informasi Event Live
            </p>
            <table>
              <tbody>
                <tr>
                  <td>Akun Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.akun_live?.nama || "-"}</b></td>
                </tr>
                <tr>
                  <td>Tanggal Live</td>
                  <td>: <b>{convertDate(dataDetailEvent?.data?.data?.tanggal_live_event) || "-"}</b></td>
                </tr>
                <tr>
                  <td>Waktu Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.waktu_live?.nama || "-"}</b></td>
                </tr>
                <tr>
                  <td>Waktu Mulai Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.waktu_mulai || "-"}</b></td>
                </tr>
                <tr>
                  <td>Durasi Live</td>
                  <td>: <b>{dataDetailEvent?.data?.data?.durasi_live_jam || "-"} jam</b></td>
                </tr>
              </tbody>
            </table>
          </Grid> */}
          <Grid item xs={12} sm={6} md={6}>
            <p style={{marginTop:0, fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
              Informasi Customer
            </p>
            <table>
              <tbody>
                <tr>
                  <td>Akun Customer</td>
                  <td>: <b>{dataCustomer?.customer?.akun || "-"}</b></td>
                </tr>
                <tr>
                  <td>Nama Customer</td>
                  <td>: <b>{dataCustomer?.customer?.nama || "-"}</b></td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
          
      {/* Tableee */}
      <Gap height={30} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <p style={{ fontSize: 20, fontWeight:700, margin:0,lineHeight:"40px"  }}>Daftar Barang Pesanan</p>
        </div>
        <div style={{ display: "flex" }}>
          {/* <Filters /> */}
          <Gap width={10} />

          <MyInput
            size="small"
            id="search"
            name="search"
            // label="Cari"
            placeholder="Cari"
            onChange={handleSearch}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Gap width={10} />
          {(dataAllEventPesanan?.data?.data[0]?.status_pesanan?.status === "Keep")?
          <MyButton
            // style={{ marginTop: 25 }}
            startIcon={<AddIcon />}
            height="40px"
            color="white"
            backgroundColor="#F06524"
            borderColor="#F06524"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Tambah Pesanan"
            onClick={handleOpenDialogUser}
          />
          :""}
        </div>
      </div>
      <Table 
        search={search}
        dataAllEventPesanan={dataAllEventPesanan}
      />
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataAllEventBarang?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={60} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
