import React, { useEffect } from "react";
import { Gap, MyInput, MyButton, MyAutocomplete } from "../../atoms";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import MyDialog from "../../molecules/Dialog";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useFormik } from "formik";
import AlertMessage from "../../../components/molecules/AlertMessage";
import {
  getAllAkunLive,
  createAkunLive,
} from "../../../config/Redux/AkunLive/actions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const validationSchemaCreate = yup.object().shape({
  nama_akun_live: yup.string("Masukan Nama Akun Live").required("Silahkan Masukan Nama Akun Live"),
});

const UserManagement = () => {
  const dispatch = useDispatch();
  const [openDialogUser, setOpenDialogUser] = React.useState(false);

  // alert
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);

  // formik add user
  const formikProfile = useFormik({
    initialValues: {
      nama_akun_live: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchemaCreate,
    onSubmit: async (values) => {
      let payload = {
        nama_akun_live: values.nama_akun_live,
      };
      let resp = await dispatch(createAkunLive(payload));
      if (resp.type === "ADD_AKUN_LIVE_SUCCESS") {
        handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getAllAkunLive(1));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });

  // new user
  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
    formikProfile.resetForm();
  };

  const handleCloseDialogUser = () => {
    setOpenDialogUser(false);
    formikProfile.resetForm();
  };
  const renderFormNewData = (
    <>
      <div align="center">
        <h2>
          <b>Tambah Akun Live</b>
        </h2>
      </div>
      <MyInput
        id="nama_akun_live"
        name="nama_akun_live"
        label="Nama Akun Live*"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_akun_live}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_akun_live && Boolean(formikProfile.errors.nama_akun_live)}
        helperText={formikProfile.touched.nama_akun_live && formikProfile.errors.nama_akun_live}
      />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUser}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );
  const classes = useStyles();

  const dataUsers = useSelector((state) => state.akunlive.dataAkunLive);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [sortBy, setSortBy] = React.useState("nama_akun_live");
  const [sortType, setSortType] = React.useState("asc");

  useEffect(() => {
    dispatch(getAllAkunLive(1));
  }, [dispatch]);

  const handlePagination = (event, value) => {
    setCurrentPage(value);
    dispatch(getAllAkunLive(value, search, sortBy, sortType));
  };

  const handleSearch = (event) => {
    setSearch(event.target.value)
    let temp = event.target.value
    var timeout;
    var delay = 500;
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      if(temp === event.target.value){
        dispatch(getAllAkunLive(1, event.target.value, sortBy, sortType));
        setCurrentPage(1);
      }
    }, delay);
  };

  const handleSortBy = (e) => {
    setSortBy(e.target.value);
    dispatch(
      getAllAkunLive(currentPage, search, e.target.value, sortType)
    );
  };

  const handleSortType = (e) => {
    setSortType(e.target.value);
    dispatch(
      getAllAkunLive(currentPage, search, sortBy, e.target.value)
    );
  };
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      {/* add new user */}
      <MyDialog
        openDialog={openDialogUser}
        handleCloseDialog={handleCloseDialogUser}
        renderValue={renderFormNewData}
        fullWidth={true}
        maxWidth={"sm"}
      />

      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={20} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: 28, fontWeight:700 }}>Akun Live</p>
        <div style={{ display: "flex", marginTop: 25 }}>
          {/* <Filters /> */}
          <Gap width={10} />

          <MyInput
            size="small"
            id="search"
            name="search"
            // label="Cari"
            placeholder="Cari"
            onChange={handleSearch}
            style={{
              // width: "70%",
              borderRadius: 5,
              backgroundColor: "white",
              height: 40,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Gap width={10} />
          <MyButton
            // style={{ marginTop: 25 }}
            startIcon={<AddIcon />}
            height="63%"
            color="white"
            backgroundColor="#F06524"
            borderColor="#F06524"
            backgroundColorHover="#F06524"
            variant="outlined"
            children="Tambah Akun Live"
            onClick={handleOpenDialogUser}
          />
        </div>
      </div>

      <div className="filter-tabel-exco">

        {/* sort */}
        <div className="filter-tabel-exco-content">
          <p>Urutkan Berdasarkan</p>
          <select onChange={handleSortBy}>
            <option value={"nama_akun_live"}>{"Nama Akun Live"}</option>
          </select>
        </div>
        <div className="filter-tabel-exco-content">
          <p>Urutkan Secara</p>
          <select onChange={handleSortType}>
            <option value={"asc"}>{"A-Z (Ascending)"}</option>
            <option value={"desc"}>{"Z-A (Descending)"}</option>
          </select>
        </div>
      </div>

      {/* Tableee */}
      <Table 
        currentPage={currentPage} 
        search={search} 
        sortBy={sortBy} 
        sortType={sortType} 
      />
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "10px",
        }}
      >
        <Pagination
          count={dataUsers?.data?.total_pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePagination}
          className={classes.root}
        />
      </div>
      <Gap height={20} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
