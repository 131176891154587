import React, { useEffect } from "react";
import { Gap, MyInput, MyButton, MyAutocomplete } from "../../atoms";
import FormHelperText from "@mui/material/FormHelperText";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Table from "./Table";
import Nota from "./Nota";
import MyDialog from "../../molecules/Dialog";
import { useFormik } from "formik";
import AlertMessage from "../../../components/molecules/AlertMessage";
import {
  getAllEventBarang,
  createEventBarang
} from "../../../config/Redux/EventBarang/actions";
import {
  getAllEventPesanan,
  updateEventPesanan
} from "../../../config/Redux/EventPesanan/actions";
import {
  getEvent,
} from "../../../config/Redux/Event/actions";
import {
  // getKategoriPembayaran,
  // getKategoriPengiriman,
  getAllStatusPesanan,
  getKategoriArea
} from "../../../config/Redux/Global/actions";
import {
  getAllPembayaran
} from "../../../config/Redux/MetodePembayaran/actions";
import {
  getAllPengiriman
} from "../../../config/Redux/MetodePengiriman/actions";
import {
  getCustomer,
  updateCustomer
} from "../../../config/Redux/Customer/actions";
import { useDispatch, useSelector } from "react-redux";
import { PRIMARY } from "../../../utils/constansColors";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: PRIMARY,
      color: "white",
      border: "none",
    },
  },
  tableBody: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    fontSize: "25px",
    borderRadius: 5,
  },
  tableHeader: {
    backgroundColor: "transparant",
    width: "100%",
    tableLayout: "fixed",
    marginTop: "10px",
    color: "#757575",
    fontWeight: 700,
  },
});

const validationSchemaCreate = yup.object().shape({
  kode_barang: yup.string("Masukkan Kode Barang").required("Silahkan Masukkan Kode Barang"),
  nama_barang: yup.string("Masukkan Nama Barang").required("Silahkan Masukkan Nama Barang"),
  harga_barang: yup.string("Masukkan Harga Barang").required("Silahkan Masukkan Harga Barang"),
});

const validationSchemaUpdateInfo = yup.object().shape({
  tanggal_rekap_pembukuan:yup.string("Masukkan Tanggal Rekap Pembukuan").nullable(),
  waktu_transfer: yup.string("Masukkan Waktu Transfer").nullable(),
  nominal: yup.string("Masukkan Nominal Transfer").nullable(),
  diskon: yup.string("Masukkan Potongan Harga").nullable(),
  berat: yup.string("Masukkan Berat Barang (Kg)").nullable(),
  ongkir: yup.string("Masukkan Ongkir").nullable(),
  potongan_ongkir: yup.string("Masukkan Potongan Ongkir").nullable(),
  is_ongkir_gabungan: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable(),
  id_metode_pembayaran: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable(),
  id_metode_pengiriman: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable(),
  id_status_pesanan: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable(),
});

const validationSchemaUpdateCustomer = yup.object().shape({
  alamat: yup.string("Masukkan Waktu Transfer").nullable(),
  catatan: yup.string("Masukkan Nominal Transfer").nullable(),
  id_kategori_area: yup
  .object()
  .shape({
    label: yup.string(),
    id: yup.string(),
  })
  .nullable(),
});

const UserManagement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let params = useParams();
  const [openDialogUser, setOpenDialogUser] = React.useState(false);
  const [openDialogAlert, setOpenDialogAlert] = React.useState(false);
  const [openDialogInfoEvent, setOpenDialogInfoEvent] = React.useState(false);
  const [openDialogUpdateCustomer, setOpenDialogUpdateCustomer] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [dataCustomer, setCustomer] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [cetak, setCetak] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const handlePagination = (event, value) => {
    setCurrentPage(value);
    // dispatch(getAllEventPesanan(value, search, "", "desc", 100, params.eventID));
    // dispatch(getAllCustomer(value, search, sortBy, sortType));
    dispatch(getAllEventBarang(value, search, "", "desc", 300, params.pesananID));
  };
  const dataAllEventBarang = useSelector((state) => state.eventlivebarang.dataAllEventBarang);


  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()
    return date
  }
  const convertDateTime = (tanggal) => {
    let today = new Date(tanggal);
    let date = ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+today.getFullYear()+', '+("0" + today.getHours()).slice(-2)+":"+("0" + today.getMinutes()).slice(-2)
    return date
  }
  const convertDateReverseSort = (tanggal) => {
    let today = new Date(tanggal);
    let date = today.getFullYear()+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2)
    return date
  }
  const convertDateReverse = (tanggal) => {
    let today = new Date(tanggal);
    let date = today.getFullYear()+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2)+'T'+("0" + today.getHours()).slice(-2)+":"+("0" + today.getMinutes()).slice(-2)
    return date
  }

  const handleOpenDialogUpdateEvent = () => {
    setOpenDialogInfoEvent(true);
  };
  const handleCloseDialogUpdateEvent = () => {
    setOpenDialogInfoEvent(false);
    formikUpdateEvent.resetForm();
  };

  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
    formikProfile.resetForm();
  };
  const handleCloseDialogUser = () => {
    setOpenDialogUser(false);
    formikProfile.resetForm();
  };

  const handleOpenDialogUpdateCustomer = () => {
    setOpenDialogUpdateCustomer(true);
    formikUpdateCustomer.resetForm();
  };
  const handleCloseDialogUpdateCustomer = () => {
    setOpenDialogUpdateCustomer(false);
    formikUpdateCustomer.resetForm();
  };

  // selector
  let dataDetailEvent = useSelector((state) => state.eventlive.dataDetailEvent);
  let dataDetailCustomer = useSelector((state) => state.customer.dataDetailCustomer);

  // let dataPembayaran = useSelector((state) => state.global?.dataKategoriPembayaran);
  let dataStatusPesanan = useSelector((state) => state.global?.dataStatusPesanan);
  // let dataPengiriman = useSelector((state) => state.global?.dataKategoriPengiriman);
  let dataPembayaran = useSelector((state) => state.metodepembayaran?.dataPembayaran);
  let dataPengiriman = useSelector((state) => state.metodepengiriman?.dataPengiriman);
  let dataKategoriArea = useSelector((state) => state.global?.dataKategoriArea);

  let optionsPengiriman = dataPengiriman?.data?.data?.map((item) => ({
    label: item?.nama_metode_pengiriman || "",
    id: item.id_metode_pengiriman,
  }))||[];

  let optionsPembayaran = dataPembayaran?.data?.data?.map((item) => ({
    label: item?.nama_metode_pembayaran || "",
    id: item.id_metode_pembayaran,
  }))||[];

  let optionsStatusPesanan = dataStatusPesanan?.data?.data?.map((item) => ({
    label: item?.nama_status_pesanan || "",
    id: item?.id_status_pesanan,
  }))||[];

  let optionsKategoriArea = dataKategoriArea?.data?.data?.map((item) => ({
    label: item?.nama_kategori_area,
    id: item?.id_kategori_area || "",
  }))||[];

  // formik add user
  const formikProfile = useFormik({
    initialValues: {
      kode_barang: "",
      nama_barang: "",
      harga_barang: ""
    },
    enableReinitialize: true,
    validationSchema: validationSchemaCreate,
    onSubmit: async (values) => {
      let payload = {
        id_rekap: params.pesananID,
        nama_barang: values.nama_barang,
        kode_barang: values.kode_barang,
        harga_barang: values.harga_barang,
      };
      let resp = await dispatch(createEventBarang(payload));
      if (resp.type === "ADD_EVENT_BARANG_SUCCESS") {
        handleCloseDialogUser();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getAllEventBarang(1, search, "", "desc", 300, params.pesananID));
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });

  const renderFormNewData = (
    <>
      <div align="center">
        <h2>
          <b>Tambah Barang Pesanan</b>
        </h2>
      </div>
      <MyInput
        id="nama_barang"
        name="nama_barang"
        label="Nama Barang"
        fullWidth
        size="medium"
        value={formikProfile.values.nama_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.nama_barang && Boolean(formikProfile.errors.nama_barang)}
        helperText={formikProfile.touched.nama_barang && formikProfile.errors.nama_barang}
      />
      <Gap height={20} />
      <MyInput
        id="kode_barang"
        name="kode_barang"
        label="Kode Barang"
        fullWidth
        size="medium"
        value={formikProfile.values.kode_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.kode_barang && Boolean(formikProfile.errors.kode_barang)}
        helperText={formikProfile.touched.kode_barang && formikProfile.errors.kode_barang}
      />
      <Gap height={20} />
      <MyInput
        id="harga_barang"
        name="harga_barang"
        label="Harga Barang"
        fullWidth
        size="medium"
        type="number"
        value={formikProfile.values.harga_barang}
        onChange={formikProfile.handleChange}
        error={formikProfile.touched.harga_barang && Boolean(formikProfile.errors.harga_barang)}
        helperText={formikProfile.touched.harga_barang && formikProfile.errors.harga_barang}
      />
      <Gap height={20} />
      
      <Gap height={20} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUser}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikProfile.handleSubmit}
        />
      </div>
    </>
  );

  // update event
  const formikUpdateEvent = useFormik({
    initialValues: {
      waktu_transfer: (dataCustomer?.pembayaran?.waktu_transfer)?convertDateReverse(dataCustomer?.pembayaran?.waktu_transfer):convertDateReverse(new Date()),
      tanggal_rekap_pembukuan: (dataCustomer?.tanggal_rekap_pembukuan)?convertDateReverseSort(dataCustomer?.tanggal_rekap_pembukuan):convertDateReverseSort(new Date()),
      nominal: dataCustomer?.pembayaran?.nominal,
      diskon: dataCustomer?.pembayaran?.diskon,
      berat: dataCustomer?.pengiriman?.berat,
      ongkir: dataCustomer?.pengiriman?.ongkir,
      potongan_ongkir: dataCustomer?.pengiriman?.potongan_ongkir,
      is_ongkir_gabungan: dataCustomer?.pengiriman?.is_ongkir_gabungan
      ? {
          id: dataCustomer?.pengiriman?.is_ongkir_gabungan,
          label: (dataCustomer?.pengiriman?.is_ongkir_gabungan === 1)?"Gabung Pesanan Lain":"Tidak Digabung Pesanan Lain",
        }
      : {
        id: 0,
        label: "Tidak Digabung Pesanan Lain",
      },
      id_metode_pembayaran: dataCustomer?.pembayaran?.id
      ? {
          id: dataCustomer?.pembayaran?.id,
          label: dataCustomer?.pembayaran?.nama,
        }
      : null,
      id_metode_pengiriman: dataCustomer?.pengiriman?.id
      ? {
          id: dataCustomer?.pengiriman?.id,
          label: dataCustomer?.pengiriman?.nama,
        }
      : null,
      id_status_pesanan: dataCustomer?.status_pesanan?.id
      ? {
          id: dataCustomer?.status_pesanan?.id,
          label: dataCustomer?.status_pesanan?.status,
        }
      : null,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaUpdateInfo,
    onSubmit: async (values) => {
      let payload = {
        tanggal_rekap_pembukuan:values?.tanggal_rekap_pembukuan,
        id_customer:dataCustomer?.customer?.id,
        waktu_transfer: values.waktu_transfer,
        nominal: values.nominal,
        diskon: values.diskon,
        berat: values.berat,
        ongkir: values.ongkir,
        potongan_ongkir: values.potongan_ongkir,
        is_ongkir_gabungan: values.is_ongkir_gabungan?.id,
        id_metode_pembayaran: values.id_metode_pembayaran?.id,
        id_metode_pengiriman: values.id_metode_pengiriman?.id,
        id_status_pesanan: values.id_status_pesanan?.id,
      };
      let resp = await dispatch(updateEventPesanan(params.pesananID,payload));
      if (resp.type === "PUT_EVENT_PESANAN_SUCCESS") {
        handleCloseDialogUpdateEvent();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        let a = await dispatch(getAllEventPesanan(1, parseInt(params?.pesananID), "", "desc", 300, params.eventID));
        if(a){
          a?.payload?.data?.map((item) => {
            if(item.id_rekap === parseInt(params?.pesananID)){
              setCustomer(item)
              dispatch(getCustomer(item?.customer?.id))
            }
            return item
          });
        }
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });
  const renderFormUpdateEvent = (
    <>
      <div align="center">
        <h2>
          <b>Lengkapi Informasi Pesanan</b>
        </h2>
      </div>
      <h3>Pembayaran</h3>
      <hr/>
      <MyAutocomplete
        options={optionsPembayaran}
        fullWidth
        size="medium"
        label="Metode Pembayaran"
        id="id_metode_pembayaran"
        name="id_metode_pembayaran"
        value={formikUpdateEvent.values.id_metode_pembayaran || null}
        onChange={(e, value) => {
          formikUpdateEvent.setFieldValue("id_metode_pembayaran", value);
        }}
        
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikUpdateEvent.touched.id_metode_pembayaran && formikUpdateEvent.errors.id_metode_pembayaran}
      </FormHelperText>
      <Gap height={20} />
      <MyInput
        id="waktu_transfer"
        name="waktu_transfer"
        label="Waktu Transfer"
        type="datetime-local"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.waktu_transfer}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.waktu_transfer && Boolean(formikUpdateEvent.errors.waktu_transfer)}
        helperText={formikUpdateEvent.touched.waktu_transfer && formikUpdateEvent.errors.waktu_transfer}
      />
      <Gap height={20} />
      <MyInput
        id="nominal"
        name="nominal"
        label="Nominal Transfer (Rp)"
        type="number"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.nominal}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.nominal && Boolean(formikUpdateEvent.errors.nominal)}
        helperText={formikUpdateEvent.touched.nominal && formikUpdateEvent.errors.nominal}
      />
      <Gap height={20} />
      <MyInput
        id="diskon"
        name="diskon"
        label="Potongan Harga (Rp)"
        type="number"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.diskon}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.diskon && Boolean(formikUpdateEvent.errors.diskon)}
        helperText={formikUpdateEvent.touched.diskon && formikUpdateEvent.errors.diskon}
      />
      <Gap height={40} />

      <h3>Pembayaran</h3>
      <hr/>
      <MyAutocomplete
        options={optionsPengiriman}
        fullWidth
        size="medium"
        label="Metode Pengiriman"
        id="id_metode_pengiriman"
        name="id_metode_pengiriman"
        value={formikUpdateEvent.values.id_metode_pengiriman || null}
        onChange={(e, value) => {
          formikUpdateEvent.setFieldValue("id_metode_pengiriman", value);
        }}
        
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikUpdateEvent.touched.id_metode_pengiriman && formikUpdateEvent.errors.id_metode_pengiriman}
      </FormHelperText>
      <Gap height={20} />
      <MyInput
        id="berat"
        name="berat"
        label="Berat Barang (Kg)"
        type="number"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.berat}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.berat && Boolean(formikUpdateEvent.errors.berat)}
        helperText={formikUpdateEvent.touched.berat && formikUpdateEvent.errors.berat}
      />
      <Gap height={20} />
      <MyInput
        id="ongkir"
        name="ongkir"
        label="Nominal Ongkir (Rp)"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.ongkir}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.ongkir && Boolean(formikUpdateEvent.errors.ongkir)}
        helperText={formikUpdateEvent.touched.ongkir && formikUpdateEvent.errors.ongkir}
      />
      <Gap height={20} />
      <MyInput
        id="potongan_ongkir"
        name="potongan_ongkir"
        label="Potongan Ongkir (Rp)"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.potongan_ongkir}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.potongan_ongkir && Boolean(formikUpdateEvent.errors.potongan_ongkir)}
        helperText={formikUpdateEvent.touched.potongan_ongkir && formikUpdateEvent.errors.potongan_ongkir}
      />
      <Gap height={20} />
      <MyAutocomplete
        options={[
          {
            id:0,
            label:"Tidak Digabung Pesanan Lain"
          },
          {
            id:1,
            label:"Gabung Pesanan Lain"
          },
        ]}
        fullWidth
        size="medium"
        label="Status Gabung Pesanan Lain"
        id="is_ongkir_gabungan"
        name="is_ongkir_gabungan"
        value={formikUpdateEvent.values.is_ongkir_gabungan || null}
        onChange={(e, value) => {
          formikUpdateEvent.setFieldValue("is_ongkir_gabungan", value);
        }}
        
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikUpdateEvent.touched.is_ongkir_gabungan && formikUpdateEvent.errors.is_ongkir_gabungan}
      </FormHelperText>
      <Gap height={40} />

      <h3>Status Pesanan</h3>
      <hr/>
      <MyAutocomplete
        options={optionsStatusPesanan}
        fullWidth
        size="medium"
        label="Status Pesanan"
        id="id_status_pesanan"
        name="id_status_pesanan"
        value={formikUpdateEvent.values.id_status_pesanan || null}
        onChange={(e, value) => {
          formikUpdateEvent.setFieldValue("id_status_pesanan", value);
        }}
        
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikUpdateEvent.touched.id_status_pesanan && formikUpdateEvent.errors.id_status_pesanan}
      </FormHelperText>
      <Gap height={20} />
      <MyInput
        id="tanggal_rekap_pembukuan"
        name="tanggal_rekap_pembukuan"
        label="Tanggal Pembukuan Rekap"
        type="date"
        fullWidth
        size="medium"
        value={formikUpdateEvent.values.tanggal_rekap_pembukuan}
        onChange={formikUpdateEvent.handleChange}
        error={formikUpdateEvent.touched.tanggal_rekap_pembukuan && Boolean(formikUpdateEvent.errors.tanggal_rekap_pembukuan)}
        helperText={formikUpdateEvent.touched.tanggal_rekap_pembukuan && formikUpdateEvent.errors.tanggal_rekap_pembukuan}
      />
      <Gap height={20} />
      <Gap height={40} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUpdateEvent}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikUpdateEvent.handleSubmit}
        />
      </div>
    </>
  );

  // update customer
  const formikUpdateCustomer = useFormik({
    initialValues: {
      alamat: dataDetailCustomer?.data?.data?.alamat,
      catatan: dataDetailCustomer?.data?.data?.catatan,
      id_kategori_area: dataDetailCustomer?.data?.data?.id_kategori_area
      ? {
          id: dataDetailCustomer?.data?.data?.id_kategori_area,
          label: dataDetailCustomer?.data?.data?.nama_kategori_area,
        }
      : null,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaUpdateCustomer,
    onSubmit: async (values) => {
      let payload = {
        nama_akun:dataCustomer?.customer?.akun,
        nama_customer:dataCustomer?.customer?.nama,
        alamat: values.alamat,
        catatan: values.catatan,
        id_kategori_area: values.id_kategori_area?.id,
      };
      let resp = await dispatch(updateCustomer(dataDetailCustomer?.data?.data?.id_customer,payload));
      if (resp.type === "PUT_CUSTOMER_SUCCESS") {
        handleCloseDialogUpdateCustomer();
        setOpenDialogAlert(true);
        setMessage(resp.payload.message);
        setStatus("");
        dispatch(getCustomer(dataDetailCustomer?.data?.data?.id_customer))
      } else {
        setOpenDialogAlert(true);
        setMessage(resp.payload.data.message);
        setStatus("error");
      }
    },
  });
  const renderFormUpdateCustomer = (
    <>
      <div align="center">
        <h2>
          <b>Perbaharui Informasi Customer</b>
        </h2>
      </div>
      <MyAutocomplete
        options={optionsKategoriArea}
        fullWidth
        size="medium"
        label="Kategori Area"
        id="id_kategori_area"
        name="id_kategori_area"
        value={formikUpdateCustomer.values.id_kategori_area || null}
        onChange={(e, value) => {
          formikUpdateCustomer.setFieldValue("id_kategori_area", value);
        }}
        
      />
      <FormHelperText id="component-error-text" style={{ color: "red" }}>
        {formikUpdateCustomer.touched.id_kategori_area && formikUpdateCustomer.errors.id_kategori_area}
      </FormHelperText>
      <Gap height={20} />
      <MyInput
        id="alamat"
        name="alamat"
        label="Alamat"
        fullWidth
        size="medium"
        value={formikUpdateCustomer.values.alamat}
        onChange={formikUpdateCustomer.handleChange}
        error={formikUpdateCustomer.touched.alamat && Boolean(formikUpdateCustomer.errors.alamat)}
        helperText={formikUpdateCustomer.touched.alamat && formikUpdateCustomer.errors.alamat}
      />
      <Gap height={20} />
      <MyInput
        id="catatan"
        name="catatan"
        label="Catatan"
        fullWidth
        size="medium"
        value={formikUpdateCustomer.values.catatan}
        onChange={formikUpdateCustomer.handleChange}
        error={formikUpdateCustomer.touched.catatan && Boolean(formikUpdateCustomer.errors.catatan)}
        helperText={formikUpdateCustomer.touched.catatan && formikUpdateCustomer.errors.catatan}
      />
      <Gap height={20} />
      <Gap height={40} />
      <div style={{ display: "flex" }}>
        <MyButton
          height="40px"
          color="#B9B9B9"
          fullWidth
          backgroundColor="#FFFFFFFF"
          borderColor="#B9B9B9"
          backgroundColorHover="#FFFFFFFF"
          variant="outlined"
          children="Batal"
          onClick={handleCloseDialogUpdateCustomer}
        />
        <Gap width={30} />
        <MyButton
          height="40px"
          color="#8B8B8B"
          colorHover="white"
          fullWidth
          backgroundColor="#E8E8E8"
          borderColor="#E8E8E8"
          backgroundColorHover="#F06524"
          variant="outlined"
          children="Simpan"
          type="submit"
          onClick={formikUpdateCustomer.handleSubmit}
        />
      </div>
    </>
  );

  useEffect(async () => {
    dispatch(getAllEventBarang(1, "", "", "desc", 300, params.pesananID));
    dispatch(getEvent(params.eventID));
    let a = await dispatch(getAllEventPesanan(1, parseInt(params?.pesananID), "", "desc", 300, params.eventID));
    // console.log(a)
    if(a){
      a?.payload?.data?.map((item) => {
        if(item.id_rekap === parseInt(params?.pesananID)){
          setCustomer(item)
          dispatch(getCustomer(item?.customer?.id))
        }
        return item
      });
    }
    dispatch(getAllPembayaran(1));
    dispatch(getAllPengiriman(1));
    dispatch(getAllStatusPesanan());
    dispatch(getKategoriArea());
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearch(event.target.value)
    let temp = event.target.value
    var timeout;
    var delay = 500;
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      if(temp === event.target.value){
        dispatch(getAllEventBarang(currentPage, event.target.value, "", "desc", 300, params.pesananID));
      }
    }, delay);
  };

  const breadcrumbs = [
    <Link
      to={"/rekap-admin"}
      style={{ textDecoration: "none", fontWeight: "bold", color: "black", fontSize:'14px' }}
    >
      Rekap Admin
    </Link>,
    <Link
      to={"/rekap-admin/pesanan/"+params.eventID+"/"+params.akunID+"/"+params.waktuID+"/"+params.tanggal}
      style={{ textDecoration: "none", fontWeight: "bold", color: "black", fontSize:'14px' }}
    >
      Rekap Pesanan
    </Link>,
    <Typography key="2" color="text.primary" style={{ color: "#BDBDBD", fontSize:'14px', marginTop:'2px' }}>
      Detail Pesanan
    </Typography>
  ];

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      {/* add new user */}
      <MyDialog
        openDialog={openDialogUser}
        handleCloseDialog={handleCloseDialogUser}
        renderValue={renderFormNewData}
        fullWidth={true}
        maxWidth={"sm"}
      />
      <MyDialog
        openDialog={openDialogInfoEvent}
        handleCloseDialog={handleCloseDialogUpdateEvent}
        renderValue={renderFormUpdateEvent}
        fullWidth={true}
        maxWidth={"sm"}
      />
      <MyDialog
        openDialog={openDialogUpdateCustomer}
        handleCloseDialog={handleCloseDialogUpdateCustomer}
        renderValue={renderFormUpdateCustomer}
        fullWidth={true}
        maxWidth={"sm"}
      />

      {/* alertMessage */}
      <AlertMessage
        openDialog={openDialogAlert}
        message={message}
        status={status}
        handleCloseDialog={() => setOpenDialogAlert(false)}
      />
      <Gap height={40} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <p style={{ fontSize: 28, fontWeight:700,marginTop:0 }}>Detail Pesanan</p>
        </div>
        <div style={{ display: "flex" }}>
        {(!cetak)?
          <MyButton
            startIcon={<PrintIcon />}
            height="40px"
            color="#F06524"
            backgroundColor="#fff"
            borderColor="#F06524"
            backgroundColorHover="lightgray"
            variant="outlined"
            children="Cetak Nota"
            onClick={()=>setCetak(true)}
          />
          :
          <MyButton
            startIcon={<ArrowBackIcon />}
            height="40px"
            color="#F06524"
            backgroundColor="#fff"
            borderColor="#F06524"
            backgroundColorHover="lightgray"
            variant="outlined"
            children="Kembali"
            onClick={()=>setCetak(false)}
          />
        }
        </div>
      </div>

      <div style={(cetak)?{display:"none"}:{}}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <p style={{marginTop:0, fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
                Informasi Customer
                <EditIcon onClick={handleOpenDialogUpdateCustomer} style={{fontSize:"18px",cursor:'pointer'}}/>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Akun Customer</td>
                    <td>: <b>{dataCustomer?.customer?.akun || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Nama Customer</td>
                    <td>: <b>{dataCustomer?.customer?.nama || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Kategori Area</td>
                    <td>: <b>{dataDetailCustomer?.data?.data?.nama_kategori_area || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>: <b>{dataDetailCustomer?.data?.data?.alamat || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Catatan</td>
                    <td>: <b>{dataDetailCustomer?.data?.data?.catatan || "-"}</b></td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <p style={{fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
                Informasi Event Live
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Tanggal Live</td>
                    <td>: <b>{convertDate(dataDetailEvent?.data?.data?.tanggal_live_event) || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Waktu Live</td>
                    <td>: <b>{dataDetailEvent?.data?.data?.waktu_live?.nama || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Waktu Mulai Live</td>
                    <td>: <b>{dataDetailEvent?.data?.data?.waktu_mulai || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Durasi Live</td>
                    <td>: <b>{dataDetailEvent?.data?.data?.durasi_live_jam || "-"} jam</b></td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <p style={{marginTop:0, fontWeight: 700, color: 'rgb(117, 117, 117)'}}>
                Informasi Pesanan
                <EditIcon onClick={handleOpenDialogUpdateEvent} style={{fontSize:"18px",cursor:'pointer'}}/>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Metode Pembayaran</td>
                    <td>: <b>{dataCustomer?.pembayaran?.nama || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Tanggal Transfer</td>
                    <td>: <b>{(dataCustomer?.pembayaran?.waktu_transfer)?convertDateTime(dataCustomer?.pembayaran?.waktu_transfer) : "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Nominal Transfer (Rp)</td>
                    <td>: <b>{dataCustomer?.pembayaran?.nominal?.toLocaleString().replace(/,/g,".",) || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Potongan Harga (Rp)</td>
                    <td>: <b>{dataCustomer?.pembayaran?.diskon?.toLocaleString().replace(/,/g,".",) || "-"}</b></td>
                  </tr>
                  <tr>
                    <td><br/></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Metode Pengiriman</td>
                    <td>: <b>{dataCustomer?.pengiriman?.nama || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Berat Barang (Kg)</td>
                    <td>: <b>{dataCustomer?.pengiriman?.berat?.toLocaleString().replace(/,/g,".",) || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Nominal Ongkir (Rp)</td>
                    <td>: <b>{dataCustomer?.pengiriman?.ongkir?.toLocaleString().replace(/,/g,".",) || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Potongan Ongkir (Rp)</td>
                    <td>: <b>{dataCustomer?.pengiriman?.potongan_ongkir?.toLocaleString().replace(/,/g,".",) || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Status Gabung Pengiriman</td>
                    <td>: <b>{(dataCustomer?.pengiriman?.is_ongkir_gabungan === 1)? "gabung pesanan lain": (dataCustomer?.pengiriman?.is_ongkir_gabungan === 0)? "tidak digabung" : "-"}</b></td>
                  </tr>
                  <tr>
                    <td><br/></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Status Pesanan</td>
                    <td>: <b>{dataCustomer?.status_pesanan?.status || "-"}</b></td>
                  </tr>
                  <tr>
                    <td>Tanggal Pembukuan Rekap</td>
                    <td>: <b>{(dataCustomer?.tanggal_rekap_pembukuan)? convertDate(dataCustomer?.tanggal_rekap_pembukuan) : "-"}</b></td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </div>
            
        {/* Tableee */}
        <Gap height={40} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p style={{ fontSize: 20, fontWeight:700, margin:0,lineHeight:"40px"  }}>Daftar Barang Pesanan</p>
          </div>
          <div style={{ display: "flex" }}>
            {/* <Filters /> */}
            <Gap width={10} />

            <MyInput
              size="small"
              id="search"
              name="search"
              // label="Cari"
              placeholder="Cari"
              onChange={handleSearch}
              style={{
                // width: "70%",
                borderRadius: 5,
                backgroundColor: "white",
                height: 40,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Gap width={10} />
            {(dataCustomer?.status_pesanan?.status === "Keep")?
            <MyButton
              // style={{ marginTop: 25 }}
              startIcon={<AddIcon />}
              height="40px"
              color="white"
              backgroundColor="#F06524"
              borderColor="#F06524"
              backgroundColorHover="#F06524"
              variant="outlined"
              children="Tambah Barang"
              onClick={handleOpenDialogUser}
            />
            :""}
          </div>
        </div>
        <Table 
          search={search}
          dataCustomer={dataCustomer}
        />
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <Pagination
            count={dataAllEventBarang?.data?.total_pages}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePagination}
            className={classes.root}
          />
        </div>
      </div>
      <div style={(!cetak)?{display:"none"}:{}}>
        <Nota 
          dataDetailEvent={dataDetailEvent}
          dataDetailCustomer={dataDetailCustomer}
          dataCustomer={dataCustomer}
        />
      </div>
      <Gap height={60} />
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
