import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL } from "./actionTypes";
import { API } from "../../API/api";
import { LOCAL_STORAGE_KEY } from "../../API/config";

export const login = (payload) => (dispatch) => {
  dispatch({
    type: LOGIN,
  });

  const request = API.login(payload);
  return request.then(
    (response) => dispatch(loginSuccess(response.data)),
    (err) => dispatch(loginFail(err))
  );
};

export const loginSuccess = (data) => (dispatch) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  return dispatch({
    type: LOGIN_SUCCESS,
    payload: data,
  });
};

export const loginFail = (data) => (dispatch) => {
  return dispatch({
    type: LOGIN_FAIL,
    payload: data,
  });
};
