import {
  GET_METODE_PENGIRIMAN,
  GET_METODE_PENGIRIMAN_SUCCESS,
  GET_METODE_PENGIRIMAN_FAIL,
  GET_DETAIL_METODE_PENGIRIMAN,
  GET_DETAIL_METODE_PENGIRIMAN_SUCCESS,
  GET_DETAIL_METODE_PENGIRIMAN_FAIL,
  PUT_METODE_PENGIRIMAN,
  PUT_METODE_PENGIRIMAN_SUCCESS,
  PUT_METODE_PENGIRIMAN_FAIL,
  ADD_METODE_PENGIRIMAN,
  ADD_METODE_PENGIRIMAN_SUCCESS,
  ADD_METODE_PENGIRIMAN_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getAllPengiriman =
  (page, search, sortBy, sortType, filterArea) => (dispatch) => {
    dispatch({
      type: GET_METODE_PENGIRIMAN,
    });

    const request = API.getAllPengiriman(page, search, sortBy, sortType, filterArea);
    return request.then(
      (response) => dispatch(customer(response.data,GET_METODE_PENGIRIMAN_SUCCESS)),
      (err) => dispatch(customer(err,GET_METODE_PENGIRIMAN_FAIL))
    );
  };


export const getPengiriman = (param) => (dispatch) => {
  dispatch({
    type: GET_DETAIL_METODE_PENGIRIMAN,
  });

  const request = API.getPengiriman(param);
  return request.then(
    (response) => dispatch(customer(response.data,GET_DETAIL_METODE_PENGIRIMAN_SUCCESS)),
    (err) => dispatch(customer(err,GET_DETAIL_METODE_PENGIRIMAN_FAIL))
  );
};

// update user
export const updatePengiriman = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_METODE_PENGIRIMAN,
  });

  const request = API.updatePengiriman(id, data);
  return request.then(
    (response) => dispatch(customer(response.data,PUT_METODE_PENGIRIMAN_SUCCESS)),
    (err) => dispatch(customer(err,PUT_METODE_PENGIRIMAN_FAIL))
  );
};

// add new user
export const createPengiriman = (data) => (dispatch) => {
  dispatch({
    type: ADD_METODE_PENGIRIMAN,
  });

  const request = API.createPengiriman(data);
  return request.then(
    (response) => dispatch(customer(response.data,ADD_METODE_PENGIRIMAN_SUCCESS)),
    (err) => dispatch(customer(err,ADD_METODE_PENGIRIMAN_FAIL))
  );
};

export const customer = (data,type) => (dispatch) => {
  return dispatch({
    type: type,
    payload: data,
  });
};
