export const GET_ALL_EVENT_PESANAN = "GET_ALL_EVENT_PESANAN";
export const GET_ALL_EVENT_PESANAN_SUCCESS = "GET_ALL_EVENT_PESANAN_SUCCESS";
export const GET_ALL_EVENT_PESANAN_FAIL = "GET_ALL_EVENT_PESANAN_FAIL";

export const GET_EVENT_PESANAN_DETAIL = "GET_EVENT_PESANAN_DETAIL";
export const GET_EVENT_PESANAN_DETAIL_SUCCESS = "GET_EVENT_PESANAN_DETAIL_SUCCESS";
export const GET_EVENT_PESANAN_DETAIL_FAIL = "GET_EVENT_PESANAN_DETAIL_FAIL";


// put detail event
export const PUT_EVENT_PESANAN = "PUT_EVENT_PESANAN";
export const PUT_EVENT_PESANAN_SUCCESS = "PUT_EVENT_PESANAN_SUCCESS";
export const PUT_EVENT_PESANAN_FAIL = "PUT_EVENT_PESANAN_FAIL";

// add new event
export const ADD_EVENT_PESANAN = "ADD_EVENT_PESANAN";
export const ADD_EVENT_PESANAN_SUCCESS = "ADD_EVENT_PESANAN_SUCCESS";
export const ADD_EVENT_PESANAN_FAIL = "ADD_EVENT_PESANAN_FAIL";

// DELETE new event
export const DELETE_EVENT_PESANAN = "DELETE_EVENT_PESANAN";
export const DELETE_EVENT_PESANAN_SUCCESS = "DELETE_EVENT_PESANAN_SUCCESS";
export const DELETE_EVENT_PESANAN_FAIL = "DELETE_EVENT_PESANAN_FAIL";