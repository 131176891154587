import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_DETAIL_CUSTOMER,
  GET_DETAIL_CUSTOMER_SUCCESS,
  GET_DETAIL_CUSTOMER_FAIL,
  PUT_CUSTOMER,
  PUT_CUSTOMER_SUCCESS,
  PUT_CUSTOMER_FAIL,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
} from "./actionTypes";

const initialState = {
  dataCustomer: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailCustomer: {
    loading: false,
    error: false,
    data: null,
  },
  updateCustomer: {
    loading: false,
    error: false,
    data: null,
  },
  addCustomer: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_CUSTOMER:
      return {
        ...state,
        dataCustomer: {
          ...state.dataCustomer,
          loading: true,
          error: false,
        },
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataCustomer: {
          ...state.dataCustomer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_CUSTOMER_FAIL:
      return {
        ...state,
        dataCustomer: {
          ...state.dataCustomer,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_DETAIL_CUSTOMER:
      return {
        ...state,
        dataDetailCustomer: {
          ...state.dataDetailCustomer,
          loading: true,
          error: false,
        },
      };
    case GET_DETAIL_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataDetailCustomer: {
          ...state.dataDetailCustomer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_DETAIL_CUSTOMER_FAIL:
      return {
        ...state,
        dataDetailCustomer: {
          ...state.dataDetailCustomer,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_CUSTOMER:
      return {
        ...state,
        updateCustomer: {
          ...state.updateCustomer,
          loading: true,
          error: false,
        },
      };
    case PUT_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateCustomer: {
          ...state.updateCustomer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_CUSTOMER_FAIL:
      return {
        ...state,
        updateCustomer: {
          ...state.updateCustomer,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_CUSTOMER:
      return {
        ...state,
        addCustomer: {
          ...state.addCustomer,
          loading: true,
          error: false,
        },
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        addCustomer: {
          ...state.addCustomer,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        addCustomer: {
          ...state.addCustomer,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
