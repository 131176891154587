import React, { useEffect } from "react";
import { Gap, MyInput} from "../../atoms";
import LiveEvent from "./live-event";
import Rekap from "./rekap";
import RekapKategori from "./rekap-kategori";
import {
  getLaporanLiveEvent,
  getLaporanRekap,
} from "../../../config/Redux/Laporan/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Grid,Paper } from "@mui/material";

const UserManagement = () => {
  const dispatch = useDispatch();
  const convertDate = (tanggal) => {
    let today = new Date(tanggal);
    let date = today.getFullYear()+'-'+("0" + (today.getMonth()+1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2)
    return date
  }
  const [searchDate, setSearchDate] = React.useState(convertDate(new Date()));
  const [dateType, setDateType] = React.useState("ytd");
  const [kategoriVisual, setKategoriVisual] = React.useState("overall");
  // const [statusPesanan, setStatusPesanan] = React.useState("1");
  // const [value, setValue] = React.useState(0);
  const handleFilterDate = (event) =>{
    setSearchDate(event.target.value)
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    if(page === "rekap-live"){
      dispatch(getLaporanLiveEvent(dateType,event.target.value,kategoriVisual));
    } else if(page === "sales"){
      dispatch(getLaporanRekap(dateType,event.target.value,kategoriVisual,"1"));
    } else if(page === "cancel"){
      dispatch(getLaporanRekap(dateType,event.target.value,kategoriVisual,"0"));
    }
    // dispatch(getLaporanLiveEvent(dateType,event.target.value,kategoriVisual));
    // dispatch(getLaporanRekap(dateType,event.target.value,kategoriVisual,statusPesanan));
  }
  const handleDateType = (event) =>{
    setDateType(event)
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    if(page === "rekap-live"){
      dispatch(getLaporanLiveEvent(event,searchDate,kategoriVisual));
    } else if(page === "sales"){
      dispatch(getLaporanRekap(event,searchDate,kategoriVisual,"1"));
    } else if(page === "cancel"){
      dispatch(getLaporanRekap(event,searchDate,kategoriVisual,"0"));
    }
    // dispatch(getLaporanLiveEvent(event,searchDate,kategoriVisual));
    // dispatch(getLaporanRekap(event,searchDate,kategoriVisual,statusPesanan));
  }
  const handleKategoriVisual = (event) =>{
    setKategoriVisual(event.target.value)
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    if(page === "rekap-live"){
      dispatch(getLaporanLiveEvent(dateType,searchDate,event.target.value));
    } else if(page === "sales"){
      dispatch(getLaporanRekap(dateType,searchDate,event.target.value,"1"));
    } else if(page === "cancel"){
      dispatch(getLaporanRekap(dateType,searchDate,event.target.value,"0"));
    }
    // dispatch(getLaporanLiveEvent(dateType,searchDate,event.target.value));
    // dispatch(getLaporanRekap(dateType,searchDate,event.target.value,statusPesanan));
  }
  const handleOnClick = (page) =>{
    if(page === "rekap-live"){
      dispatch(getLaporanLiveEvent(dateType,searchDate,kategoriVisual));
    } else if(page === "sales"){
      dispatch(getLaporanRekap(dateType,searchDate,kategoriVisual,"1"));
    } else if(page === "cancel"){
      dispatch(getLaporanRekap(dateType,searchDate,kategoriVisual,"0"));
    }
  }
  const checkParam = () =>{
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    return page
  }
  // const handleStatusPesanan = (event) =>{
  //   setStatusPesanan(event.target.value)
  //   // dispatch(getAllEventLast(event.target.value));
  // }
  useEffect(() => {
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    if(page === "rekap-live"){
      dispatch(getLaporanLiveEvent(dateType,searchDate,kategoriVisual));
    } else if(page === "sales"){
      dispatch(getLaporanRekap(dateType,searchDate,kategoriVisual,"1"));
    } else if(page === "cancel"){
      dispatch(getLaporanRekap(dateType,searchDate,kategoriVisual,"0"));
    }

    // dispatch(getLaporanLiveEvent(dateType,searchDate,kategoriVisual));
    // dispatch(getLaporanRekap(dateType,searchDate,kategoriVisual,statusPesanan));
  }, [dispatch]);
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Gap height={20} />
      <div>
        <p style={{ fontSize: 28, fontWeight:700 }}>{(checkParam())?<Link to="/laporan">Laporan</Link>:"Laporan"} {(checkParam() === "cancel")?" - Pesanan Dibatalkan":(checkParam() === "sales")?" - Penjualan":(checkParam() === "rekap-live")?" - Rekap Live":""}</p>
      </div>
      {(checkParam() === "sales" || checkParam() === "rekap-live" || checkParam() === "cancel")?
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={6}>
          <div style={{textAlign:'left'}}>
            <div className="filter-tabel-exco-content">
              <p style={{margin:'0px'}}>Kategori Visual</p>
              <select onChange={handleKategoriVisual} value={kategoriVisual}>
                <option value={"overall"}>{"Overall"}</option>
                <option value={"reviewer"}>{"Reviewer"}</option>
                {(checkParam() === "cancel" || checkParam() === "rekap-live" )?null:<option value={"pembayaran"}>{"Metode Pembayaran"}</option>}
                {(checkParam() === "cancel" || checkParam() === "rekap-live" )?null:<option value={"pengiriman"}>{"Metode Pengiriman"}</option>}
                <option value={"kategori_area"}>{"Kategori Area"}</option>
                <option value={"akun_live"}>{"Akun Live"}</option>
              </select>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <div style={{float:'right'}}>
            <div style={{ display: "flex" }}>
              <div style={{height:'30px', margin:'5px 0px', textAlign:'right'}}>
                <div className="switch-button" style={(dateType==="mtd")?{backgroundColor:'#04AA6D', color:'white'}:{}} onClick={()=>{handleDateType("mtd")}}>Harian</div>
                <div className="switch-button" style={(dateType==="ytd")?{backgroundColor:'#04AA6D', color:'white'}:{}} onClick={()=>{handleDateType("ytd")}}>Bulanan</div>
              </div>
              <Gap width={10} />
              <MyInput
                size="small"
                id="search"
                name="search"
                type="date"
                placeholder="Cari"
                value={searchDate}
                onChange={handleFilterDate}
                style={{
                  // width: "70%",
                  borderRadius: 5,
                  backgroundColor: "white",
                  height: 40,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      :null}
      <Gap height={10} />
      {(checkParam() === "sales" || checkParam() === "rekap-live" || checkParam() === "cancel")?
      null:
      <div>
        <Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6}>
            <Link to="/laporan?report=rekap-live" className="boxhead" onClick={()=>handleOnClick("rekap-live")}>
            {/* <Link to="/laporan" className="boxhead"> */}
              <Paper className="overview-paper">
                <h3 className="overview-header">Laporan <br/> Live</h3>
              </Paper>
            </Link>
					</Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Link to="/laporan?report=sales" className="boxhead" onClick={()=>handleOnClick("sales")}>
              <Paper className="overview-paper">
                <h3 className="overview-header">Laporan <br/> Penjualan</h3>
              </Paper>
            </Link>
					</Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Link to="/laporan?report=cancel" className="boxhead" onClick={()=>handleOnClick("cancel")}>
              <Paper className="overview-paper">
                <h3 className="overview-header">Laporan <br/> Pesanan Dibatalkan / Tidak Dapat Dihubungi</h3>
              </Paper>
            </Link>
					</Grid>
          {/* <Grid item xs={12} sm={6} md={6}>
            <Link to="/laporan?report=cancel" className="boxhead" onClick={()=>handleOnClick("cancel")}>
              <Paper className="overview-paper">
                <h3 className="overview-header">Daftar <br/>Barang Tidak Jadi Dibeli</h3>
              </Paper>
            </Link>
					</Grid> */}
				</Grid>
      </div>
      }
      <Gap height={10} />
      {(checkParam() === "cancel" || checkParam() === "sales")?
      <div>
        {(kategoriVisual === "overall")?
        <Rekap dateType={dateType} kategoriVisual={kategoriVisual} searchDate={searchDate}/>:
        <RekapKategori dateType={dateType} kategoriVisual={kategoriVisual} searchDate={searchDate}/>
        }
      </div>
      :""}
      {(checkParam() === "rekap-live")?
      <div>
        {(kategoriVisual === "overall")?
        <Rekap dateType={dateType} kategoriVisual={kategoriVisual} searchDate={searchDate}/>:
        <RekapKategori dateType={dateType} kategoriVisual={kategoriVisual} searchDate={searchDate}/>
        }
      </div>
      :""}
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
