import React, { useEffect } from "react";
import { Gap, MyInput} from "../../atoms";
import { Grid,Paper } from "@mui/material";
import {
  getLaporanLiveEvent,
  getLaporanRekap,
} from "../../../config/Redux/Laporan/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

let months = ['Jan','Feb','Mar','Apr', 'Mei','Jun',
                'Jul','Agu','Sep','Okt','Nov','Des'];
const UserManagement = (params) => {
  const dataLaporanLiveEvent = useSelector((state) => state.laporan.dataLaporanLiveEvent);
  	const convertDateMonth = (date) => {
		var local = new Date(date);
		return local.getDate() + ' ' + (months[local.getMonth()]) + ' ' +  local.getFullYear();
	}
  const dataChartLabel = (datas) => {
		let val = [];
		if(datas){
			datas.sort((a, b) => a.tanggal > b.tanggal ? 1 : -1);
			datas.forEach((data) => {
				let temp = ""
				if(params.dateType==="ytd"){
					temp=months[data.tanggal-1]
				} else {
					temp=convertDateMonth(data.tanggal)
				}
				val.push(temp)
			});
		}
		return val
	}
  const dataChartDataset = (datas) => {
		let val = [];
		if(datas){
			datas.sort((a, b) => a.tanggal > b.tanggal ? 1 : -1);
			datas.forEach((data) => {
				val.push(data?.data["overall"].jumlah_harga_barang)
			});
		}
		return val
	}
  const labels = dataChartLabel(dataLaporanLiveEvent?.data?.data);
  const data = {
		labels,
		datasets: [
			{
				label: 'Jumlah Harga Barang',
				data: dataChartDataset(dataLaporanLiveEvent?.data?.data),
				borderColor: '#04AA6D',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
		],
  };
  const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display:false
			},
			title: {
				display: true,
				text: '',
			},
		},
  };
  return (
    <div>
      <Paper className="overview-paper-secondary">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h3 className="overview-header">Laporan Event Live</h3>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							{/* <div className="overview-filter">
								<div className="overview-toggle">
									<div className={(toggle==="jam")?"active":""} onClick={() => setToggle('jam')}>Per Jam</div>
									<div className={(toggle==="hari")?"active":""} onClick={() => setToggle('hari')}>Harian</div>
								</div>
								<div className="overview-datepicker">
									<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
								</div>
							</div> */}
							<Line options={options} data={data} height="270px" width="100%"/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							<table className="tabel-laporan" style={{width:"100%"}}>
								<thead>
									<tr>
										<th style={{textAlign:"center"}}>{(params.dateType==="ytd")?"Bulan":"Tanggal"}</th>
										<th style={{textAlign:"center"}}>Jumlah Pesanan</th>
										<th style={{textAlign:"center"}}>Jumlah Barang</th>
										<th style={{textAlign:"center"}}>Jumlah Harga Barang</th>
									</tr>
								</thead>
								<tbody>
									{dataLaporanLiveEvent?.data?.data?.map((list,idx)=>{
										return(<tr key={idx}>
											<td  style={{textAlign:"center"}}>{(params.dateType==="ytd")?months[list?.tanggal-1]:convertDateMonth(list?.tanggal)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_pesanan?.toLocaleString().replace(/,/g,".",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_barang?.toLocaleString().replace(/,/g,".",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_harga_barang?.toLocaleString().replace(/,/g,".",)}</td>
										</tr>)
									})}
								</tbody>
							</table>
						</div>
					</Grid>
				</Grid>
			</Paper>
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
