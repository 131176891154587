import React, { useEffect } from "react";
import { Grid,Paper } from "@mui/material";
import {
  getLaporanLiveEvent,
  getLaporanRekap,
} from "../../../config/Redux/Laporan/actions";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
	BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let months = ['Jan','Feb','Mar','Apr', 'Mei','Jun',
                'Jul','Agu','Sep','Okt','Nov','Des'];
let kategori = {
	"overall":"Overall",
	"reviewer":"Reviewer",
	"pembayaran":"Metode Pembayaran",
	"pengiriman":"Metode Pengiriman",
	"kategori_area":"Kategori Area",
  "akun_live":"Akun Live"
}
const UserManagement = (params) => {
	// const [dataKategori, setDataKategori] = React.useState([]);
	const [jumlahPesanan, setJumlahPesanan] = React.useState(0);
	const [jumlahBarang, setJumlahBarang] = React.useState(0);
	const [jumlahHarga, setJumlahHarga] = React.useState(0);
  const dataLaporanRekap = useSelector((state) => state.laporan.dataLaporanRekap);
	const dataLaporanLiveEvent = useSelector((state) => state.laporan.dataLaporanLiveEvent);
  const convertDateMonth = (date) => {
		var local = new Date(date);
		return local.getDate() + ' ' + (months[local.getMonth()]) + ' ' +  local.getFullYear();
	}
	const convertMonth = (date) => {
		var local = new Date(date);
		return (months[local.getMonth()]);
	}
	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	}
	let dataKategori = []
	let jmlBrg = 0;
	let jmlPsnan = 0;
	let jmlHrg = 0;
	const dataGeneral = (datas) => {
		let val = [];
		let key = [];
		if(datas){
			datas.forEach((data) => {
				let listKey = Object.keys(data?.data);
				listKey?.forEach((list) => {
					key.push(list)
				})
			});
			key = key.filter(onlyUnique);
		}
		if(datas){
			key.forEach((dat) => {
				let temp = {
					name:dat,
					jumlah_barang:0,
					jumlah_diskon:0,
					jumlah_harga_barang:0,
					jumlah_nominal_transfer:0,
					jumlah_ongkir:0,
					jumlah_pesanan:0,
					jumlah_potongan_ongkir:0,
				};
				datas.forEach((list) => {
					if(list?.data[dat]){
						temp.jumlah_barang = temp.jumlah_barang + list?.data[dat]?.jumlah_barang
						temp.jumlah_diskon = temp.jumlah_diskon + list?.data[dat]?.jumlah_diskon
						temp.jumlah_harga_barang = temp.jumlah_harga_barang + list?.data[dat]?.jumlah_harga_barang
						temp.jumlah_nominal_transfer = temp.jumlah_nominal_transfer + list?.data[dat]?.jumlah_nominal_transfer
						temp.jumlah_ongkir = temp.jumlah_ongkir + list?.data[dat]?.jumlah_ongkir
						temp.jumlah_pesanan = temp.jumlah_pesanan + list?.data[dat]?.jumlah_pesanan
						temp.jumlah_potongan_ongkir = temp.jumlah_potongan_ongkir + list?.data[dat]?.jumlah_potongan_ongkir
					}
				});
				jmlBrg = jmlBrg + temp.jumlah_barang;
				jmlPsnan = jmlPsnan + temp.jumlah_pesanan;
				jmlHrg = jmlHrg + temp.jumlah_harga_barang;
				val.push(temp)
			});
			// setJumlahBarang(jmlBrg)
			// setJumlahPesanan(jmlPsnan)
			// setJumlahHarga(jmlHrg)
		}
		val.sort((a, b) => a.jumlah_harga_barang < b.jumlah_harga_barang ? 1 : -1);
		dataKategori = val
		// setDataKategori(val)
	}

	const checkParam = () =>{
    var url = new URL(window.location.href);
    var page = url.searchParams.get("report");
    return page
  }
	dataGeneral((checkParam()==="rekap-live")?dataLaporanLiveEvent?.data?.data:dataLaporanRekap?.data?.data)

	
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom',
			},
			title: {
				display: true,
				text: (checkParam()==="sales")?"Penjualan Per " + kategori[params.kategoriVisual]:"Pesanan Dibatalkan Per " + kategori[params.kategoriVisual],
			},
		},
	};

	const convertDataset = (datas, value) => {
		let temp = [];
		datas.forEach((list) => {
			temp.push(list[value])
		})
		return temp
	}

	const labels = convertDataset(dataKategori,"name");

	let data = {
		labels,
		datasets: [
			{
				label: 'Jumlah Harga Barang',
				data: convertDataset(dataKategori,"jumlah_harga_barang"),
				borderColor: '#04AA6D',
				backgroundColor: 'rgba(4, 170, 109, 0.5)',
			},
		],
	};



  return (
    <div>
      <Paper className="overview-paper-secondary">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<h3 className="overview-header">Rekap Laporan {(checkParam()==="rekap-live")?"Pesanan Saat Live":(checkParam()==="sales")?"Panjualan":"Pesanan Dibatalkan"}</h3>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4} md={4}>
						<div className="overview-paper-card">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12} style={{textAlign:'center'}}>
									<span style={{fontWeight:'bold',color:'#8d8d8d', fontSize:'18px'}}>{jmlPsnan?.toLocaleString().replace(/,/g,",",)}</span><br/>
									<span style={{color:'#8d8d8d', fontSize:'12px'}}>Jumlah Pesanan</span>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={4}>
						<div className="overview-paper-card">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12} style={{textAlign:'center'}}>
									<span style={{fontWeight:'bold',color:'#8d8d8d', fontSize:'18px'}}>{jmlBrg?.toLocaleString().replace(/,/g,",",)}</span><br/>
									<span style={{color:'#8d8d8d', fontSize:'12px'}}>Jumlah Barang</span>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={4}>
						<div className="overview-paper-card">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12} style={{textAlign:'center'}}>
									<span style={{fontWeight:'bold',color:'#8d8d8d', fontSize:'18px'}}>{jmlHrg?.toLocaleString().replace(/,/g,",",)}</span><br/>
									<span style={{color:'#8d8d8d', fontSize:'12px'}}>Jumlah Harga Barang</span>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							<Bar options={options} data={data} />
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6} style={{textAlign:'left',marginBottom:'12px'}}>
									<span style={{fontWeight:'bold',color:'#8d8d8d'}}>Daftar {(checkParam()==="rekap-live")?"Pesanan Saat Live":(checkParam()==="sales")?"Panjualan":"Pesanan Dibatalkan"} Per {kategori[params.kategoriVisual]}</span>
								</Grid>
								<Grid item xs={12} sm={6} md={6} style={{textAlign:'right'}}>
									<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename={(checkParam()==="rekap-live")?"Pesanan Saat Live "+ kategori[params.kategoriVisual] + " " + params.dateType + " " + params.searchDate:(checkParam()==="sales")?"Penjualan " + kategori[params.kategoriVisual] + " " + params.dateType + " " + params.searchDate:"Pesanan Dibatalkan " + kategori[params.kategoriVisual] + " " + params.dateType + " " + params.searchDate}
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
								</Grid>
							</Grid>
							<table className="tabel-laporan" style={{width:"100%"}} id="table-to-xls"> 
								<thead>
									<tr>
										<th style={{textAlign:"center"}}>No.</th>
										<th style={{textAlign:"center"}}>Nama</th>
										<th style={{textAlign:"center"}}>Jumlah Pesanan</th>
										<th style={{textAlign:"center"}}>Jumlah Barang</th>
										<th style={{textAlign:"center"}}>Jumlah Harga Barang</th>
										{/* <th style={{textAlign:"center"}}>Jumlah Diskon</th>
										<th style={{textAlign:"center"}}>Jumlah Nominal Ongkir</th>
										<th style={{textAlign:"center"}}>Jumlah Potongan Ongkir</th>
										<th style={{textAlign:"center"}}>Jumlah Nominal Transfer</th> */}
									</tr>
								</thead>
								<tbody>
									{dataKategori?.map((list,idx)=>{
										return(<tr key={idx}>
											<td style={{textAlign:"left"}}>{idx+1}</td>
											<td style={{textAlign:"left"}}>{list?.name}</td>
											<td style={{textAlign:"right"}}>{list.jumlah_pesanan?.toLocaleString().replace(/,/g,",",)}</td>
											<td style={{textAlign:"right"}}>{list.jumlah_barang?.toLocaleString().replace(/,/g,",",)}</td>
											<td style={{textAlign:"right"}}>{list.jumlah_harga_barang?.toLocaleString().replace(/,/g,",",)}</td>
										</tr>)
									})}
								</tbody>
							</table>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<div className="overview-paper-card">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6} style={{textAlign:'left',marginBottom:'12px'}}>
									<span style={{fontWeight:'bold',color:'#8d8d8d'}}>Daftar Riwayat {(checkParam()==="rekap-live")?"Pesanan Saat Live":(checkParam()==="sales")?"Panjualan":"Pesanan Dibatalkan"} Per {kategori[params.kategoriVisual]}</span>
								</Grid>
								<Grid item xs={12} sm={6} md={6} style={{textAlign:'right'}}>
									<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls-2"
                    filename={(checkParam()==="rekap-live")?"Riwayat Saat Live "+ kategori[params.kategoriVisual] + " " + params.dateType + " " + params.searchDate:(checkParam()==="sales")?"Riwayat Penjualan " + kategori[params.kategoriVisual] + " " + params.dateType + " " + params.searchDate:"Riwayat Pesanan Dibatalkan " + kategori[params.kategoriVisual] + " " + params.dateType + " " + params.searchDate} 
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
								</Grid>
							</Grid>
							<table className="tabel-laporan" style={{width:"100%"}} id="table-to-xls-2">
								<thead>
									<tr>
										<th style={{textAlign:"center"}}>{(params.dateType==="ytd")?"Bulan":"Tanggal"}</th>
										<th style={{textAlign:"center"}}>Jumlah Pesanan</th>
										<th style={{textAlign:"center"}}>Jumlah Barang</th>
										<th style={{textAlign:"center"}}>Jumlah Harga Barang</th>
										{/* <th style={{textAlign:"center"}}>Jumlah Diskon</th>
										<th style={{textAlign:"center"}}>Jumlah Nominal Ongkir</th>
										<th style={{textAlign:"center"}}>Jumlah Potongan Ongkir</th>
										<th style={{textAlign:"center"}}>Jumlah Nominal Transfer</th> */}
									</tr>
								</thead>
								<tbody>
									{((checkParam()==="rekap-live")?dataLaporanLiveEvent?.data?.data:dataLaporanRekap?.data?.data)?.map((list,idx)=>{
										let listKey = Object.keys(list?.data);
										return(<tr key={idx}>
											<td  style={{textAlign:"left"}}>{(params.dateType==="ytd")?months[list?.tanggal-1]:convertDateMonth(list?.tanggal)}</td>
											<td  style={{textAlign:"left"}}>
												{listKey?.map((daftar,index)=>{
													return (
														<div key={index}>
															<span style={{fontSize:'11px'}}>{daftar} : </span>
															<span style={{fontWeight:'bold', fontSize:'11px'}}>{list?.data[daftar]?.jumlah_pesanan?.toLocaleString().replace(/,/g,",",)}</span>
														</div>
													)
												})}
											</td>
											<td  style={{textAlign:"left"}}>
												{listKey?.map((daftar,index)=>{
													return (
														<div key={index}>
															<span style={{fontSize:'11px'}}>{daftar} : </span>
															<span style={{fontWeight:'bold', fontSize:'11px'}}>{list?.data[daftar]?.jumlah_barang?.toLocaleString().replace(/,/g,",",)}</span>
														</div>
													)
												})}
											</td>
											<td  style={{textAlign:"left"}}>
												{listKey?.map((daftar,index)=>{
													return (
														<div key={index}>
															<span style={{fontSize:'11px'}}>{daftar} : </span>
															<span style={{fontWeight:'bold', fontSize:'11px'}}>{list?.data[daftar]?.jumlah_harga_barang?.toLocaleString().replace(/,/g,",",)}</span>
														</div>
													)
												})}
											</td>
											{/* <td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_barang?.toLocaleString().replace(/,/g,",",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_harga_barang?.toLocaleString().replace(/,/g,",",)}</td> */}
											{/* <td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_diskon?.toLocaleString().replace(/,/g,",",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_ongkir?.toLocaleString().replace(/,/g,",",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_potongan_ongkir?.toLocaleString().replace(/,/g,",",)}</td>
											<td  style={{textAlign:"right"}}>{list?.data["overall"]?.jumlah_nominal_transfer?.toLocaleString().replace(/,/g,",",)}</td> */}
										</tr>)
									})}
								</tbody>
							</table>
						</div>
					</Grid>
				</Grid>
			</Paper>
      <div className="footer-content"></div>
    </div>
  );
};

export default UserManagement;
