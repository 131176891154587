import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  GET_WAKTU_LIVE,
  GET_WAKTU_LIVE_SUCCESS,
  GET_WAKTU_LIVE_FAIL,
  GET_AKUN_LIVE,
  GET_AKUN_LIVE_SUCCESS,
  GET_AKUN_LIVE_FAIL,
  GET_KATEGORI_AREA,
  GET_KATEGORI_AREA_SUCCESS,
  GET_KATEGORI_AREA_FAIL,
  GET_KATEGORI_PENGIRIMAN,
  GET_KATEGORI_PENGIRIMAN_SUCCESS,
  GET_KATEGORI_PENGIRIMAN_FAIL,
  GET_KATEGORI_PEMBAYARAN,
  GET_KATEGORI_PEMBAYARAN_SUCCESS,
  GET_KATEGORI_PEMBAYARAN_FAIL,
  GET_STATUS_PESANAN,
  GET_STATUS_PESANAN_SUCCESS,
  GET_STATUS_PESANAN_FAIL,
} from "./actionTypes";

const initialState = {
  dataProfile: {
    loading: false,
    error: false,
    data: null,
  },
  changePassword: {
    loading: false,
    error: false,
    data: null,
  },
  dataWaktuLive:{
    loading: false,
    error: false,
    data: null,
  },
  dataAkunLive:{
    loading: false,
    error: false,
    data: null,
  },
  dataKategoriArea:{
    loading: false,
    error: false,
    data: null,
  },
  dataKategoriPembayaran:{
    loading: false,
    error: false,
    data: null,
  },
  dataKategoriPengiriman:{
    loading: false,
    error: false,
    data: null,
  },
  dataStatusPesanan:{
    loading: false,
    error: false,
    data: null,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get profile
    case GET_PROFILE:
      return {
        ...state,
        dataProfile: {
          ...state.dataProfile,
          loading: true,
          error: false,
        },
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        dataProfile: {
          ...state.dataProfile,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        dataProfile: {
          ...state.dataProfile,
          loading: false,
          error: action.payload,
        },
      };
    // change password
    case UPDATE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: true,
          error: false,
        },
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: action.payload,
        },
      };
    // get waktu live
    case GET_WAKTU_LIVE:
      return {
        ...state,
        dataWaktuLive: {
          ...state.dataWaktuLive,
          loading: true,
          error: false,
        },
      };
    case GET_WAKTU_LIVE_SUCCESS:
      return {
        ...state,
        dataWaktuLive: {
          ...state.dataWaktuLive,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_WAKTU_LIVE_FAIL:
      return {
        ...state,
        dataWaktuLive: {
          ...state.dataWaktuLive,
          loading: false,
          error: action.payload,
        },
      };
    // get akun live
    case GET_AKUN_LIVE:
      return {
        ...state,
        dataAkunLive: {
          ...state.dataAkunLive,
          loading: true,
          error: false,
        },
      };
    case GET_AKUN_LIVE_SUCCESS:
      return {
        ...state,
        dataAkunLive: {
          ...state.dataAkunLive,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_AKUN_LIVE_FAIL:
      return {
        ...state,
        dataAkunLive: {
          ...state.dataAkunLive,
          loading: false,
          error: action.payload,
        },
      };
    // get kategori area
    case GET_KATEGORI_AREA:
      return {
        ...state,
        dataKategoriArea: {
          ...state.dataKategoriArea,
          loading: true,
          error: false,
        },
      };
    case GET_KATEGORI_AREA_SUCCESS:
      return {
        ...state,
        dataKategoriArea: {
          ...state.dataKategoriArea,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_KATEGORI_AREA_FAIL:
      return {
        ...state,
        dataKategoriArea: {
          ...state.dataKategoriArea,
          loading: false,
          error: action.payload,
        },
      };
    // get kategori pembayaran
    case GET_KATEGORI_PEMBAYARAN:
      return {
        ...state,
        dataKategoriPembayaran: {
          ...state.dataKategoriPembayaran,
          loading: true,
          error: false,
        },
      };
    case GET_KATEGORI_PEMBAYARAN_SUCCESS:
      return {
        ...state,
        dataKategoriPembayaran: {
          ...state.dataKategoriPembayaran,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_KATEGORI_PEMBAYARAN_FAIL:
      return {
        ...state,
        dataKategoriPembayaran: {
          ...state.dataKategoriPembayaran,
          loading: false,
          error: action.payload,
        },
      };
    // get kategori pengiriman
    case GET_KATEGORI_PENGIRIMAN:
      return {
        ...state,
        dataKategoriPengiriman: {
          ...state.dataKategoriPengiriman,
          loading: true,
          error: false,
        },
      };
    case GET_KATEGORI_PENGIRIMAN_SUCCESS:
      return {
        ...state,
        dataKategoriPengiriman: {
          ...state.dataKategoriPengiriman,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_KATEGORI_PENGIRIMAN_FAIL:
      return {
        ...state,
        dataKategoriPengiriman: {
          ...state.dataKategoriPengiriman,
          loading: false,
          error: action.payload,
        },
      };
    // get status pesanan
    case GET_STATUS_PESANAN:
      return {
        ...state,
        dataStatusPesanan: {
          ...state.dataStatusPesanan,
          loading: true,
          error: false,
        },
      };
    case GET_STATUS_PESANAN_SUCCESS:
      return {
        ...state,
        dataStatusPesanan: {
          ...state.dataStatusPesanan,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_STATUS_PESANAN_FAIL:
      return {
        ...state,
        dataStatusPesanan: {
          ...state.dataStatusPesanan,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
