import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_DETAIL_USER,
  GET_DETAIL_USER_SUCCESS,
  GET_DETAIL_USER_FAIL,
  PUT_USERS,
  PUT_USERS_SUCCESS,
  PUT_USERS_FAIL,
  CHANGE_PASSWORD_USER,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_FAIL,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  GET_HAK_AKSES,
  GET_HAK_AKSES_SUCCESS,
  GET_HAK_AKSES_FAIL,
  POST_HAK_AKSES,
  POST_HAK_AKSES_SUCCESS,
  POST_HAK_AKSES_FAIL,
  POST_HAK_AKUN,
  POST_HAK_AKUN_SUCCESS,
  POST_HAK_AKUN_FAIL,
} from "./actionTypes";
import { API } from "../../API/api";

// get users
export const getUsers =
  (page, search, sortBy, sortType) => (dispatch) => {
    dispatch({
      type: GET_USERS,
    });

    const request = API.getUsers(page, search, sortBy, sortType);
    return request.then(
      (response) => dispatch(getUsersSuccess(response.data)),
      (err) => dispatch(getUsersFail(err))
    );
  };

export const getUsersSuccess = (data) => (dispatch) => {
  return dispatch({
    type: GET_USERS_SUCCESS,
    payload: data,
  });
};

export const getUsersFail = (data) => (dispatch) => {
  return dispatch({
    type: GET_USERS_FAIL,
    payload: data,
  });
};

// get detail user

// get users
export const getDetailUser = (param) => (dispatch) => {
  dispatch({
    type: GET_DETAIL_USER,
  });

  const request = API.getUserManagement(param);
  return request.then(
    (response) => dispatch(getDetailUserSuccess(response.data)),
    (err) => dispatch(getDetailUserFail(err))
  );
};

export const getDetailUserSuccess = (data) => (dispatch) => {
  return dispatch({
    type: GET_DETAIL_USER_SUCCESS,
    payload: data,
  });
};

export const getDetailUserFail = (data) => (dispatch) => {
  return dispatch({
    type: GET_DETAIL_USER_FAIL,
    payload: data,
  });
};

// update user
export const updateUser = (id, data) => (dispatch) => {
  dispatch({
    type: PUT_USERS,
  });

  const request = API.patchUserManagement(id, data);
  return request.then(
    (response) => dispatch(updateUserSuccess(response.data)),
    (err) => dispatch(updateUserFail(err))
  );
};

export const updateUserSuccess = (data) => (dispatch) => {
  return dispatch({
    type: PUT_USERS_SUCCESS,
    payload: data,
  });
};

export const updateUserFail = (data) => (dispatch) => {
  return dispatch({
    type: PUT_USERS_FAIL,
    payload: data,
  });
};

// add new user
export const postNewUser = (data) => (dispatch) => {
  dispatch({
    type: ADD_NEW_USER,
  });

  const request = API.addNewUser(data);
  return request.then(
    (response) => dispatch(addNewUserSuccess(response.data)),
    (err) => dispatch(addNewUserFail(err))
  );
};

export const addNewUserSuccess = (data) => (dispatch) => {
  return dispatch({
    type: ADD_NEW_USER_SUCCESS,
    payload: data,
  });
};

export const addNewUserFail = (data) => (dispatch) => {
  return dispatch({
    type: ADD_NEW_USER_FAIL,
    payload: data,
  });
};

// change password user
export const changePasswordUser = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_USER,
  });

  const request = API.changePasswordUser(data);
  return request.then(
    (response) => dispatch(changePasswordUserSuccess(response.data)),
    (err) => dispatch(changePasswordUserFail(err))
  );
};

export const changePasswordUserSuccess = (data) => (dispatch) => {
  return dispatch({
    type: CHANGE_PASSWORD_USER_SUCCESS,
    payload: data,
  });
};

export const changePasswordUserFail = (data) => (dispatch) => {
  return dispatch({
    type: CHANGE_PASSWORD_USER_FAIL,
    payload: data,
  });
};

// get users
export const getHakAkses = (param) => (dispatch) => {
  dispatch({
    type: GET_HAK_AKSES,
  });

  const request = API.getHakAkses(param);
  return request.then(
    (response) => dispatch(getHakAksesSuccess(response.data)),
    (err) => dispatch(getHakAksesFail(err))
  );
};

export const getHakAksesSuccess = (data) => (dispatch) => {
  return dispatch({
    type: GET_HAK_AKSES_SUCCESS,
    payload: data,
  });
};

export const getHakAksesFail = (data) => (dispatch) => {
  return dispatch({
    type: GET_HAK_AKSES_FAIL,
    payload: data,
  });
};

// update HAK AKSES
export const updateHakAkses = (id_user,id_hak_akses) => (dispatch) => {
  dispatch({
    type: POST_HAK_AKSES,
  });

  const request = API.updateHakAkses(id_user,id_hak_akses);
  return request.then(
    (response) => dispatch(updateHakAksesSuccess(response.data)),
    (err) => dispatch(updateHakAksesFail(err))
  );
};

export const updateHakAksesSuccess = (data) => (dispatch) => {
  return dispatch({
    type: POST_HAK_AKSES_SUCCESS,
    payload: data,
  });
};

export const updateHakAksesFail = (data) => (dispatch) => {
  return dispatch({
    type: POST_HAK_AKSES_FAIL,
    payload: data,
  });
};

// update HAK AKUN
export const updateHakAkun = (id_user,id_hak_akun) => (dispatch) => {
  dispatch({
    type: POST_HAK_AKUN,
  });

  const request = API.updateHakAkun(id_user,id_hak_akun);
  return request.then(
    (response) => dispatch(updateHakAkunSuccess(response.data)),
    (err) => dispatch(updateHakAkunFail(err))
  );
};

export const updateHakAkunSuccess = (data) => (dispatch) => {
  return dispatch({
    type: POST_HAK_AKUN_SUCCESS,
    payload: data,
  });
};

export const updateHakAkunFail = (data) => (dispatch) => {
  return dispatch({
    type: POST_HAK_AKUN_FAIL,
    payload: data,
  });
};