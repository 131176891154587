import React from "react";
import RekapLive from "../../components/organism/RekapLive";

const PageRekapLive = () => {
  return (
    <>
      <RekapLive />
    </>
  );
};

export default PageRekapLive;
