export const GET_AKUN_LIVE = "GET_AKUN_LIVE";
export const GET_AKUN_LIVE_SUCCESS = "GET_AKUN_LIVE_SUCCESS";
export const GET_AKUN_LIVE_FAIL = "GET_AKUN_LIVE_FAIL";

// get detail user
export const GET_DETAIL_AKUN_LIVE = "GET_DETAIL_AKUN_LIVE";
export const GET_DETAIL_AKUN_LIVE_SUCCESS = "GET_DETAIL_AKUN_LIVE_SUCCESS";
export const GET_DETAIL_AKUN_LIVE_FAIL = "GET_DETAIL_AKUN_LIVE_FAIL";

// put detail user
export const PUT_AKUN_LIVE = "PUT_AKUN_LIVE";
export const PUT_AKUN_LIVE_SUCCESS = "PUT_AKUN_LIVE_SUCCESS";
export const PUT_AKUN_LIVE_FAIL = "PUT_AKUN_LIVE_FAIL";

// add new user
export const ADD_AKUN_LIVE = "ADD_AKUN_LIVE";
export const ADD_AKUN_LIVE_SUCCESS = "ADD_AKUN_LIVE_SUCCESS";
export const ADD_AKUN_LIVE_FAIL = "ADD_AKUN_LIVE_FAIL";