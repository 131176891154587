import FolderIcon from "@mui/icons-material/Folder";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "../../../config/API/config";
import { PRIMARY } from "../../../utils/constansColors";
import { Gap } from "../../atoms";
import "./Sidemenu.css";

const SideMenu = () => {
  const location = useLocation();
  const path = location.pathname;
  const profile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

  //cek hak akses
  const checkHakAkses = (list,akses) => {
    let has = false;
    list?.map((val)=>{
      if(val.nama_hak_akses === akses){
        has = true
      }
      if(val.hak_akses === "Super Admin Semata"){
        has = true
      }
    })
    return has
  }

  return (
    <div className="SideNav" style={{boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px'}}>
      <Gap height={25} />
      <List component="nav" aria-label="main mailbox folders">
        
        {(checkHakAkses(profile?.data?.hak_akses,"Rekap Live"))?(
          <>
            <Link to="/" style={{ display: "flex", textDecoration: "none" }}>
              <ListItemButton
                className={path === "/" ? "Side-menu-list-item-active" : {}}
              >
                <ListItemIcon style={path === "/" ? { color: PRIMARY,minWidth:'45px' } : {minWidth:'45px'}}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Rekap Live"
                  primaryTypographyProps={
                    path === "/"
                      ? { fontSize: "13px", fontWeight: 700, color: PRIMARY }
                      : { fontSize: "13px",color: "#8b8b8b" }
                  }
                />
              </ListItemButton>
            </Link>
            <Gap height={5} />
          </>
        ):null}

        {(checkHakAkses(profile?.data?.hak_akses,"Rekap Admin"))?(
          <>
            <Link to="/rekap-admin" style={{ display: "flex", textDecoration: "none" }}>
              <ListItemButton
                className={path === "/rekap-admin" ? "Side-menu-list-item-active" : {}}
              >
                <ListItemIcon style={path === "/rekap-admin" ? { color: PRIMARY,minWidth:'45px' } : {minWidth:'45px'}}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Rekap Admin"
                  primaryTypographyProps={
                    path === "/rekap-admin"
                      ? { fontSize: "13px", fontWeight: 700, color: PRIMARY }
                      : { fontSize: "13px",color: "#8b8b8b" }
                  }
                />
              </ListItemButton>
            </Link>
            <Gap height={5} />
          </>
        ):null}
        {(checkHakAkses(profile?.data?.hak_akses,"Laporan Penjualan"))?(
          <>
            <Link to="/laporan" style={{ display: "flex", textDecoration: "none" }}>
              <ListItemButton
                className={path === "/laporan" ? "Side-menu-list-item-active" : {}}
              >
                <ListItemIcon style={path === "/laporan" ? { color: PRIMARY,minWidth:'45px' } : {minWidth:'45px'}}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Laporan"
                  primaryTypographyProps={
                    path === "/laporan"
                      ? { fontSize: "13px", fontWeight: 700, color: PRIMARY }
                      : { fontSize: "13px",color: "#8b8b8b" }
                  }
                />
              </ListItemButton>
            </Link>
            <Gap height={5} />
          </>
        ):null}
        {/* {(checkHakAkses(profile?.data?.hak_akses,"Customer Management"))?( */}
          <>
            <Link to="/customer" style={{ display: "flex", textDecoration: "none" }}>
              <ListItemButton
                className={path === "/customer" ? "Side-menu-list-item-active" : {}}
              >
                <ListItemIcon style={path === "/customer" ? { color: PRIMARY,minWidth:'45px' } : {minWidth:'45px'}}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Customer"
                  primaryTypographyProps={
                    path === "/customer"
                      ? { fontSize: "13px", fontWeight: 700, color: PRIMARY }
                      : { fontSize: "13px",color: "#8b8b8b" }
                  }
                />
              </ListItemButton>
            </Link>
            <Gap height={5} />
          </>
        {/* ):null} */}
        {(checkHakAkses(profile?.data?.hak_akses,"Data Management"))?(
          <>
            <Link to="/data-master" style={{ display: "flex", textDecoration: "none" }}>
              <ListItemButton
                className={path === "/data-master" ? "Side-menu-list-item-active" : {}}
              >
                <ListItemIcon style={path === "/data-master" ? { color: PRIMARY,minWidth:'45px' } : {minWidth:'45px'}}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Data Master"
                  primaryTypographyProps={
                    path === "/data-master"
                      ? { fontSize: "13px", fontWeight: 700, color: PRIMARY }
                      : { fontSize: "13px",color: "#8b8b8b" }
                  }
                />
              </ListItemButton>
            </Link>
            <Gap height={5} />
          </>
        ):null}
        {(checkHakAkses(profile?.data?.hak_akses,"User Management"))?(
          <>
            <Link to="/user-management" style={{ display: "flex", textDecoration: "none" }}>
              <ListItemButton
                className={path === "/user-management" ? "Side-menu-list-item-active" : {}}
              >
                <ListItemIcon style={path === "/user-management" ? { color: PRIMARY,minWidth:'45px' } : {minWidth:'45px'}}>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary="User Management"
                  primaryTypographyProps={
                    path === "/user-management"
                      ? { fontSize: "13px", fontWeight: 700, color: PRIMARY }
                      : { fontSize: "13px",color: "#8b8b8b" }
                  }
                />
              </ListItemButton>
            </Link>
            <Gap height={5} />
          </>
        ):null}

        
      </List>
    </div>
  );
};

export default SideMenu;
