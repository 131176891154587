import {
  GET_METODE_PEMBAYARAN,
  GET_METODE_PEMBAYARAN_SUCCESS,
  GET_METODE_PEMBAYARAN_FAIL,
  GET_DETAIL_METODE_PEMBAYARAN,
  GET_DETAIL_METODE_PEMBAYARAN_SUCCESS,
  GET_DETAIL_METODE_PEMBAYARAN_FAIL,
  PUT_METODE_PEMBAYARAN,
  PUT_METODE_PEMBAYARAN_SUCCESS,
  PUT_METODE_PEMBAYARAN_FAIL,
  ADD_METODE_PEMBAYARAN,
  ADD_METODE_PEMBAYARAN_SUCCESS,
  ADD_METODE_PEMBAYARAN_FAIL,
} from "./actionTypes";

const initialState = {
  dataPembayaran: {
    loading: false,
    error: false,
    data: null,
  },
  dataDetailPembayaran: {
    loading: false,
    error: false,
    data: null,
  },
  updatePembayaran: {
    loading: false,
    error: false,
    data: null,
  },
  addPembayaran: {
    loading: false,
    error: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // get table exco pusat
    case GET_METODE_PEMBAYARAN:
      return {
        ...state,
        dataPembayaran: {
          ...state.dataPembayaran,
          loading: true,
          error: false,
        },
      };
    case GET_METODE_PEMBAYARAN_SUCCESS:
      return {
        ...state,
        dataPembayaran: {
          ...state.dataPembayaran,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_METODE_PEMBAYARAN_FAIL:
      return {
        ...state,
        dataPembayaran: {
          ...state.dataPembayaran,
          loading: false,
          error: action.payload,
        },
      };
    // get detail user
    case GET_DETAIL_METODE_PEMBAYARAN:
      return {
        ...state,
        dataDetailPembayaran: {
          ...state.dataDetailPembayaran,
          loading: true,
          error: false,
        },
      };
    case GET_DETAIL_METODE_PEMBAYARAN_SUCCESS:
      return {
        ...state,
        dataDetailPembayaran: {
          ...state.dataDetailPembayaran,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case GET_DETAIL_METODE_PEMBAYARAN_FAIL:
      return {
        ...state,
        dataDetailPembayaran: {
          ...state.dataDetailPembayaran,
          loading: false,
          error: action.payload,
        },
      };
    // update user
    case PUT_METODE_PEMBAYARAN:
      return {
        ...state,
        updatePembayaran: {
          ...state.updatePembayaran,
          loading: true,
          error: false,
        },
      };
    case PUT_METODE_PEMBAYARAN_SUCCESS:
      return {
        ...state,
        updatePembayaran: {
          ...state.updatePembayaran,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case PUT_METODE_PEMBAYARAN_FAIL:
      return {
        ...state,
        updatePembayaran: {
          ...state.updatePembayaran,
          loading: false,
          error: action.payload,
        },
      };
    //add new user
    case ADD_METODE_PEMBAYARAN:
      return {
        ...state,
        addPembayaran: {
          ...state.addPembayaran,
          loading: true,
          error: false,
        },
      };
    case ADD_METODE_PEMBAYARAN_SUCCESS:
      return {
        ...state,
        addPembayaran: {
          ...state.addPembayaran,
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    case ADD_METODE_PEMBAYARAN_FAIL:
      return {
        ...state,
        addPembayaran: {
          ...state.addPembayaran,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
